import React, { useState, useEffect } from "react";
// import { addBlock } from "services/blockmaster.service";
import { addStatusMaster } from "services/status.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddStatusMaster = () => {
  const [statusData, setStatusData] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setStatusData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const AddStatus = (e) => {
    e.preventDefault();

    addStatusMaster(statusData).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/status");
        }, 1500);
      } else if (response.Status === "SUCCESS" && response.Error === true) {
        console.log("im here");
        toast.error(response.Message);
      } else {
        toast.error("Please fill the required fields");
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Status</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddStatus}>
                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        name="title"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddStatusMaster;
