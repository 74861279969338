// import React from "react";
// import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminForgotPassword } from "services/admin.service";

export default function ForgotPassword() {
  const [email, setemail] = useState("");
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("isLoggedIn"));
  if (loggedIn) {
    return <Navigate to="/dashboard" />;
  }
  const forgotPassword = (e) => {
    e.preventDefault();
    let param = {
      email: email,
    };
    adminForgotPassword(param)
      .then((resp) => {
        console.log(resp);
        if (resp.errorCode === 200) {
          console.log("200");
          toast.success(resp.message);
        } else {
          console.log("400");
          toast.error(resp.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blue-950 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          >
            <h1 className="text-center text-white text-3xl mt-20 ">ZIIEI</h1>
          </div>

          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-700 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-white text-center mb-6  font-bold">
                      <h1 className="text-2xl">Forgot your Password?</h1>
                    </div>
                    <form onSubmit={forgotPassword}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-white text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          name="email"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                      <div className="text-center mt-6">
                        <button
                          className="bg-green-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Submit
                        </button>
                        <ToastContainer />
                        <div className="flex flex-wrap mt-6 relative">
                          <div className="w-1/2">
                            <Link to="/login">
                              <h1 className="text-center text-white font-bold">
                                Return to Login?
                              </h1>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
