import { Navigate } from "react-router";
import Dashboard from "../components/admin/Dashboard/Dashboard";
import AddUsermanagement from "../components/admin/usermanagement/AddUsermanagement";
import EditUsermanagement from "components/admin/usermanagement/EditUsermanagement";
import ListUserManagement from "../components/admin/usermanagement/ListUserManagement";
import PageMaster from "../components/admin/PageMaster/ListPageMaster";
import AddPageMaster from "components/admin/PageMaster/AddPageMaster";
import EditPageMaster from "components/admin/PageMaster/EditPageMaster";
import CourseMaster from "../components/admin/CourseMaster/ListCourseMaster";
import AddCourseMaster from "components/admin/CourseMaster/AddCourseMaster";
import EditCourseMaster from "components/admin/CourseMaster/EditCourseMaster";
import EditBoardMaster from "components/admin/BoardMaster/EditBoardMaster";
import BoardMaster from "../components/admin/BoardMaster/ListBoardMaster";
import AddBoardMaster from "components/admin/BoardMaster/AddBoardMaster";
import GradeMaster from "components/admin/GradeMaster/ListGradeMaster";
import AddGradeMaster from "components/admin/GradeMaster/AddGradeMaster";
import EditGradeMaster from "components/admin/GradeMaster/EditGradeMaster";
import SubjectMaster from "components/admin/SubjectMaster/ListSubjectMaster";
import AddSubjectMaster from "components/admin/SubjectMaster/AddSubjectMaster";
import EditSubjectMaster from "components/admin/SubjectMaster/EditSubjectMaster";
import SubjectGradeMapping from "components/admin/SubjectGradeMapping/ListSubjectGradeMapping.js";
import StateMaster from "components/admin/StateMaster/ListStateMaster.js";
import EditStateMaster from "components/admin/StateMaster/EditStateMaster";
import AddStateMaster from "components/admin/StateMaster/AddStateMaster";
import DivisionMaster from "components/admin/DivisionMaster/ListDivisionMaster";
import AddDivision from "components/admin/DivisionMaster/AddDivisionMaster";
import EditDivision from "components/admin/DivisionMaster/EditDivisionMaster";
import Districtmaster from "components/admin/DistrictMaster/ListDistrictMaster";
import AddDistrict from "components/admin/DistrictMaster/AddDistrictMaster";
import EditDistrict from "components/admin/DistrictMaster/EditDistrictMaster";
import BlockMaster from "components/admin/BlockMaster/ListBlockMaster";
import AddBlock from "components/admin/BlockMaster/AddBlockMaster";
import EditBlock from "components/admin/BlockMaster/EditBlockMaster";
import ClusterMaster from "components/admin/ClusterMaster/ListClusterMaster";
import AddCluster from "components/admin/ClusterMaster/AddClusterMaster";
import EditCluster from "components/admin/ClusterMaster/EditClusterMaster";
import VillageMaster from "components/admin/VillageMaster/ListVillageMaster";
import AddVillage from "components/admin/VillageMaster/AddVillageMaster";
import EditVillage from "components/admin/VillageMaster/EditVillageMaster";
import SchoolMaster from "components/admin/SchoolMaster/ListSchoolMaster";
import AddSchool from "components/admin/SchoolMaster/AddSchoolMaster";
import EditSchool from "components/admin/SchoolMaster/EditSchoolMaster";
import TlmMaster from "components/admin/TlmMaster/ListTlmMaster";
import AddTlm from "components/admin/TlmMaster/AddTlmMaster";
import EditTlm from "components/admin/TlmMaster/EditTlmMaster";
import TlmItemMaster from "components/admin/TlmItemMaster/ListTlmItemMaster";
import AddTlmItem from "components/admin/TlmItemMaster/AddTlmItemMaster";
import EditTlmItem from "components/admin/TlmItemMaster/EditTlmItemMaster";
import BlockTranslationTable from "components/admin/Translation/BlockMasterTranslation/ListBlockMasterTranslation";
import EditBlockMasterTranslation from "components/admin/Translation/BlockMasterTranslation/EditBlockMasterTranslation";
import AddBlockMasterTranslation from "components/admin/Translation/BlockMasterTranslation/AddBlockMasterTranslation";
import BoardTranslationTable from "components/admin/Translation/BoardMasterTranslation/ListBoardMasterTranslation";
import EditBoardMasterTranslation from "components/admin/Translation/BoardMasterTranslation/EditBoardMasterTranslation";
import AddBoardMasterTranslation from "components/admin/Translation/BoardMasterTranslation/AddBoardMasterTranslation";
import ClusterTranslationTable from "components/admin/Translation/ClusterMasterTranslation/ListClusterMasterTranslation";
import EditClusterMasterTranslation from "components/admin/Translation/ClusterMasterTranslation/EditClusterMasterTranslation";
import AddClusterMasterTranslation from "components/admin/Translation/ClusterMasterTranslation/AddClusterMasterTranslation";
import CourseTranslationTable from "components/admin/Translation/CourseMasterTranslation/ListCourseMasterTranslation";
import EditCourseMasterTranslation from "components/admin/Translation/CourseMasterTranslation/EditCourseMasterTranslation";
import AddCourseMasterTranslation from "components/admin/Translation/CourseMasterTranslation/AddCourseMasterTranslation";
import DistrictTranslationTable from "components/admin/Translation/DistrictMasterTranslation/ListDistrictMasterTranslation";
import EditDistrictMasterTranslation from "components/admin/Translation/DistrictMasterTranslation/EditDistrictMasterTranslation";
import AddDistrictMasterTranslation from "components/admin/Translation/DistrictMasterTranslation/AddDistrictMasterTranslation";
import DivisionTranslationTable from "components/admin/Translation/DivisionMasterTranslation/ListDivisionMasterTranslation";
import EditDivisionMasterTranslation from "components/admin/Translation/DivisionMasterTranslation/EditDivisionMasterTranslation";
import AddDivisionMasterTranslation from "components/admin/Translation/DivisionMasterTranslation/AddDivisionMasterTranslation";
import GradeTranslationTable from "components/admin/Translation/GradeMasterTranslation/ListGradeMasterTranslation";
import EditGradeMasterTranslation from "components/admin/Translation/GradeMasterTranslation/EditGradeMasterTranslation";
import AddGradeMasterTranslation from "components/admin/Translation/GradeMasterTranslation/AddGradeMasterTranslation";
import AddPageMasterTranslation from "components/admin/Translation/PageMasterTranslation/AddPageMasterTranslation";
import EditPageMasterTranslation from "components/admin/Translation/PageMasterTranslation/EditPageMasterTranslation";
import PageTranslationTable from "components/admin/Translation/PageMasterTranslation/ListPageMasterTranslation";
import SchoolTranslationTable from "components/admin/Translation/SchoolMasterTranslation/ListSchoolMasterTranslation";
import EditSchoolMasterTranslation from "components/admin/Translation/SchoolMasterTranslation/EditSchoolMasterTranslation";
import AddSchoolMasterTranslation from "components/admin/Translation/SchoolMasterTranslation/AddSchoolMasterTranslation";
import StateTranslationTable from "components/admin/Translation/StateMasterTranslation/ListStateMasterTranslation";
import EditStateMasterTranslation from "components/admin/Translation/StateMasterTranslation/EditStateMasterTranslation";
import AddStateMasterTranslation from "components/admin/Translation/StateMasterTranslation/AddStateMasterTranslation";
import SubjectTranslationTable from "components/admin/Translation/SubjectMasterTranslation/ListSubjectMasterTranslation";
import EditSubjectMasterTranslation from "components/admin/Translation/SubjectMasterTranslation/EditSubjectMasterTranslation";
import AddSubjectMasterTranslation from "components/admin/Translation/SubjectMasterTranslation/AddSubjectMasterTranslation";
import VillageTranslationTable from "components/admin/Translation/VillageMasterTranslation/ListVillageMasterTranslation";
import EditVillageMasterTranslation from "components/admin/Translation/VillageMasterTranslation/EditVillageMasterTranslation";
import AddVillageMasterTranslation from "components/admin/Translation/VillageMasterTranslation/AddVillageMasterTranslation";
import LanguageMaster from "components/admin/Languages/ListLanguageMaster";
import AddLangaugeMaster from "components/admin/Languages/AddLangaugeMaster";
import EditLangaugeMaster from "components/admin/Languages/EditLangaugeMaster";
import ListActivityMaster from "components/admin/Activity/ListActivityMaster";
import AddActivityMaster from "components/admin/Activity/AddActivityMaster";
import EditActivityMaster from "components/admin/Activity/EditActivityMaster";
import SubConceptMaster from "components/admin/SubConceptMaster/ListSubConceptMaster";
import AddSubConceptMaster from "components/admin/SubConceptMaster/AddSubConceptMaster";
import EditSubConceptMaster from "components/admin/SubConceptMaster/EditSubConceptMaster";
import ChapterMaster from "components/admin/ChapterMaster/ListChapterMaster";
import AddChapterMaster from "components/admin/ChapterMaster/AddChapterMaster";
import EditChapterMaster from "components/admin/ChapterMaster/EditChapterMaster";
import PeriodMaster from "components/admin/PeriodMaster/ListPeriodMaster";
import AddPeriodMaster from "components/admin/PeriodMaster/AddPeriodMaster";
import EditPeriodMaster from "components/admin/PeriodMaster/EditPeriodMaster";
import ThemeMaster from "components/admin/ThemeMaster/ListThemeMaster";
import AddThemeMaster from "components/admin/ThemeMaster/AddThemeMaster";
import EditThemeMaster from "components/admin/ThemeMaster/EditThemeMaster";
import ProjectMaster from "components/admin/ProjectMaster/ListProjectMaster";
import AddProjectMaster from "components/admin/ProjectMaster/AddProjectMaster";
import EditProjectMaster from "components/admin/ProjectMaster/EditProjectMaster";
import ProjectActivityMaster from "components/admin/ProjectActivityMaster/ListProjectActivityMaster";
import AddProjectActivityMaster from "components/admin/ProjectActivityMaster/AddProjectActivityMaster";
import EditProjectActivityMaster from "components/admin/ProjectActivityMaster/EditProjectActivityMaster";
import AddLearningOutcomeMaster from "components/admin/LearningOutcomeMaster/AddLearningOutcome";
import ListLearningOutcome from "components/admin/LearningOutcomeMaster/ListLearningOutcome";
import EditLearningOutcomeMaster from "components/admin/LearningOutcomeMaster/EditLearningOutcome";
import AddPageContent from "components/admin/PageContent/AddPageContent";
import AddPageImage from "components/admin/PageImage/addPageImage";
import ConceptMaster from "components/admin/ConceptMaster/ListConceptMaster";
import AddConceptMaster from "components/admin/ConceptMaster/AddConceptMaster";
import EditConceptMaster from "components/admin/ConceptMaster/EditConceptMaster";
import ListPageContentMaster from "components/admin/PageContent/ListPageContent";
import ListPageImageMaster from "components/admin/PageImage/ListPageImage";
import EditPageContent from "components/admin/PageContent/EditPageContent";
import EditPageImage from "components/admin/PageImage/EditPageImage";
import AddStatusMaster from "components/admin/StatusMaster/AddStatusMaster";
import ListStatusMaster from "components/admin/StatusMaster/ListStatusMaster";
import EditStatusMaster from "components/admin/StatusMaster/EditStatusMaster";
import ListIdeaMaster from "components/admin/IdeaMaster/ListIDeaMaster";
import EditIdeaMaster from "components/admin/IdeaMaster/EditIdeaMaster";
import ListIdeaStatusMaster from "components/admin/IdeaStatus/ListIdeaStatus";
import ListConfiguration from "components/admin/Configuration/ListConfiguration";
import AddConfigurationMaster from "components/admin/Configuration/AddConfiguration";
import EditConfigurationMaster from "components/admin/Configuration/EditConfiguration";
import CountryMaster from "components/admin/CountryMaster/ListCountryMaster";
import AddCountryMaster from "components/admin/CountryMaster/AddCountryMaster";
import EditCountryMaster from "components/admin/CountryMaster/EditCountryMaster";
import CourseContentMaster from "components/admin/CourseContentMaster/ListCourseContentMaster";
import AddCourseContentMaster from "components/admin/CourseContentMaster/AddCourseContentMaster";
import EditCourseContentMaster from "components/admin/CourseContentMaster/EditCourseContentMaster";
import CourseSubContentMaster from "components/admin/CourseSubContentMaster/ListCourseSubContentMaster";
import AddCourseSubContentMaster from "components/admin/CourseSubContentMaster/AddCourseSubContentMaster";
import EditCourseSubContentMaster from "components/admin/CourseSubContentMaster/EditCourseSubContentMaster";
import ListInnovativePathshalaMaster from "components/admin/InnovativePathShala/ListInnovativePathshala";
import AddInnovativePathshalaMaster from "components/admin/InnovativePathShala/AddInnovativePathshala";
import EditInnovativePathshalaMaster from "components/admin/InnovativePathShala/EditInnovativePathshala";
// import ChapterWeekMaster from "components/admin/ChapterWeekMaster/ListChapterWeekMaster";
// import AddChapterWeekMaster from "components/admin/ChapterWeekMaster/AddChapterWeekMaster";
// import EditChapterWeekMaster from "components/admin/ChapterWeekMaster/EditChapterWeekMaster";
import { HandBookMasterView } from "view/admin/HandbookMasterView";
import AddHandBookMaster from "components/admin/Handbook/AddHandbook";
import EditHandBookMaster from "components/admin/Handbook/EditHandbook";
import { KnowledgeMasterView } from "view/admin/KnowledgeMasterView";
import ListKnowledgeMaster from "components/admin/Knowledge/ListKnowledge";
import AddKnowledgeMaster from "components/admin/Knowledge/AddKnowledge";
import EditKnowledgeMaster from "components/admin/Knowledge/EditKnowledge";
import CaseStudyMaster from "components/admin/CaseStudyMaster/ListCaseStudyMaster";
import AddCaseStudyMaster from "components/admin/CaseStudyMaster/AddCaseStudyMaster";
import EditCaseStudyMaster from "components/admin/CaseStudyMaster/EditCaseStudyMaster";

export const adminRoutes = [
  {
    path: "/",
    component: <Navigate to="/dashboard" replace />,
  },
  {
    path: "dashboard",
    component: <Dashboard />,
  },
  {
    path: "user",
    component: <ListUserManagement />,
  },
  {
    path: "adduser",
    component: <AddUsermanagement />,
  },
  {
    path: "edituser",
    component: <EditUsermanagement />,
  },
  {
    path: "page",
    component: <PageMaster />,
  },
  {
    path: "addpage",
    component: <AddPageMaster />,
  },
  {
    path: "editpage",
    component: <EditPageMaster />,
  },
  {
    path: "innovativepaathshala/course",
    component: <CourseMaster />,
  },
  {
    path: "innovativepaathshala/addcourse",
    component: <AddCourseMaster />,
  },
  {
    path: "innovativepaathshala/editcourse",
    component: <EditCourseMaster />,
  },
  {
    path: "board",
    component: <BoardMaster />,
  },
  {
    path: "addboard",
    component: <AddBoardMaster />,
  },
  {
    path: "editboard",
    component: <EditBoardMaster />,
  },
  {
    path: "grade",
    component: <GradeMaster />,
  },
  {
    path: "addgrade",
    component: <AddGradeMaster />,
  },
  {
    path: "editgrade",
    component: <EditGradeMaster />,
  },
  {
    path: "subject",
    component: <SubjectMaster />,
  },
  {
    path: "addsubject",
    component: <AddSubjectMaster />,
  },
  {
    path: "editsubject",
    component: <EditSubjectMaster />,
  },
  {
    path: "subjectgrademapping",
    component: <SubjectGradeMapping />,
  },
  {
    path: "state",
    component: <StateMaster />,
  },
  {
    path: "addstate",
    component: <AddStateMaster />,
  },
  {
    path: "editstate",
    component: <EditStateMaster />,
  },
  {
    path: "country",
    component: <CountryMaster />,
  },
  {
    path: "addcountry",
    component: <AddCountryMaster />,
  },
  {
    path: "editcountry",
    component: <EditCountryMaster />,
  },
  {
    path: "division",
    component: <DivisionMaster />,
  },
  {
    path: "adddivision",
    component: <AddDivision />,
  },
  {
    path: "editdivision",
    component: <EditDivision />,
  },

  {
    path: "district",
    component: <Districtmaster />,
  },
  {
    path: "adddistrict",
    component: <AddDistrict />,
  },
  {
    path: "editdistrict",
    component: <EditDistrict />,
  },
  {
    path: "block",
    component: <BlockMaster />,
  },
  {
    path: "addblock",
    component: <AddBlock />,
  },
  {
    path: "editblock",
    component: <EditBlock />,
  },
  {
    path: "cluster",
    component: <ClusterMaster />,
  },
  {
    path: "addcluster",
    component: <AddCluster />,
  },
  {
    path: "editcluster",
    component: <EditCluster />,
  },
  {
    path: "village",
    component: <VillageMaster />,
  },
  {
    path: "addvillage",
    component: <AddVillage />,
  },
  {
    path: "editvillage",
    component: <EditVillage />,
  },
  {
    path: "school",
    component: <SchoolMaster />,
  },
  {
    path: "addschool",
    component: <AddSchool />,
  },
  {
    path: "editschool",
    component: <EditSchool />,
  },
  {
    path: "Tlm",
    component: <TlmMaster />,
  },
  {
    path: "addTlm",
    component: <AddTlm />,
  },
  {
    path: "editTlm",
    component: <EditTlm />,
  },
  {
    path: "tlmitem",
    component: <TlmItemMaster />,
  },
  {
    path: "addtlmitem",
    component: <AddTlmItem />,
  },
  {
    path: "edittlmitem",
    component: <EditTlmItem />,
  },
  {
    path: "blockTranslationList",
    component: <BlockTranslationTable />,
  },
  {
    path: "editBlockTranslation",
    component: <EditBlockMasterTranslation />,
  },
  {
    path: "blockTranslation",
    component: <AddBlockMasterTranslation />,
  },
  {
    path: "boardTranslationList",
    component: <BoardTranslationTable />,
  },
  {
    path: "editBoardTranslationList",
    component: <EditBoardMasterTranslation />,
  },
  {
    path: "boardTranslation",
    component: <AddBoardMasterTranslation />,
  },
  {
    path: "clusterTranslationList",
    component: <ClusterTranslationTable />,
  },
  {
    path: "editClusterTranslationList",
    component: <EditClusterMasterTranslation />,
  },
  {
    path: "clusterTranslation",
    component: <AddClusterMasterTranslation />,
  },
  {
    path: "courseTranslationList",
    component: <CourseTranslationTable />,
  },
  {
    path: "editCourseTranslationList",
    component: <EditCourseMasterTranslation />,
  },
  {
    path: "courseTranslation",
    component: <AddCourseMasterTranslation />,
  },
  {
    path: "districtTranslationList",
    component: <DistrictTranslationTable />,
  },
  {
    path: "editDistrictTranslationList",
    component: <EditDistrictMasterTranslation />,
  },
  {
    path: "districtTranslation",
    component: <AddDistrictMasterTranslation />,
  },
  {
    path: "divisionTranslationList",
    component: <DivisionTranslationTable />,
  },
  {
    path: "editDivisionTranslationList",
    component: <EditDivisionMasterTranslation />,
  },
  {
    path: "divisionTranslation",
    component: <AddDivisionMasterTranslation />,
  },
  {
    path: "gradeTranslationList",
    component: <GradeTranslationTable />,
  },
  {
    path: "editGradeTranslationList",
    component: <EditGradeMasterTranslation />,
  },
  {
    path: "gradeTranslation",
    component: <AddGradeMasterTranslation />,
  },
  {
    path: "pageTranslationList",
    component: <PageTranslationTable />,
  },
  {
    path: "editPageTranslationList",
    component: <EditPageMasterTranslation />,
  },
  {
    path: "pageTranslation",
    component: <AddPageMasterTranslation />,
  },
  {
    path: "schoolTranslationList",
    component: <SchoolTranslationTable />,
  },
  {
    path: "editSchoolTranslationList",
    component: <EditSchoolMasterTranslation />,
  },
  {
    path: "schoolTranslation",
    component: <AddSchoolMasterTranslation />,
  },
  {
    path: "stateTranslationList",
    component: <StateTranslationTable />,
  },
  {
    path: "editStateTranslationList",
    component: <EditStateMasterTranslation />,
  },
  {
    path: "stateTranslation",
    component: <AddStateMasterTranslation />,
  },
  {
    path: "subjectTranslationList",
    component: <SubjectTranslationTable />,
  },
  {
    path: "editSubjectTranslationList",
    component: <EditSubjectMasterTranslation />,
  },
  {
    path: "subjectTranslation",
    component: <AddSubjectMasterTranslation />,
  },
  {
    path: "villageTranslationList",
    component: <VillageTranslationTable />,
  },
  {
    path: "editVillageTranslationList",
    component: <EditVillageMasterTranslation />,
  },
  {
    path: "villageTranslation",
    component: <AddVillageMasterTranslation />,
  },
  {
    path: "language",
    component: <LanguageMaster />,
  },
  {
    path: "addlanguage",
    component: <AddLangaugeMaster />,
  },
  {
    path: "editlanguage",
    component: <EditLangaugeMaster />,
  },
  {
    path: "activity",
    component: <ListActivityMaster />,
  },
  {
    path: "addactivity",
    component: <AddActivityMaster />,
  },
  {
    path: "editactivity",
    component: <EditActivityMaster />,
  },
  {
    path: "subconcept",
    component: <SubConceptMaster />,
  },

  {
    path: "chapter",
    component: <ChapterMaster />,
  },

  {
    path: "editchapter",
    component: <EditChapterMaster />,
  },
  {
    path: "period",
    component: <PeriodMaster />,
  },
  {
    path: "addperiod",
    component: <AddPeriodMaster />,
  },
  {
    path: "editperiod",
    component: <EditPeriodMaster />,
  },
  {
    path: "theme",
    component: <ThemeMaster />,
  },
  {
    path: "addtheme",
    component: <AddThemeMaster />,
  },
  {
    path: "edittheme",
    component: <EditThemeMaster />,
  },
  {
    path: "project",
    component: <ProjectMaster />,
  },
  {
    path: "addproject",
    component: <AddProjectMaster />,
  },
  {
    path: "editproject",
    component: <EditProjectMaster />,
  },
  {
    path: "projectactivity",
    component: <ProjectActivityMaster />,
  },
  {
    path: "addLearningOutcome",
    component: <AddLearningOutcomeMaster />,
  },
  {
    path: "learningOutcome",
    component: <ListLearningOutcome />,
  },
  {
    path: "addLearningOutcome",
    component: <AddLearningOutcomeMaster />,
  },
  {
    path: "editLearningOutcome",
    component: <EditLearningOutcomeMaster />,
  },
  {
    path: "addPageContent",
    component: <AddPageContent />,
  },
  {
    path: "addPageImage",
    component: <AddPageImage />,
  },
  {
    path: "concept",
    component: <ConceptMaster />,
  },
  {
    path: "addconcept",
    component: <AddConceptMaster />,
  },
  {
    path: "editconcept",
    component: <EditConceptMaster />,
  },
  {
    path: "listPageContent",
    component: <ListPageContentMaster />,
  },
  {
    path: "listPageImage",
    component: <ListPageImageMaster />,
  },
  {
    path: "editPageContent",
    component: <EditPageContent />,
  },
  {
    path: "editPageImage",
    component: <EditPageImage />,
  },
  {
    path: "status",
    component: <ListStatusMaster />,
  },
  {
    path: "addStatus",
    component: <AddStatusMaster />,
  },
  {
    path: "editStatus",
    component: <EditStatusMaster />,
  },
  {
    path: "idea",
    component: <ListIdeaMaster />,
  },
  {
    path: "editIdea",
    component: <EditIdeaMaster />,
  },
  {
    path: "ideaStatus",
    component: <ListIdeaStatusMaster />,
  },
  {
    path: "configuration",
    component: <ListConfiguration />,
  },
  {
    path: "addConfiguration",
    component: <AddConfigurationMaster />,
  },
  {
    path: "editConfiguration",
    component: <EditConfigurationMaster />,
  },
  {
    path: "addProjectActivity",
    component: <AddProjectActivityMaster />,
  },
  {
    path: "editProjectActivity",
    component: <EditProjectActivityMaster />,
  },
  {
    path: "/",
    component: <Navigate to="/dashboard" replace />,
  },
  {
    path: "dashboard",
    component: <Dashboard />,
  },

  {
    path: "addpage",
    component: <AddPageMaster />,
  },
  {
    path: "editpage",
    component: <EditPageMaster />,
  },

  {
    path: "addcourse",
    component: <AddCourseMaster />,
  },
  {
    path: "editcourse",
    component: <EditCourseMaster />,
  },

  {
    path: "addboard",
    component: <AddBoardMaster />,
  },
  {
    path: "editboard",
    component: <EditBoardMaster />,
  },

  {
    path: "addgrade",
    component: <AddGradeMaster />,
  },
  {
    path: "editgrade",
    component: <EditGradeMaster />,
  },

  {
    path: "addsubject",
    component: <AddSubjectMaster />,
  },
  {
    path: "editsubject",
    component: <EditSubjectMaster />,
  },
  {
    path: "subjectgrademapping",
    component: <SubjectGradeMapping />,
  },
  {
    path: "statemaster",
    component: <StateMaster />,
  },
  {
    path: "addstate",
    component: <AddStateMaster />,
  },
  {
    path: "editstate",
    component: <EditStateMaster />,
  },

  {
    path: "addcountry",
    component: <AddCountryMaster />,
  },
  {
    path: "editcountry",
    component: <EditCountryMaster />,
  },

  {
    path: "adddivision",
    component: <AddDivision />,
  },
  {
    path: "editdivision",
    component: <EditDivision />,
  },

  {
    path: "adddistrict",
    component: <AddDistrict />,
  },
  {
    path: "editdistrict",
    component: <EditDistrict />,
  },

  {
    path: "addblock",
    component: <AddBlock />,
  },
  {
    path: "editblock",
    component: <EditBlock />,
  },

  {
    path: "addcluster",
    component: <AddCluster />,
  },
  {
    path: "editcluster",
    component: <EditCluster />,
  },

  {
    path: "addvillage",
    component: <AddVillage />,
  },
  {
    path: "editvillage",
    component: <EditVillage />,
  },

  {
    path: "addschool",
    component: <AddSchool />,
  },
  {
    path: "editschool",
    component: <EditSchool />,
  },

  {
    path: "addTlm",
    component: <AddTlm />,
  },
  {
    path: "editTlm",
    component: <EditTlm />,
  },

  {
    path: "addtlmitem",
    component: <AddTlmItem />,
  },
  {
    path: "edittlmitem",
    component: <EditTlmItem />,
  },
  {
    path: "blockTranslationList",
    component: <BlockTranslationTable />,
  },
  {
    path: "editBlockTranslation",
    component: <EditBlockMasterTranslation />,
  },
  {
    path: "blockTranslation",
    component: <AddBlockMasterTranslation />,
  },
  {
    path: "boardTranslationList",
    component: <BoardTranslationTable />,
  },
  {
    path: "editBoardTranslationList",
    component: <EditBoardMasterTranslation />,
  },
  {
    path: "boardTranslation",
    component: <AddBoardMasterTranslation />,
  },
  {
    path: "clusterTranslationList",
    component: <ClusterTranslationTable />,
  },
  {
    path: "editClusterTranslationList",
    component: <EditClusterMasterTranslation />,
  },
  {
    path: "clusterTranslation",
    component: <AddClusterMasterTranslation />,
  },
  {
    path: "courseTranslationList",
    component: <CourseTranslationTable />,
  },
  {
    path: "editCourseTranslationList",
    component: <EditCourseMasterTranslation />,
  },
  {
    path: "courseTranslation",
    component: <AddCourseMasterTranslation />,
  },
  {
    path: "districtTranslationList",
    component: <DistrictTranslationTable />,
  },
  {
    path: "editDistrictTranslationList",
    component: <EditDistrictMasterTranslation />,
  },
  {
    path: "districtTranslation",
    component: <AddDistrictMasterTranslation />,
  },
  {
    path: "divisionTranslationList",
    component: <DivisionTranslationTable />,
  },
  {
    path: "editDivisionTranslationList",
    component: <EditDivisionMasterTranslation />,
  },
  {
    path: "divisionTranslation",
    component: <AddDivisionMasterTranslation />,
  },
  {
    path: "gradeTranslationList",
    component: <GradeTranslationTable />,
  },
  {
    path: "editGradeTranslationList",
    component: <EditGradeMasterTranslation />,
  },
  {
    path: "gradeTranslation",
    component: <AddGradeMasterTranslation />,
  },
  {
    path: "pageTranslationList",
    component: <PageTranslationTable />,
  },
  {
    path: "editPageTranslationList",
    component: <EditPageMasterTranslation />,
  },
  {
    path: "pageTranslation",
    component: <AddPageMasterTranslation />,
  },
  {
    path: "schoolTranslationList",
    component: <SchoolTranslationTable />,
  },
  {
    path: "editSchoolTranslationList",
    component: <EditSchoolMasterTranslation />,
  },
  {
    path: "schoolTranslation",
    component: <AddSchoolMasterTranslation />,
  },
  {
    path: "stateTranslationList",
    component: <StateTranslationTable />,
  },
  {
    path: "editStateTranslationList",
    component: <EditStateMasterTranslation />,
  },
  {
    path: "stateTranslation",
    component: <AddStateMasterTranslation />,
  },
  {
    path: "subjectTranslationList",
    component: <SubjectTranslationTable />,
  },
  {
    path: "editSubjectTranslationList",
    component: <EditSubjectMasterTranslation />,
  },
  {
    path: "subjectTranslation",
    component: <AddSubjectMasterTranslation />,
  },
  {
    path: "villageTranslationList",
    component: <VillageTranslationTable />,
  },
  {
    path: "editVillageTranslationList",
    component: <EditVillageMasterTranslation />,
  },
  {
    path: "villageTranslation",
    component: <AddVillageMasterTranslation />,
  },

  {
    path: "addlanguage",
    component: <AddLangaugeMaster />,
  },
  {
    path: "editlanguage",
    component: <EditLangaugeMaster />,
  },

  {
    path: "addactivity",
    component: <AddActivityMaster />,
  },
  {
    path: "editactivity",
    component: <EditActivityMaster />,
  },

  {
    path: "addsubconcept",
    component: <AddSubConceptMaster />,
  },
  {
    path: "editsubconcept",
    component: <EditSubConceptMaster />,
  },

  {
    path: "addchapter",
    component: <AddChapterMaster />,
  },

  {
    path: "addLearningOutcome",
    component: <AddLearningOutcomeMaster />,
  },
  {
    path: "learningOutcome",
    component: <ListLearningOutcome />,
  },
  {
    path: "addLearningOutcome",
    component: <AddLearningOutcomeMaster />,
  },
  {
    path: "editLearningOutcome",
    component: <EditLearningOutcomeMaster />,
  },
  {
    path: "addPageContent",
    component: <AddPageContent />,
  },
  {
    path: "addPageImage",
    component: <AddPageImage />,
  },

  {
    path: "listPageContent",
    component: <ListPageContentMaster />,
  },
  {
    path: "listPageImage",
    component: <ListPageImageMaster />,
  },
  {
    path: "editPageContent",
    component: <EditPageContent />,
  },
  {
    path: "editPageImage",
    component: <EditPageImage />,
  },
  {
    path: "status",
    component: <ListStatusMaster />,
  },
  {
    path: "addStatus",
    component: <AddStatusMaster />,
  },
  {
    path: "editStatus",
    component: <EditStatusMaster />,
  },
  {
    path: "idea",
    component: <ListIdeaMaster />,
  },
  {
    path: "editIdea",
    component: <EditIdeaMaster />,
  },
  {
    path: "ideaStatus",
    component: <ListIdeaStatusMaster />,
  },
  {
    path: "configuration",
    component: <ListConfiguration />,
  },
  {
    path: "addConfiguration",
    component: <AddConfigurationMaster />,
  },
  {
    path: "editConfiguration",
    component: <EditConfigurationMaster />,
  },
  {
    path: "addProjectActivity",
    component: <AddProjectActivityMaster />,
  },
  {
    path: "editProjectActivity",
    component: <EditProjectActivityMaster />,
  },
  {
    path: "innovativepaathshala/coursecontent",
    component: <CourseContentMaster />,
  },
  {
    path: "innovativepaathshala/addcoursecontent",
    component: <AddCourseContentMaster />,
  },
  {
    path: "innovativepaathshala/editcoursecontent",
    component: <EditCourseContentMaster />,
  },
  {
    path: "innovativepaathshala/chapterWeek",
    component: <CourseSubContentMaster />,
  },
  {
    path: "innovativepaathshala/addChapterWeek",
    component: <AddCourseSubContentMaster />,
  },
  {
    path: "innovativepaathshala/editChapterWeek",
    component: <EditCourseSubContentMaster />,
  },
  {
    path: "innovativePathshala",
    component: <ListInnovativePathshalaMaster />,
  },
  {
    path: "addInnovativePathshala",
    component: <AddInnovativePathshalaMaster />,
  },
  {
    path: "editInnovativePathshala",
    component: <EditInnovativePathshalaMaster />,
  },
  // {
  //   path: "innovativepaathshala/chapterweek",
  //   component: <ChapterWeekMaster />,
  // },
  // {
  //   path: "innovativepaathshala/addchapterweek",
  //   component: <AddChapterWeekMaster />,
  // },
  // {
  //   path: "innovativepaathshala/editchapterweek",
  //   component: <EditChapterWeekMaster />,
  // },
  {
    path: "teachermanual",
    component: <HandBookMasterView />,
  },
  {
    path: "addteachermanual",
    component: <AddHandBookMaster />,
  },
  {
    path: "editteachermanual",
    component: <EditHandBookMaster />,
  },
  {
    path: "knowledge",
    component: <ListKnowledgeMaster />,
  },
  {
    path: "addKnowledge",
    component: <AddKnowledgeMaster />,
  },
  {
    path: "editKnowledge",
    component: <EditKnowledgeMaster />,
  },
  {
    path: "casestudy",
    component: <CaseStudyMaster/>,
  },
  {
    path: "addCasestudy",
    component: <AddCaseStudyMaster />,
  },
  {
    path: "editCasestudy",
    component: <EditCaseStudyMaster />,
  },
];
