import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import AdminLayout from "../components/admin/AdminLayout";
import { adminRoutes } from "./AdminRoutes";
import Login from "../components/admin/user/Login";
import NotFound from "components/admin/404/NotFound";
import ForgotPassword from "components/admin/user/ForgotPassword";
import Resetpassword from "components/admin/user/ResetPassword";

const childAdminRoutes = [];
const isLoggedIn = localStorage.getItem("isLoggedIn");

adminRoutes.forEach((item) => {
  let r = {
    path: item.path,
    element: item.component,
  };

  childAdminRoutes.push(r);
});

// export const router = createBrowserRouter(
//   createRoutesFromElements(
//     <>
//       <Route path="/login" element={<Login />} />
//       {/* <Route
//         path="/login"
//         element={
//           isLoggedIn ? <Navigate replace to={"/dashboard"} /> : <Login />
//         }
//       /> */}
//       <Route path="/forgot" element={<ForgotPassword />} />
//       <Route path="/reset" element={<Resetpassword />} />
//       <Route path="*" element={<NotFound />} />
//       <Route element={<AdminLayout />}>
//         {childAdminRoutes.map((item, index) => (
//           <Route key={index} path={item.path} element={item.element} />
//         ))}
//       </Route>
//     </>
//   )
// );

export const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reset",
    element: <Resetpassword />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/",
    element: <AdminLayout />,
    children: childAdminRoutes,
  },
]);
