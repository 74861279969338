import React, { useState, useEffect } from "react";
import {
  updateInnovativePathshala,
  editInnovativePathshala,
} from "services/innovativePathshala.service";
import { getCourseSubContentList } from "services/coursesubcontentmaster.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import { IMAGEURL } from "global";

export default function EditInnovativePathshalaMaster() {
  const parse = require("html-react-parser");
  const [courseSubContent, setCourseSubContent] = useState("");
  const [title, setTitle] = useState("");
  const [innovativePathshalaID, setInnovativePathshalaID] = useState("");
  const [courseSubContentID, setCourseSubContentID] = useState("");
  const [rawHtmlContent, setRawHtmlContent] = useState("");
  const [viewCount, setViewCount] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getCourseSubContentList(location.state).then((data) => {
      setCourseSubContent(data.Result);
    });

    editInnovativePathshala(location.state).then((data) => {
      setCourseSubContentID(data.Result[0].CourseSubContentID);
      setInnovativePathshalaID(data.Result[0].InnovativePathShalaID);
      setRawHtmlContent(data.Result[0].Content);
      setTitle(data.Result[0].Title);
      setViewCount(data.Result[0].ViewCount);
      setAttachment(data.Result[0].Attachment);
      setAttachmentType(data.Result[0].AttachmentType);
    });
  }, []);

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    setAttachmentType(ext);
    if (file) {
      const reader = new FileReader();
      if (reader !== undefined && file !== undefined) {
        reader.onload = _handleReaderLoaded;
        reader.readAsBinaryString(file);
      }
    } else if (ext === "") {
      return false;
    } else {
      setAttachment("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setImageUrl(setDp);
  };

  const UpdateInnovativePathshala = (e) => {
    e.preventDefault();
    let content = parse(rawHtmlContent).props.children;
    let Param = {
      innovativePathshalaID,
      title,
      courseSubContentID,
      content,
      viewCount: viewCount ?? 0,
      attachment: imageUrl !== "" ? attachment : imageUrl,
      attachmentType: attachmentType,
      createBy: AdminID,
    };

    updateInnovativePathshala(Param).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/innovativePathshala");
        }, 1500);
      } else {
        console.log("toast",response);
        toast.error(JSON.stringify(response));      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit InnovativePathshala
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateInnovativePathshala}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Course SubContent
                        <select
                          value={courseSubContentID}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setCourseSubContentID(e.target.value);
                          }}
                        >
                          <option hidden>CourseSubContent List</option>
                          {courseSubContent &&
                            courseSubContent?.map((item, index) => {
                              return (
                                <option
                                  value={item.CourseSubContentID}
                                  key={index}
                                >
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        required
                        value={title}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="InnovativePathshala-Name"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Content
                      </label>
                      <ReactQuill
                        theme="snow"
                        value={rawHtmlContent}
                        onChange={(e) => {
                          setRawHtmlContent(e);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="attachment"
                        onChange={handleImage}
                      />
                      {imageUrl ? (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      ) : (
                        <img src={`${IMAGEURL}${attachment}`} width="100" />
                      )}
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}
