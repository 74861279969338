import axios from "axios";
const { APIURL } = require("global");

//To get ConfigurationMaster List for Table
const getConfigurationMasterList = async () => {
  const listApi = APIURL + "configuration/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
// to edit configuration
const editConfiguration = async (param) => {
  const editConfigurationAPI = APIURL + `configuration/${param}`;
  try {
    let Result = await axios.get(editConfigurationAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add ConfigurationMaster List
const addConfigurationMaster = async (param) => {
  const addConfigurationAPI = APIURL + "configuration/create";
  try {
    let Result = await axios.post(addConfigurationAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To update ConfigurationMaster List
const updateConfiguration = async (param) => {
  const updateConfigurationAPI = APIURL + "configuration/update";

  try {
    let Result = await axios.post(updateConfigurationAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//to activate configuration
const activateConfiguration = async (param) => {
  const activateConfigurationAPI = APIURL + "configuration/activate";
  try {
    let Result = await axios.post(activateConfigurationAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//deactivate configuration
const deactivateConfiguration = async (param) => {
  const deactivateConfigurationAPI = APIURL + "configuration/deactivate";
  try {
    let Result = await axios.post(deactivateConfigurationAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
// to delete configuration
const deleteConfiguration = async (param) => {
  const deleteConfigurationAPI = APIURL + "configuration/delete";
  try {
    let Result = await axios.post(deleteConfigurationAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Configuration
const getPageWiseRecordConfiguration = async (param) => {
  const getPageWiseRecordConfigurationAPI =
    APIURL + "configuration/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordConfigurationAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getConfigurationMasterList,
  addConfigurationMaster,
  updateConfiguration,
  deleteConfiguration,
  deactivateConfiguration,
  activateConfiguration,
  editConfiguration,
  getPageWiseRecordConfiguration,
};
