import axios from 'axios';
const { APIURL } = require("global");

//To get Village List for Table
const getVillageList = async () => {
  const listApi = APIURL + "village/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Village
const addVillage = async (param) => {
  const addVillageAPI = APIURL + "village/create";
  try {
    let Result = await axios.post(addVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update Village
const updateVillage = async (param) => {
  const updateVillageAPI = APIURL + "village/update";
  try {
    let Result = await axios.post(updateVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Village
const deleteVillage = async (param) => {
  const deleteVillageAPI = APIURL + "village/delete";
  try {
    let Result = await axios.post(deleteVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Village
const activateVillage = async (param) => {
  const activateVillageAPI = APIURL + "village/activate";
  try {
    let Result = await axios.post(activateVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactive Village
const deactivateVillage = async (param) => {
  const deactivateVillageAPI = APIURL + "village/deactivate";
  try {
    let Result = await axios.post(deactivateVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//Edit  village Page
const editVillage = async (param) => {
  const editVillageAPI = APIURL + `village/${param}`;
  try {
    let Result = await axios.post(editVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get Village By Keyword
const getVillageByKeyword = async (param) => {
  const getVillageByKeywordAPI = APIURL + "village/searchByKeyword";

  try {
    let Result = await axios.post(getVillageByKeywordAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
export {
  getVillageList,
  addVillage,
  updateVillage,
  deleteVillage,
  activateVillage,
  deactivateVillage,
  editVillage,
  getVillageByKeyword
};
