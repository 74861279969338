import React, { useEffect, useState } from "react";
import { BoardTranslationView } from "view/admin/Translation/BoardTranslationView";
import { getBoardTranslationList } from "services/Translation/BoardTranslation.service";

export default function BoardTranslationTable({ color }) {
  const [boardTranslation, setBoardTranslation] = useState([]);

  useEffect(() => {
    getBoardTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setBoardTranslation(data.Result);
      });
  }, []);

  return <BoardTranslationView BoardTranslationData={boardTranslation}></BoardTranslationView>;
}
