const { APIURL } = require("global");

const getBlockTranslationList = async () => {
  const blockTranslationListApi = APIURL + "blockTranslation/list";
  return await fetch(blockTranslationListApi);
};

const getFilterBlockTranslation = async (param) => {
  const getFilterBlockByLanguageIDApi = APIURL + `blockTranslation/getFilterBlockByLanguageID/${param}`;
  return await fetch(getFilterBlockByLanguageIDApi);
};

const getBlockTranslationByID = async (param) => {
  const getBlockTranslationByIDApi =
    APIURL + `blockTranslation/${param.blockTranslationID}`;
  return await fetch(getBlockTranslationByIDApi);
};

const UpdateBlockTranslation = async (param) => {
    const updateBlockTranslation = APIURL + "blockTranslation/update";
    return await fetch(updateBlockTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addBlockMasterTranslation = async (param) => {
    const addBlockTranslation = APIURL + "blockTranslation/create";
    return await fetch(addBlockTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBlockTranslationDataByLanguageID = async (param) => {
    const getBlockTranslationDataByLanguageID = APIURL + "blockTranslation/getBlockTranslationData";
    return await fetch(getBlockTranslationDataByLanguageID, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getBlockTranslationList,
  getFilterBlockTranslation,
  getBlockTranslationByID,
  UpdateBlockTranslation,
  addBlockMasterTranslation,
  getBlockTranslationDataByLanguageID,
};
