import { React } from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { router } from "./Routes/Routes";

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById("root")
);
