import React, { useState, useEffect } from "react";
import {
  addDistrict,
  getDivisionListByStateID,
} from "services/districtmaster.service";
import { getStateList } from "services/statemaster.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DEFAULT_LANGUAGE } from "global";

export default function AddDistrictMaster() {
  const [State, setState] = useState("");
  const [DistrictName, setDistrictName] = useState("");
  const [StateID, setStateID] = useState("");
  const [LanguageID, setLanguageID] = useState(DEFAULT_LANGUAGE);
  const [Division, setDivision] = useState("");
  const [NativeLanguageName, setNativeLanguageName] = useState("");
  const [DistrictCode, setDistrictCode] = useState("");
  const [DivisionID, setDivisionID] = useState("");

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const navigate = useNavigate();
  useEffect(() => {
    StateList();
  }, []);
  const StateList = () => {
    getStateList().then((data) => {
      setState(data.Result);
    });
  };
  const DivisionList = (id) => {
    getDivisionListByStateID(id).then((data) => {
      console.log(data);
      setDivision(data.Result);
    });
  };

  const AddDistrict = (e) => {
    e.preventDefault();
    let Param = {
      districtName: DistrictName,
      stateID: StateID,
      nativeLanguageName: NativeLanguageName,
      districtCode: DistrictCode,
      langaugeID: LanguageID,
      divisionID: DivisionID,
      createBy: AdminID,
    };

    addDistrict(Param).then((response) => {
      if (response.Status === "Success" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/district");
        }, 1500);
      } else if (StateID === "") {
        toast.error("State field is required");
      } else if (DivisionID === "") {
        toast.error("Division field is required");
      } else if (DistrictCode.length > 4) {
        toast.error("The districtCode may not be greater than 4 characters");
      } else {
        toast.error(response.Message);
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add District</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddDistrict}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose State
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setStateID(e.target.value);
                            DivisionList({ stateID: e.target.value });
                          }}
                        >
                          <option hidden>State List</option>
                          {State &&
                            State?.map((item, index) => {
                              return (
                                <option value={item.StateID} key={index}>
                                  {item.StateName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Division
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setDivisionID(e.target.value);
                          }}
                        >
                          <option hidden>Division List</option>
                          {Division &&
                            Division?.map((item, index) => {
                              return (
                                <option value={item.DivisionID} key={index}>
                                  {item.DivisionName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        District Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="District-Name"
                        onChange={(e) => setDistrictName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        District Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="District-Code"
                        onChange={(e) => setDistrictCode(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Native Language Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Native-language-name"
                        onChange={(e) => setNativeLanguageName(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}
