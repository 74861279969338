import React, { useEffect, useState } from "react";
import { CourseTranslationView } from "view/admin/Translation/CourseTranslationView";
import { getCourseTranslationList } from "services/Translation/CourseMasterTranslation.service";

export default function CourseTranslationTable({ color }) {
  const [courseTranslation, setCourseTranslation] = useState([]);

  useEffect(() => {
    getCourseTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setCourseTranslation(data.Result);
      });
  }, []);

  return <CourseTranslationView courseTranslation={courseTranslation}></CourseTranslationView>;
}
