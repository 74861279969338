import { ToastContainer, toast } from "react-toastify";

// export const userNameRegex = /^[a-zA-Z0-9_@]+$/;
export const userNameRegex = /^[a-zA-Z0-9_@!#$%^&*()\-+=~`{}[\]|:;"'<>,.?/]+$/; //It allows alphanumeric characters, underscores, and all special characters listed in the regex
export const NameRegex = /^[A-Za-z\s]+$/;
export const nameRegex = /^[^\d\s!@#$%^&*()\-=_+[\]{}|\\;:'",.<>/?]+$/;

export const spaceRegex = /^[A-Za-z][A-Za-z\s]*$/;

// export const handleKeyPressForNumbers = (e) => {
//     const keyCode = e.keyCode || e.which;
//     // Block numeric key presses
//     if (keyCode >= 48 && keyCode <= 57) {
//       e.preventDefault();
//     }
//   };

export const handleKeyPressForNumbers = (event) => {
  const keyCode = event.which || event.keyCode;
  const isValidKey = keyCode >= 48 && keyCode <= 57; // Check if the key code represents a numeric digit (0-9)

  if (!isValidKey) {
    event.preventDefault(); // Prevent the input of non-numeric characters
  }
};

export const handleKeyPressforSpecialCharacters = (e) => {
  const keyCode = e.keyCode || e.which;
  const char = String.fromCharCode(keyCode);
  const pattern = /^[a-zA-Z\s]*$/; // Regular expression pattern to allow only alphabetic characters and spaces

  if (!pattern.test(char)) {
    e.preventDefault();
  }
};

export const KeyPressForNumeric = (event) => {
  const keyCode = event.which || event.keyCode;

  const isValidKey =
    (keyCode >= 48 && keyCode <= 57) ||
    keyCode === 8 ||
    keyCode === 37 ||
    keyCode === 39; // Check if the key code represents a numeric digit (0-9)

  if (!isValidKey) {
    toast.error("Type Only Numbers here");
    event.preventDefault(); // Prevent the input of non-numeric characters
  }
};

export const KeyPressForAlphabets = (event) => {
  const keyCode = event.which || event.keyCode;
  const isValidKey =
    (keyCode >= 97 && keyCode <= 122) ||
    (keyCode >= 65 && keyCode <= 90) ||
    keyCode === 8 ||
    keyCode === 32 ||
    keyCode === 16; // Check if the key code represents a numeric digit (0-9)

  if (!isValidKey) {
    toast.error("Type Only Alphabets here  ");
    event.preventDefault(); // Prevent the input of non-numeric characters
  }
};

//   const handlePaste = (e) => {
//     e.preventDefault();
//     const pastedValue = e.clipboardData.getData('text/plain');
//     const pattern = /^[^\d]+$/; // Regular expression pattern to exclude numeric characters

//     if (pattern.test(pastedValue)) {
//       setInputValue(pastedValue);
//     }
//   };
