import React, { useState, useEffect } from "react";
import { addUser } from "services/user.service";
import { getUserTypeList } from "services/usertypemaster.service";
import { getGradeMasterList } from "services/grademaster.service";
import { getBoardList } from "services/boardmaster.service";
import { getSubjectList } from "services/subjectmaster.service";
import { useNavigate } from "react-router-dom";
import { getStateList } from "services/statemaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  KeyPressForNumeric,
  KeyPressForAlphabets,
} from "globalKeyPressValidation";
import { getSchoolByStateID } from "services/schoolmaster.service";

export default function AddUsermanagement() {
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const currentDate = new Date().toISOString().split("T")[0];

  const [UserType, setUserType] = useState([]);
  const [UserTypeID, setUserTypeID] = useState("");
  const [UserName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [DOB, setDOB] = useState("");
  const [Contact, setContact] = useState("");
  const [Gender, setGender] = useState("");
  const [ProfilePic, setProfilePic] = useState("");
  const [Department, setDepartment] = useState("");
  const [UDiseCode, setUDiseCode] = useState("");
  const [School, setSchool] = useState([]);
  const [SchoolID, setSchoolID] = useState("");
  const [Grade, setGrade] = useState([]);
  const [GradeID, setGradeID] = useState("");
  const [Board, setBoard] = useState([]);
  const [BoardID, setBoardID] = useState("");
  const [Subject, setSubject] = useState([]);
  const [SubjectID, setSubjectID] = useState("");
  const [State, setState] = useState([]);
  const [StateID, setStateID] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [ContactError, setContactError] = useState();

  useEffect(() => {
    UserTypeList();
    BoardList();
    GradeList();
    SubjectList();
    StateList();
  }, []);

  const UserTypeList = () => {
    getUserTypeList().then((response) => {
      setUserType(response.Result);
    });
  };

  const BoardList = () => {
    getBoardList().then((data) => {
      setBoard(data.Result);
    });
  };
  const GradeList = () => {
    getGradeMasterList().then((data) => {
      setGrade(data.Result);
    });
  };
  const StateList = () => {
    getStateList().then((data) => {
      setState(data.Result);
    });
  };
  const SubjectList = () => {
    getSubjectList().then((data) => {
      setSubject(data.Result);
    });
  };

  const handleStateChange = (e) => {
    setStateID(e.target.value);
    let Param = {
      StateID: e.target.value,
    };
    console.log(Param);
    getSchoolByStateID(Param).then((response) => {
      setSchool(response.Result);
      setUDiseCode("");
    });
  };

  const handleSchoolChange = (e) => {
    const selectedSchool = School.filter(
      (item) => item.SchoolID == e.target.value
    );
    setUDiseCode(selectedSchool[0].UDiseCode);
    setSchoolID(selectedSchool[0].SchoolID);
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (file) {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setProfilePic("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setProfilePic(btoa(binaryString));
    setImageUrl(setDp);
  };

  const AddUser = (e) => {
    e.preventDefault();
    let Param = {
      userTypeID: UserTypeID,
      username: UserName,
      password: Password,
      name: Name,
      email: Email,
      dob: DOB,
      contact: Contact,
      gender: Gender,
      profilePic: ProfilePic,
      department: Department,
      udiseCode: UDiseCode,
      schoolID: SchoolID,
      gradeID: GradeID,
      boardID: BoardID,
      subjectID: SubjectID,
      stateID: StateID,
      createBy: AdminID,
    };

    addUser(Param).then((response) => {
      if (response.Status === "Success" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/user");
        }, 1500);
      } else if (UserTypeID === "") {
        toast.error("UserType field is required");
      } else if (SchoolID === "") {
        toast.error("School field is required");
      } else if (BoardID === "") {
        toast.error("Board field is required");
      } else if (GradeID === "") {
        toast.error("Grade field is required");
      } else if (SubjectID === "") {
        toast.error("Subject field is required");
      } else if (SubjectID === "") {
        toast.error("Subject field is required");
      } else {
        toast.error(response.Message);
      }
    });
  };

  const ContactValidation = () => {
    let regex = /^[6-9]\d{9}$/;
    if (regex.test(Contact)) {
      setContactError(false);
    } else {
      toast.warn("Please Enter a Valid 10 Digit Mobile Number");
      setContactError(true);
    }
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add New User</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddUser}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        User-Type
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => setUserTypeID(e.target.value)}
                          required
                        >
                          <option hidden>Select User-Type</option>
                          {UserType &&
                            UserType.map((item, index) => {
                              return (
                                <option value={item.UserTypeID} key={index}>
                                  {item.UserTypeName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-user-name"
                      >
                        User Name
                      </label>
                      <input
                        required
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="User-Name"
                        onChange={(e) => setUserName(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        required
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={(e) => KeyPressForAlphabets(e)}
                        required
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Email
                      </label>
                      <input
                        required
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Date of Birth
                      </label>
                      <input
                        required
                        max={currentDate}
                        type="date"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Date of Birth"
                        onChange={(e) => setDOB(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-email"
                      >
                        Contact
                      </label>
                      <input
                        required
                        type="text"
                        maxLength={10}
                        minLength={10}
                        className=" border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Contact"
                        onChange={(e) => {
                          setContact(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          KeyPressForNumeric(e);
                        }}
                        onBlur={ContactValidation}
                      />
                      {ContactError && (
                        <p className=" text-red-600 text-center">
                          Enter Valid 10 Digit Mobile Number
                        </p>
                      )}
                    </div>

                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Gender
                    </label>

                    <div className="relative w-full mb-3">
                      <select
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        id="grid-BoardID"
                        onChange={(e) => setGender(e.target.value)}
                        required
                      >
                        <option hidden value="">
                          Select Gender
                        </option>

                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Profile Pic
                      </label>
                      <input
                        required
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Profilepic"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                    <div>
                      {ProfilePic && (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Department
                      </label>
                      <input
                        required
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Department"
                        onChange={(e) => setDepartment(e.target.value)}
                        onKeyPress={(e) => KeyPressForAlphabets(e)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        State
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          value={StateID}
                          onChange={handleStateChange}
                        >
                          <option hidden>Select State</option>
                          {State &&
                            State.map((item, index) => {
                              return (
                                <option value={item.StateID} key={index}>
                                  {item.StateName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        School
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={handleSchoolChange}
                        >
                          <option hidden>Select School</option>
                          {School &&
                            School.map((item, index) => {
                              return (
                                <option value={item.SchoolID} key={index}>
                                  {item.SchoolName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Udise Code
                      </label>
                      <input
                        value={UDiseCode}
                        type="text"
                        readOnly
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Udise Code"
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Board
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => setBoardID(e.target.value)}
                        >
                          <option hidden>Select Board</option>
                          {Board &&
                            Board.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Grade
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => setGradeID(e.target.value)}
                        >
                          <option hidden>Select Grade</option>
                          {Grade &&
                            Grade.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Subject
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => setSubjectID(e.target.value)}
                        >
                          <option hidden>Select Subject</option>
                          {Subject &&
                            Subject.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
