import axios from "axios";
const { APIURL } = require("global");

//To get Cluster List for Table
const getClusterList = async () => {
  const listApi = APIURL + "cluster/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To add Cluster
const addCluster = async (param) => {
  const addClusterAPI = APIURL + "cluster/create";
  try {
    let Result = await axios.post(addClusterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Cluster
const updateCluster = async (param) => {
  const updateClusterAPI = APIURL + "cluster/update";
  try {
    let Result = await axios.post(updateClusterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//Edit  Cluster Page
const editCluster = async (param) => {
  const clusterAPI = APIURL + `cluster/${param}`;
  try {
    let Result = await axios.get(clusterAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Cluster
const deleteCluster = async (param) => {
  const deleteClusterAPI = APIURL + "cluster/delete";
  try {
    let Result = await axios.post(deleteClusterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Cluster
const activateCluster = async (param) => {
  const activateClusterAPI = APIURL + "cluster/activate";
  try {
    let Result = await axios.post(activateClusterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactive Cluster
const deactivateCluster = async (param) => {
  const deactivateClusterAPI = APIURL + "cluster/deactivate";
  try {
    let Result = await axios.post(deactivateClusterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Cluster
const getPageWiseRecordCluster = async (param) => {
  const getPageWiseRecordClusterAPI = APIURL + "cluster/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordClusterAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getClusterList,
  addCluster,
  editCluster,
  updateCluster,
  deleteCluster,
  activateCluster,
  deactivateCluster,
  getPageWiseRecordCluster,
};
