const { APIURL } = require("global");

const getDistrictTranslationList = async (param) => {
  const districtTranslationList = APIURL + "admin/login";
  return await fetch(districtTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterDistrictTranslation = async (param) => {
  const getFilterDistrict = APIURL + "admin/login";
  return await fetch(getFilterDistrict, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getDistrictTranslationByID = async (data) => {
  const getDistrictTranslationByIDApi =
    APIURL + `districtTranslation/${data.DistrictTranslationID}`;
  return await fetch(getDistrictTranslationByIDApi);
};

const UpdateDistrictTranslation = async (param) => {
    const updateDistrictTranslation = APIURL + "admin/login";
    return await fetch(updateDistrictTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addDistrictMasterTranslation = async (param) => {
    const addDistrictTranslation = APIURL + "admin/login";
    return await fetch(addDistrictTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getDistrictTranslationList,
  getFilterDistrictTranslation,
  getDistrictTranslationByID,
  UpdateDistrictTranslation,
  addDistrictMasterTranslation
};
