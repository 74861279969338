import React, { useState, useEffect } from "react";
import { getCountryList } from "services/countrymaster.service";
import { getStateListByCountryID } from "services/divisionmaster.service";
import {
  getDivisionListByStateID,
  getDistrictListByDivisionID,
  getBlockListByDistrictID,
  getClusterListByBlockID,
  getVillageListByClusterID,
  getSchoolTypeList,
  updateSchool,
  editSchool,
} from "services/schoolmaster.service";
import { getDistrictList } from "services/districtmaster.service";
import { getBlockList } from "services/blockmaster.service";
import { getClusterList } from "services/clustermaster.service";
import { getStateList } from "services/statemaster.service";
import { getDivisionList } from "services/divisionmaster.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getVillageList } from "services/villagemaster.service";

const EditSchoolMaster = () => {
  const [Country, setCountry] = useState([]);
  const [CountryID, setCountryID] = useState("");
  const [State, setState] = useState([]);
  const [StateID, setStateID] = useState("");
  const [Division, setDivision] = useState([]);
  const [DivisionID, setDivisionID] = useState("");
  const [District, setDistrict] = useState([]);
  const [DistrictID, setDistrictID] = useState("");
  const [Block, setBlock] = useState([]);
  const [BlockID, setBlockID] = useState("");
  const [Cluster, setCluster] = useState([]);
  const [ClusterID, setClusterID] = useState("");
  const [Village, setVillage] = useState([]);
  const [VillageID, setVillageID] = useState("");
  const [SchoolType, setSchoolType] = useState([]);
  const [SchoolTypeID, setSchoolTypeID] = useState("");
  const [SchoolName, setSchoolName] = useState("");
  const [UdiseCode, setUdiseCode] = useState("");
  const [Address, setAddress] = useState("");
  const [PinCode, setPinCode] = useState("");

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    CountryList();
    StateList();
    DivisionList();
    DistrictList();
    BlockList();
    ClusterList();
    VillageList();
    SchoolTypeList();
  }, []);
  const CountryList = () => {
    getCountryList().then((data) => {
      setCountry(data.Result);
      editSchool(location.state).then((resp) => {
        setSchoolName(resp.Result[0].SchoolName);
        setUdiseCode(resp.Result[0].UDiseCode);
        setAddress(resp.Result[0].Address);
        setPinCode(resp.Result[0].PinCode);
        setCountryID(resp.Result[0].CountryID);
        setSchoolTypeID(resp.Result[0].SchoolTypeID);
        setStateID(resp.Result[0].StateID);
        setDistrictID(resp.Result[0].DistrictID);
        setBlockID(resp.Result[0].BlockID);
        setVillageID(resp.Result[0].VillageID);
        setClusterID(resp.Result[0].ClusterID);
        setDivisionID(resp.Result[0].DivisionID);
      });
    });
  };

  const filterState = (id) => {
    setCountryID(id);
    getStateListByCountryID(id)
      .then((response) => response.json())
      .then((data) => {
        setState(data.Result);
      });
  };
  const filterDivision = (id) => {
    setStateID(id);
    let obj = {
      stateID: id,
    };
    getDivisionListByStateID(obj).then((data) => {
      setDivision(data.Result);
    });
  };
  const filterDistrict = (id) => {
    setDivisionID(id);
    let obj = {
      divisionID: id,
    };

    getDistrictListByDivisionID(obj).then((data) => {
      setDistrict(data.Result);
    });
  };
  const filterBlock = (id) => {
    setDistrictID(id);
    let obj = {
      districtID: id,
    };
    getBlockListByDistrictID(obj).then((data) => {
      setBlock(data.Result);
    });
  };
  const filterCluster = (id) => {
    setBlockID(id);
    let obj = {
      blockID: id,
    };
    getClusterListByBlockID(obj).then((data) => {
      setCluster(data.Result);
    });
  };
  const filterVillage = (id) => {
    setClusterID(id);
    let obj = {
      clusterID: id,
    };
    getVillageListByClusterID(obj).then((data) => {
      setVillage(data.Result);
    });
  };

  const StateList = () => {
    getStateList().then((data) => {
      setState(data.Result);
    });
  };
  const DivisionList = () => {
    getDivisionList().then((data) => {
      setDivision(data.Result);
    });
  };
  const DistrictList = () => {
    getDistrictList().then((data) => {
      setDistrict(data.Result);
    });
  };

  const BlockList = () => {
    getBlockList().then((data) => {
      setBlock(data.Result);
    });
  };
  const ClusterList = () => {
    getClusterList().then((data) => {
      setCluster(data.Result);
    });
  };

  const VillageList = () => {
    getVillageList().then((data) => {
      setVillage(data.Result);
    });
  };
  const SchoolTypeList = () => {
    getSchoolTypeList().then((data) => {
      setSchoolType(data.Result);
    });
  };

  const UpdateSchool = (e) => {
    e.preventDefault();
    let Param = {
      schoolID: location.state,
      schoolName: SchoolName,
      udiseCode: UdiseCode,
      schoolTypeID: SchoolTypeID,
      countryID: CountryID,
      stateID: StateID,
      districtID: DistrictID,
      divisionID: DivisionID,
      blockID: BlockID,
      clusterID: ClusterID,
      villageID: VillageID,
      address: Address,
      pinCode: PinCode,
      createBy: AdminID,
    };

    updateSchool(Param).then((response) => {
      if (response.Status && response.Status.toLowerCase() === "success") {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/school");
        }, 1500);
      } else if (response.success === false && response.status === "ERROR") {
        toast.error(response.message);
      } else {
        toast.error("Please fill the required fields");
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit School</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateSchool}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Country
                        <select
                          value={CountryID}
                          onChange={(e) => filterState(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>Country List</option>
                          {Country &&
                            Country?.map((item, index) => {
                              return (
                                <option value={item.CountryID} key={index}>
                                  {item.CountryName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose State
                        <select
                          value={StateID}
                          onChange={(e) => filterDivision(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>State List</option>
                          {State &&
                            State?.map((item, index) => {
                              return (
                                <option value={item.StateID} key={index}>
                                  {item.StateName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Division
                        <select
                          value={DivisionID}
                          onChange={(e) => filterDistrict(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>Division List</option>
                          {Division &&
                            Division?.map((item, index) => {
                              return (
                                <option value={item.DivisionID} key={index}>
                                  {item.DivisionName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose District
                        <select
                          value={DistrictID}
                          onChange={(e) => filterBlock(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>District List</option>
                          {District &&
                            District?.map((item, index) => {
                              return (
                                <option value={item.DistrictID} key={index}>
                                  {item.DistrictName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Block
                        <select
                          value={BlockID}
                          onChange={(e) => filterCluster(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>Block List</option>
                          {Block &&
                            Block?.map((item, index) => {
                              return (
                                <>
                                  <option value={item.BlockID} key={index}>
                                    {item.BlockName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Cluster
                        <select
                          value={ClusterID}
                          onChange={(e) => filterVillage(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>Cluster List</option>
                          {Cluster &&
                            Cluster?.map((item, index) => {
                              return (
                                <>
                                  <option value={item.ClusterID} key={index}>
                                    {item.ClusterName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Village
                        <select
                          value={VillageID}
                          onChange={(e) => e.target.value}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>Village List</option>
                          {Village &&
                            Village?.map((item, index) => {
                              return (
                                <option value={item.VillageID} key={index}>
                                  {item.VillageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose School Type
                        <select
                          value={SchoolTypeID}
                          onChange={(e) => setSchoolTypeID(e.target.value)}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        >
                          <option hidden>School Type</option>

                          {SchoolType &&
                            SchoolType?.map((item, index) => {
                              return (
                                <option value={item.schoolTypeName} key={index}>
                                  {item.schoolTypeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        School Name
                      </label>
                      <input
                        type="text"
                        required
                        value={SchoolName}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="School-Name"
                        onChange={(e) => {
                          setSchoolName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Udise Code
                      </label>
                      <input
                        type="text"
                        required
                        value={UdiseCode}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Udise-Code"
                        onChange={(e) => {
                          setUdiseCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        required
                        value={Address}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Pin Code
                      </label>
                      <input
                        type="text"
                        required
                        value={PinCode}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Pin-Code"
                        onChange={(e) => {
                          setPinCode(e.target.value);
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditSchoolMaster;
