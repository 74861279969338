import axios from "axios";
const { APIURL } = require("global");

// eslint-disable-next-line no-undef
//To get CaseStudyMaster List for Table
const getCaseStudyMasterList = async () => {
  const listApi = APIURL + "caseStudy/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
export {getCaseStudyMasterList}