import React, { useEffect, useState } from "react";
import { updateActivity, editActivity } from "services/activity.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { getBoardList } from "services/boardmaster.service";
import { getSubjectByGradeID } from "services/coursemaster.service";
import { getGradeMasterList } from "services/grademaster.service";
import { getCourseSubContentList } from "services/coursesubcontentmaster.service";
import "react-toastify/dist/ReactToastify.css";
import { IMAGEURL } from "global";

const EditActivityMaster = () => {
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("");
  const [concept, setConcept] = useState("");
  const [boardID, setBoardID] = useState("");
  const [gradeID, setGradeID] = useState("");
  const [subjectID, setSubjectID] = useState("");
  const [boardList, setBoardList] = useState("");
  const [gradeList, setGradeList] = useState("");
  const [subjectList, setSubjectList] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [courseSubContentID, setCourseSubContentID] = useState("");
  const [courseSubContentList, setCourseSubContentList] = useState([]);

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    DropDownList();
  }, []);

  const DropDownList = () => {
    getBoardList().then((data) => {
      setBoardList(data.Result);
      getGradeMasterList().then((result) => {
        setGradeList(result.Result);
        getCourseSubContentList().then((resp) => {
          setCourseSubContentList(resp.Result);
          editActivity(location.state).then((value1) => {
            console.log(value1);
            setDescription(value1.Result[0].Description);
            setAttachment(value1.Result[0].Attachment);
            setConcept(value1.Result[0].Concept);
            setGradeID(value1.Result[0].GradeID);
            setBoardID(value1.Result[0].BoardID);
            setCourseSubContentID(value1.Result[0].CourseSubContentID);
            setSubjectID(value1.Result[0].SubjectID);
            let obj = {
              gradeID: value1.Result[0].GradeID,
            };
            getSubjectByGradeID(obj).then((response) => {
              setSubjectList(response.Result);
            });
          });
        });
      });
    });
  };

  const UpdateActivity = (e) => {
    e.preventDefault();
    let Param = {
      ID: location.state,
      Description: description,
      Attachment: attachment,
      Concept: concept,
      SubjectID: subjectID,
      GradeID: gradeID,
      BoardID: boardID,
      CourseSubContentID: courseSubContentID,
      CreateBy: AdminID,
    };

    updateActivity(Param).then((response) => {
      if (response?.Status === "SUCCESS" && response?.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/activity");
        }, 1500);
      } else if (response?.Status === "SUCCESS" && response?.Error === true) {
        toast.error(response.Message);
      } else {
        toast.error(response?.Result.errors);
      }
    });
  };

  const getSubjectListByGradeID = (e) => {
    setGradeID(e.target.value);
    let obj = {
      gradeID: e.target.value,
    };
    getSubjectByGradeID(obj).then((result) => {
      setSubjectList(result.Result);
    });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (file) {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setAttachment("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setImageUrl(setDp);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Activity</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateActivity}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Board
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={boardID}
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden>Board List</option>
                          {boardList &&
                            boardList?.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Course Sub Content
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={courseSubContentID}
                          onChange={(e) => {
                            setCourseSubContentID(e.target.value);
                          }}
                        >
                          <option hidden>Course Sub Content List</option>
                          {console.log(courseSubContentID)}
                          {courseSubContentList &&
                            courseSubContentList?.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Grade
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={gradeID}
                          onChange={(e) => {
                            getSubjectListByGradeID(e);
                          }}
                        >
                          <option hidden>Grade List</option>
                          {gradeList &&
                            gradeList?.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={subjectID}
                          onChange={(e) => {
                            setSubjectID(e.target.value);
                          }}
                        >
                          <option hidden>Subject List</option>
                          {subjectList &&
                            subjectList?.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Concept
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Concept"
                        onChange={(e) => setConcept(e.target.value)}
                        value={concept}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                    {imageUrl ? (
                      <>
                        <img src={imageUrl} alt="not found image" width="100" />
                      </>
                    ) : (
                      <img src={`${IMAGEURL}${attachment}`} width="100" />
                    )}
                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditActivityMaster;
