import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getFilterBlockTranslation } from "services/Translation/BlockTranslation.service";
import { getLanguageMasterList } from "services/languages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const BlockTranslationView = ({ BlockTranslationData }) => {
	const [blockTranslation, setBlockTranslation] =
		useState(BlockTranslationData);
	const [filterLanguageList, setFilterLanguageList] = useState("");
	const navigate = useNavigate();
	useEffect(() => {
		setBlockTranslation(BlockTranslationData);
		getLanguageMasterList()
			.then((response) => response.json())
			.then((data) => {
				setFilterLanguageList(data.Result);
			});
	}, [BlockTranslationData]);

	const RedirectToEditBlockTranslation = async (e) => {
		e.preventDefault();
		navigate("/editBlockTranslation", {
			state: e.currentTarget.id,
		});
	};

	const getFilterBlockTranslationData = (e) => {
		getFilterBlockTranslation(e.target.value)
			.then((response) => response.json())
			.then((data) => {
				setBlockTranslation(data.Result);
			});
	};
	return (
		<>
			<div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
				<div className="mb-0 px-4 py-3 border-0 bg-slate-300">
					<div className="flex flex-wrap items-center">
						<div className="w-full max-w-full flex-grow flex-1">
							<h3 className="font-semibold text-lg text-black">
								List of Translation
							</h3>
						</div>
						<div className="w-full">
							<div className="mx-4 my-2">
								<label className="font-semibold flex flex-col w-1/4 text-black">
									Language
									<select
										className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
										onChange={getFilterBlockTranslationData}
									>
										<option hidden>Language</option>
										{filterLanguageList &&
											filterLanguageList?.map((item, index) => {
												return (
													<option value={item.LanguageID} key={index}>
														{item.LanguageName}
													</option>
												);
											})}
									</select>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className="block w-full overflow-x-auto">
					{/* Projects table */}
					<table className="items-center w-full bg-transparent border-collapse">
						<thead>
							<tr className="bg-blue-950">
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Block Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Action
								</th>
							</tr>
						</thead>

						<tbody>
							{blockTranslation.length > 0 ? (
								blockTranslation.map((block) => {
									return (
										<tr key={block.BlockID}>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{block.BlockName}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
												<i
													id={block.BlockTranslationID}
													class="fas fa-edit"
													onClick={RedirectToEditBlockTranslation}
												></i>
											</td>
										</tr>
									);
								})
							) : (
								<p className="text-black">No Data Found</p>
							)}
						</tbody>
					</table>{" "}
					<ToastContainer />
				</div>
			</div>
		</>
	);
};
