import React from "react";
import ProjectActivityMasterView from "view/admin/ProjectActivityMasterView";
const ListProjectActivityMaster = () => {
  return (
    <>
      <ProjectActivityMasterView />
    </>
  );
};

export default ListProjectActivityMaster;
