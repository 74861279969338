const { APIURL } = require("global");

const getPageTranslationList = async (param) => {
  const pageTranslationList = APIURL + "admin/login";
  return await fetch(pageTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterPageTranslation = async (param) => {
  const getFilterPage = APIURL + "admin/login";
  return await fetch(getFilterPage, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getPageTranslationByID = async (data) => {
  const getPageTranslationByIDApi =
    APIURL + `pageTranslation/${data.PageTranslationID}`;
  return await fetch(getPageTranslationByIDApi);
};

const UpdatePageTranslation = async (param) => {
    const updatePageTranslation = APIURL + "admin/login";
    return await fetch(updatePageTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addPageMasterTranslation = async (param) => {
    const addPageTranslation = APIURL + "admin/login";
    return await fetch(addPageTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getPageTranslationList,
  getFilterPageTranslation,
  getPageTranslationByID,
  UpdatePageTranslation,
  addPageMasterTranslation,
};
