import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addClusterMasterTranslation } from "services/Translation/ClusterTranslation.service";

export default function AddClusterMasterTranslation() {
  const [ClusterName, setClusterName] = useState("");
  //   const [SubjectCode, setSubjectCode] = useState("");
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const addClusterTranslation = (e) => {
    e.preventDefault();
    let Param = {
      clusterName: ClusterName,
      //   subjectCode: SubjectCode,
      createBy: AdminID,
    };
    addClusterMasterTranslation(Param).then((response) => {
      if (response.Status === "Success" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/clusterMasterTranslation");
        }, 1500);
      } else if (response.Status === "Success" && response.Error === true) {
        toast.error(response.Message);
      } else {
        toast.error(response.Result.errors.ClusterName[0]);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Cluster Translation Master
              </h3>
            </div>
            <div>
              <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                <div className=" mt-32 sm:mt-0">
                  <button
                    className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <Link to="/clusterTranslationList">Go to List</Link>
                  </button>
                </div>
              </div>
              <div className="mx-4 my-2">
                <label className="font-semibold flex flex-col w-1/4">
                  From
                  <select className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 ">
                    <option hidden>Language</option>
                    {/* {La &&
                            Country?.map((item, index) => {
                              return (
                                <option value={item.CountryID} key={index}>
                                  {item.CountryName}
                                </option>
                              );
                            })} */}
                  </select>
                </label>
              </div>
              <div className="mx-4 my-2">
                <label className="font-semibold flex flex-col w-1/4">
                  To
                  <select className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 ">
                    <option hidden>Language</option>
                    {/* {La &&
                            Country?.map((item, index) => {
                              return (
                                <option value={item.CountryID} key={index}>
                                  {item.CountryName}
                                </option>
                              );
                            })} */}
                  </select>
                </label>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addClusterTranslation}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Cluster Name
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="ClusterName"
                        onChange={(e) => setClusterName(e.target.value)}
                      />
                    </div>
                    {/* <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Subject Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Subject-Code"
                        onChange={(e) => setSubjectCode(e.target.value)}
                      />
                    </div> */}

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
