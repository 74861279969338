import axios from "axios";
const { APIURL } = require("global");

//To get Knowledge List for Table
const getKnowledgeList = async () => {
  const listApi = APIURL + "knowledge/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Knowledge
const addKnowledge = async (param) => {
  const addKnowledgeAPI = APIURL + "knowledge/create";
  try {
    let Result = await axios.post(addKnowledgeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update Knowledge
const updateKnowledge = async (param) => {
  const updateKnowledgeAPI = APIURL + "knowledge/update";
  try {
    let Result = await axios.post(updateKnowledgeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Knowledge
const deleteKnowledge = async (param) => {
  const deleteKnowledgeAPI = APIURL + "knowledge/delete";
  try {
    let Result = await axios.post(deleteKnowledgeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Knowledge
const activateKnowledge = async (param) => {
  const activateKnowledgeAPI = APIURL + "knowledge/activate";
  try {
    let Result = await axios.post(activateKnowledgeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Knowledge
const deactivateKnowledge = async (param) => {
  const deactivateKnowledgeAPI = APIURL + "knowledge/deactivate";
  try {
    let Result = await axios.post(deactivateKnowledgeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//to edit knowledge
const editKnowledge = async (param) => {
  const editKnowledgeAPI = APIURL + `knowledge/${param}`;
  try {
    let Result = await axios.get(editKnowledgeAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Knowledge
const getPageWiseRecordKnowledge = async (param) => {
  const getPageWiseRecordKnowledgeAPI = APIURL + "knowledge/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordKnowledgeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
export {
  getKnowledgeList,
  addKnowledge,
  updateKnowledge,
  deleteKnowledge,
  activateKnowledge,
  deactivateKnowledge,
  editKnowledge,
  getPageWiseRecordKnowledge,
};
