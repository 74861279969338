import axios from "axios";
const { APIURL } = require("global");

//To get Chapter List for Table
const getChapterList = async () => {
  const listApi = APIURL + "chapter/list";

  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Chapter
const addChapter = async (param) => {
  const addChapterAPI = APIURL + "chapter/create";

  try {
    let Result = await axios.post(addChapterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Aactivate Chapter
const activateChapter = async (param) => {
  const addChapterAPI = APIURL + "chapter/activate";

  try {
    let Result = await axios.post(addChapterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Deactivate Chapter
const deactivateChapter = async (param) => {
  const addChapterAPI = APIURL + "chapter/deactivate";

  try {
    let Result = await axios.post(addChapterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Chapter
const deleteChapter = async (param) => {
  const addChapterAPI = APIURL + "chapter/delete";

  try {
    let Result = await axios.post(addChapterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//to edit chapter
const editChapter = async (param) => {
  const editChapterAPI = APIURL + `chapter/${param}`;

  try {
    let Result = await axios.get(editChapterAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Chapter
const updateChapter = async (param) => {
  const updateChapterAPI = APIURL + "chapter/update";

  try {
    let Result = await axios.post(updateChapterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Chapter
const getPageWiseRecordChapter = async (param) => {
  const getPageWiseRecordChapterAPI = APIURL + "chapter/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordChapterAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getChapterList,
  addChapter,
  activateChapter,
  deactivateChapter,
  deleteChapter,
  editChapter,
  updateChapter,
  getPageWiseRecordChapter,
};
