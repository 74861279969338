import axios from "axios";
const { APIURL } = require("global");

//To get Subject List for Table
const getSubjectList = async () => {
  const listApi = APIURL + "subject/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Subject List for Table
const getSubjectByID = async (param) => {
  const getSubjectByIDApi = APIURL + `subject/${param.subjectID}`;
  try {
    let Result = await axios.get(getSubjectByIDApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Subject List
const addSubject = async (param) => {
  const addSubjectAPI = APIURL + "subject/create";
  try {
    let Result = await axios.post(addSubjectAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Subject List
const updateSubject = async (param) => {
  const updateSubjectAPI = APIURL + "subject/update";

  try {
    let Result = await axios.post(updateSubjectAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Subject
const deleteSubject = async (param) => {
  const deleteSubjectAPI = APIURL + "subject/delete";
  try {
    let Result = await axios.post(deleteSubjectAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Subject
const activateSubject = async (param) => {
  const activateSubjectAPI = APIURL + "subject/activate";
  try {
    let Result = await axios.post(activateSubjectAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Subject
const deactivateSubject = async (param) => {
  const deactivateSubjectAPI = APIURL + "subject/deactivate";
  try {
    let Result = await axios.post(deactivateSubjectAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
export {
  getSubjectList,
  getSubjectByID,
  addSubject,
  updateSubject,
  deleteSubject,
  activateSubject,
  deactivateSubject,
};
