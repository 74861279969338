import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getConceptList } from "services/conceptmaster.service";
import { getLanguageMasterList } from "services/languages.service";
import { addSubConceptMaster } from "services/subconceptmaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddSubConceptMaster = () => {
  const navigate = useNavigate();
  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const [Concept, setConcept] = useState("");
  const [ConceptID, setConceptID] = useState("");
  const [Language, setLanguage] = useState("");
  const [LanguageID, setLanguageID] = useState("");
  const [Title, setTitle] = useState("");
  const [HTMLContent, setHTMLContent] = useState("");
  const [AttachmentType, setAttachmentType] = useState("");
  const [Attachment, setAttachment] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    LanguageList();
    ConceptList();
  }, []);

  const ConceptList = () => {
    getConceptList()
      .then((data) => {
        setConcept(data.Result);
      });
  };

  const LanguageList = () => {
    getLanguageMasterList()
      .then((data) => {
        setLanguage(data.Result);
      });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    setAttachmentType(ext);
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (file) {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setAttachment("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    }
  };
  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setImageUrl(setDp);
  };

  const AddSubConceptMaster = (e) => {
    e.preventDefault();
    let Param = {
      title: Title,
      conceptID: ConceptID,
      languageID: LanguageID,
      htmlContent: HTMLContent,
      attachment: Attachment,
      attachmentType: AttachmentType,
      createBy: AdminID,
    };
    console.log(Param);
    addSubConceptMaster(Param)
      .then((response) => {
        if (response.Status === "SUCCESS" && response.Error === false) {
          toast.success(response.Message);
          setTimeout(() => {
            navigate("/subconcept");
          }, 1500);
        } else {
          toast.error(response.Message);
        }
      });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Sub Concept
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddSubConceptMaster}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose concept
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setConceptID(e.target.value);
                          }}
                        >
                          <option hidden>Concept List</option>
                          {Concept &&
                            Concept.map((item, index) => {
                              return (
                                <option value={item.ConceptID} key={index}>
                                  {item.Title}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setLanguageID(e.target.value);
                          }}
                        >
                          <option hidden>Language List</option>
                          {Language &&
                            Language.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        HTML Content
                      </label>
                      <ReactQuill
                        theme="snow"
                        name="htmlcontent"
                        onChange={(e) => {
                          setHTMLContent(e);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Attachment"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                    <div>
                      {Attachment && (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      )}
                    </div>
                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddSubConceptMaster;
