import React, { useEffect, useState } from "react";
import { PageTranslationView } from "view/admin/Translation/PageTranslationView";
import { getPageTranslationList } from "services/Translation/PageTranslation.service";

export default function PageTranslationTable({ color }) {
  const [pageTranslation, setPageTranslation] = useState([]);

  useEffect(() => {
    getPageTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setPageTranslation(data.Result);
      });
  }, []);

  return <PageTranslationView pageTranslation={pageTranslation}></PageTranslationView>;
}
