import axios from "axios";
const { APIURL } = require("global");

//To get Course Content List for Table
const getCourseSubContentList = async () => {
  const listApi = APIURL + "courseSubContent/list";
  let Result = await axios.get(listApi);
  return Result.data;
};

//To add CourseSubContent
const addCourseSubContent = async (param) => {
  const createCourseSubContentAPI = APIURL + "courseSubContent/create";
  return await fetch(createCourseSubContentAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To activate CourseSubContent
const activateCourseSubContent = async (param) => {
  const activateCourseSubContentAPI = APIURL + "courseSubContent/activate";
  return await fetch(activateCourseSubContentAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To deactivate CourseSubContent
const deactivateCourseSubContent = async (param) => {
  const deactivateCourseSubContentAPI = APIURL + "courseSubContent/deactivate";
  return await fetch(deactivateCourseSubContentAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To delete CourseSubContent
const deleteCourseSubContent = async (param) => {
  const deleteCourseSubContentAPI = APIURL + "courseSubContent/delete";
  return await fetch(deleteCourseSubContentAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To update CourseSubContent
const updateCourseSubContent = async (param) => {
  const updateCourseSubContentAPI = APIURL + "courseSubContent/update";
  return await fetch(updateCourseSubContentAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
const editCourseSubContent = async (param) => {
  const editCourseSubContentAPI = APIURL + `courseSubContent/${param}`;
  return await fetch(editCourseSubContentAPI);
};

//To Get PageWise Record CourseSubContent
const getPageWiseRecordCourseSubContent = async (param) => {
  const getPageWiseRecordCourseSubContentAPI =
    APIURL + "courseSubContent/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordCourseSubContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    return error;
  }
};

//To Get CourseContent PageWise Record By Course
const getPageWiseRecordByCourseContent = async (param) => {
  const getPeriodWiseRecordCourseSubContentAPI =
    APIURL + "courseSubContent/getPageWiseRecordsByCourseContent";

  try {
    let Result = await axios.post(getPeriodWiseRecordCourseSubContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    return error;
  }
};

export {
  getCourseSubContentList,
  addCourseSubContent,
  activateCourseSubContent,
  deactivateCourseSubContent,
  deleteCourseSubContent,
  updateCourseSubContent,
  editCourseSubContent,
  getPageWiseRecordCourseSubContent,
  getPageWiseRecordByCourseContent
};
