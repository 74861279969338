import React, { useState, useEffect } from "react";
import { getBoardList } from "services/boardmaster.service";
import { getGradeMasterList } from "services/grademaster.service";
import { getActivityList } from "services/activity.service";
import { getTlmItemList } from "services/tlmItemmaster.service";
import { editTlm } from "services/tlmmaster.service";
import { useNavigate, useLocation } from "react-router-dom";
import { updateTlm } from "services/tlmmaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSubjectByGradeID } from "services/coursemaster.service";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IMAGEURL } from "global";

export default function EditTlmMaster() {
  const [Board, setBoard] = useState([]);
  const [BoardID, setBoardID] = useState("");
  const [Grade, setGrade] = useState([]);
  const [GradeID, setGradeID] = useState("");
  const [Subject, setSubject] = useState([]);
  const [SubjectID, setSubjectID] = useState("");
  const [Activity, setActivity] = useState([]);
  const [ActivityID, setActivityID] = useState("");
  const [TLMItem, setTLMItem] = useState([]);
  const [TLMItemID, setTLMItemID] = useState("");
  const [OtherItems, setOtherItems] = useState("");
  const [TlmActivity, setTlmActivity] = useState("");
  const [Link, setLink] = useState("");
  const [Attachment, setAttachment] = useState("");
  const [Description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    editTlm(location.state).then((response) => {
      setBoardID(response.Result.BoardID);
      let obj = {
        gradeID: response.Result.GradeID,
      };
      getSubjectByGradeID(obj).then((result) => {
        setSubject(result.Result);
      });
      setGradeID(response.Result.GradeID, obj);
      setSubjectID(response.Result.SubjectID);
      setActivityID(response.Result.ActivityID);
      setTLMItemID(response.Result.TLMItemID);
      setAttachment(response.Result.Attachment);
      setOtherItems(response.Result.OtherItems);
      setTlmActivity(response.Result.TLM_Activity);
      setLink(response.Result.Link);
      setDescription(response.Result.Description);
    });

    BoardList();
    GradeList();
    ActivityList();
    TlmItem();
  }, []);

  const BoardList = () => {
    getBoardList().then((data) => {
      setBoard(data.Result);
    });
  };
  const GradeList = () => {
    getGradeMasterList().then((data) => {
      setGrade(data.Result);
    });
  };
  const SubjectList = (e) => {
    setGradeID(e.target.value);
    let obj = {
      gradeID: e.target.value,
    };
    getSubjectByGradeID(obj).then((result) => {
      setSubject(result.Result);
    });
  };

  const ActivityList = () => {
    getActivityList().then((data) => {
      setActivity(data.Result);
    });
  };
  const TlmItem = () => {
    getTlmItemList().then((data) => {
      setTLMItem(data.Result);
    });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (file) {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setAttachment("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setImageUrl(setDp);
  };

  const UpdateTlm = (e) => {
    e.preventDefault();
    let Param = {
      ID: location.state,
      Description: Description,
      Attachment: Attachment,
      TLMActivity: TlmActivity,
      Link: Link,
      OtherItems: OtherItems,
      TLMItemID: TLMItemID,
      ActivityID: ActivityID,
      BoardID: BoardID,
      GradeID: GradeID,
      SubjectID: SubjectID,
      CreateBy: AdminID,
    };

    updateTlm(Param).then((response) => {
      if (response?.Status === "SUCCESS" && response?.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/Tlm");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit TLM</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateTlm}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Board
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          value={BoardID}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden>Select Board</option>
                          {Board &&
                            Board.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Grade
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          value={GradeID}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={SubjectList}
                        >
                          <option hidden>Select Grade</option>
                          {Grade &&
                            Grade.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Subject
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          value={SubjectID}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => {
                            setSubjectID(e.target.value);
                          }}
                        >
                          <option hidden>Select Subject</option>
                          {Subject &&
                            Subject.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Activity
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          value={ActivityID}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => {
                            setActivityID(e.target.value);
                          }}
                        >
                          <option hidden>Select Activity</option>
                          {Activity &&
                            Activity.map((item, index) => {
                              return (
                                <option value={item.ActivityID} key={index}>
                                  {item.Concept}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Tlm Item
                      </label>
                      <div className="relative w-full mb-3">
                        <select
                          value={TLMItemID}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          onChange={(e) => {
                            setTLMItemID(e.target.value);
                          }}
                        >
                          <option hidden>Select Tlm Item</option>
                          {TLMItem &&
                            TLMItem.map((item, index) => {
                              return (
                                <option value={item.ItemID} key={index}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-user-name"
                      >
                        Other Items
                      </label>
                      <input
                        value={OtherItems}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Other Items"
                        onChange={(e) => {
                          setOtherItems(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Tlm-Activity
                      </label>
                      <input
                        value={TlmActivity}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder=" Tlm-Activity"
                        onChange={(e) => {
                          setTlmActivity(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Link
                      </label>
                      <input
                        value={Link}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder=" Link"
                        onChange={(e) => {
                          setLink(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Attachment"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                    {/* <div>
                      {Attachment && (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      )}
                    </div> */}
                    <div>
                      {imageUrl !== "" ? (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      ) : (
                        <img src={`${IMAGEURL}${Attachment}`} width="100" />
                      )}
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 pt-4"
                        htmlFor="grid-page-name"
                      >
                        Description
                      </label>
                      <ReactQuill
                        value={Description}
                        theme="snow"
                        name="description"
                        onChange={(e) => {
                          setDescription(e);
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
