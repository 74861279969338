import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  getPageWiseRecordLanguage,
  activateLanguage,
  deactivateLanguage,
  deleteLanguage,
} from "services/languages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addPriority } from "services/languages.service";
import Pagination from "components/admin/Admin-Pagination/pagination";

const LanguageMasterView = () => {
  const [Language, setLanguage] = useState("");
  const [priorityData, setPriorityData] = useState([]);
  const [counts, setCounts] = useState("");
  const [startLimit, setStartLimit] = useState(0);
  const [endLimit, setEndLimit] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    LanguageList();
  }, [startLimit]);

  const LanguageList = () => {
    let obj = {
      offset: startLimit,
      records: endLimit,
    };
    getPageWiseRecordLanguage(obj).then((data) => {
      setLanguage(data.Result);
      setCounts(data.Count);
      data.Result.map((item) => {
        setPriorityData(item);
      });
    });
  };
  const ActivateLanguage = (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    activateLanguage(Param).then((response) => {
      toast.success(response.Message);
      LanguageList();
    });
  };
  const DeactivateLanguage = (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deactivateLanguage(Param).then((response) => {
      toast.error(response.Message);
      LanguageList();
    });
  };
  const DeleteLanguage = (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deleteLanguage(Param).then((response) => {
      toast.error(response.Message);
      LanguageList();
    });
  };

  const handleChange = (e, languageID) => {
    if (e.target.value !== "") {
      let obj = {
        [e.target.name]: e.target.value,
        languageID,
      };
      addPriority(obj).then((data) => {
        console.log("Priority Data -->",data);
        if (data?.Status === "Success" && data?.Error === false) {
          toast.success(data.Message);
        } else if (data?.Status === "Success" && data?.Error === true) {
          toast.error(data.Message);
        }
      });
    } else {
      toast.error("Priority does not blank");
    }
  };

  const RedirectToEditLanguage = (e) => {
    e.preventDefault();
    navigate("/editlanguage", {
      state: e.currentTarget.id,
    });
  };
  return (
    <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
      <div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
        <div className="w-full max-w-full flex-grow flex-1">
          <h3 className="font-semibold text-lg text-black">Filter</h3>
        </div>
        <div>
          <input
            type="text"
            className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
            placeholder="Keyword"
          />

          <select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 ">
            <option hidden>Status</option>
            <option>Activate</option>
            <option>Deactivate</option>
            <option>Delete</option>
          </select>

          <button
            className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
            // ml-48
            type="button"
          >
            Filter
          </button>
        </div>
      </div>
      <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
        <div className="flex flex-wrap items-center">
          <div className="w-full max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-lg text-black">
              List of Languages
            </h3>
          </div>
          <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
            <div className=" mt-32 sm:mt-0">
              <button
                className="bg-slate-950	active:bg-lightBlPue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                <Link to="/addlanguage">Add</Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="block w-full overflow-x-auto">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr className="bg-blue-950">
              <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                Language Name
              </th>
              <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                Language Code
              </th>
              <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                Priority
              </th>
              <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                Status
              </th>
              <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {Language &&
              Language.map((language, index) => {
                return (
                  <tr key={language.LanguageID}>
                    <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                      {language.LanguageName}
                    </td>

                    <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                      {language.LanguageCode}
                    </td>

                    <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Priority"
                        name="priority"
                        defaultValue={language.Priority}
                        value={priorityData[index]}
                        required
                        onBlur={(e) => handleChange(e, language.LanguageID)}
                      />
                    </td>

                    <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                      {language.CStatus}
                    </td>
                    <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
                      {language.CStatus === "Active" && (
                        <i
                          id={language.LanguageID}
                          className="fas fa-edit"
                          onClick={RedirectToEditLanguage}
                        ></i>
                      )}
                      {(language.CStatus === "Active" ) && (
                        <i
                          id={language.LanguageID}
                          className="fas fa-trash text-red-600"
                          onClick={(e) => DeleteLanguage(e)}
                        ></i>
                      )}
                      {(language.CStatus === "Deactive" ||
                        language.CStatus === "Delete") && (
                        <i
                          id={language.LanguageID}
                          className="fas fa-check-circle"
                          onClick={(e) => ActivateLanguage(e)}
                        ></i>
                      )}
                      {(language.CStatus === "Active" ) && (
                        <i
                          id={language.LanguageID}
                          className="fas fa-ban text-red-600"
                          onClick={(e) => DeactivateLanguage(e)}
                        ></i>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ToastContainer />
      <Pagination
        data={{
          counts,
          startLimit,
          setStartLimit,
          endLimit,
          setEndLimit,
        }}
      />
    </div>
  );
};

export default LanguageMasterView;
