import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { editLanguage, updateLanguage } from "services/languages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddLanguageMaster() {
  const [LanguageName, setLanguageName] = useState("");
  const [LanguageCode, setLanguageCode] = useState("");
  const [Priority,setPriority] = useState("");

  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    editLanguage(location.state)
      .then((data) => {
        console.log(data);
        setPriority(data.Result.Priority);
        setLanguageName(data.Result.LanguageName);
        setLanguageCode(data.Result.LanguageCode);
      });
  }, []);
  const UpdateLanguage = (e) => {
    e.preventDefault();
    let Param = {
      languageID: location.state,
      languageName: LanguageName,
      languageCode: LanguageCode,
      priority : Priority,
      createBy: AdminID,
    };

    updateLanguage(Param).then((response) => {
      console.log("updatelanguage",response)

       if(response.errors){
        toast.error(response.response.errors.languageCode[0]);
       }
      else if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/language");
        }, 1500);
      } else if (LanguageCode.length > 4) {
        toast.error("The Language code shouldn't be greater then 4");
      } else if(response.Message){
        toast.error(response.Message);
      } 
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Language</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateLanguage}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Language Name
                      </label>
                      <input
                        type="text"
                        value={LanguageName}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Language-Name"
                        required
                        onChange={(e) => setLanguageName(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Language Code
                      </label>
                      <input
                        type="text"
                        value={LanguageCode}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Language-Code"
                        required
                        onChange={(e) => setLanguageCode(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
