import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	getCountryList,
	activateCountry,
	deactivateCountry,
	deleteCountry,
	getPageWiseRecordCountry,
} from "services/countrymaster.service";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "components/admin/Admin-Pagination/pagination";

const CountryMasterView = () => {
	const [Country, setCountry] = useState("");
	const [startLimit, setStartLimit] = useState(0);
	const [endLimit, setEndLimit] = useState(10);
	const [counts, setCounts] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		// CountryList();
		let obj = {
			offset: startLimit,
			records: endLimit,
		};
		getPageWiseRecordCountry(obj).then((data) => {
			setCountry(data.Result);
			setCounts(data.Count);
		});
	}, [startLimit]);

	const CountryList = () => {
		getCountryList()
			.then((data) => {
				setCountry(data.Result);
			});
	};
	const ActivateCountry = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		activateCountry(Param).then((response) => {
			toast.success(response.Message);
			CountryList();
		});
	};
	const DeactivateCountry = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		deactivateCountry(Param).then((response) => {
			toast.error(response.Message);
			CountryList();
		});
	};
	const DeleteCountry = (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		deleteCountry(Param).then((response) => {
			toast.error(response.Message);
			CountryList();
		});
	};
	const RedirectToEditPage = async (e) => {
		e.preventDefault();
		navigate("/editcountry", {
			state: e.currentTarget.id,
		});
	};

	return (
		<>
			<div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
				<div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
					<div className="w-full max-w-full flex-grow flex-1">
						<h3 className="font-semibold text-lg text-black">Filter</h3>
					</div>
					<div>
						<input
							type="text"
							className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
							placeholder="Keyword"
						/>

						<select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 ">
							<option hidden>Status</option>
							<option>Activate</option>
							<option>Deactivate</option>
							<option>Delete</option>
						</select>

						<button
							className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
							// ml-48
							type="button"
						>
							Filter
						</button>
					</div>
				</div>
				<div className="mb-0 px-4 py-3 border-0 bg-slate-300">
					<div className="flex flex-wrap items-center">
						<div className="w-full max-w-full flex-grow flex-1">
							<h3 className="font-semibold text-lg text-black">
								List of Countries
							</h3>
						</div>
						<div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
							<div className=" mt-32 sm:mt-0">
								<button
									className="bg-slate-950	active:bg-lightBlPue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
									type="button"
								>
									<Link to="/addcountry">Add</Link>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="block w-full overflow-x-auto">
					<table className="items-center w-full bg-transparent border-collapse">
						<thead>
							<tr className="bg-blue-950">
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Country Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Country Code
								</th>

								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Status
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Action
								</th>
							</tr>
						</thead>

						<tbody>
							{Country &&
								Country.map((country) => {
									return (
										<tr key={country.stateID}>
											<td className="border-[1px] border-blue-950 px-6 align-middle text-black text-md whitespace-normal p-4">
												{country.CountryName}
											</td>
											<td className="border-[1px] border-blue-950 px-6 align-middle text-black text-md whitespace-normal p-4">
												{country.CountryCode}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{country.CStatus}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
												{country.CStatus === "Active" && (
													<i
														id={country.CountryID}
														className="fas fa-edit"
														onClick={RedirectToEditPage}
													></i>
												)}
												{(country.CStatus === "Active" ) && (
													<i
														id={country.CountryID}
														className="fas fa-trash text-red-600"
														onClick={(e) => {
															DeleteCountry(e);
														}}
													></i>
												)}
												{(country.CStatus === "Deactive" ||
													country.CStatus === "Delete") && (
													<i
														id={country.CountryID}
														className="fas fa-check-circle"
														onClick={(e) => ActivateCountry(e)}
													></i>
												)}
												{(country.CStatus === "Active" ) && (
													<i
														id={country.CountryID}
														className="fas fa-ban text-red-600"
														onClick={(e) => DeactivateCountry(e)}
													></i>
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
					<ToastContainer />
					<Pagination
						data={{
							counts,
							startLimit,
							setStartLimit,
							endLimit,
							setEndLimit,
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default CountryMasterView;
