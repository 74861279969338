import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { editCountry, updateCountry } from "services/countrymaster.service";
import { useLocation } from "react-router";
import { getLanguageMasterList } from "services/languages.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { IMAGEURL } from "global";

export default function EditCountryMaster() {
  const [CountryName, setCountryName] = useState("");
  const [CountryCode, setCountryCode] = useState("");
  const [CountryDescription, setCountryDescription] = useState("");
  const [MobileMaxLength, setMobileMaxLength] = useState("");
  const [MobileMinLength, setMobileMinLength] = useState("");
  const [MobilePreFix, setMobilePreFix] = useState("");
  const [CountryFlag, setCountryFlag] = useState("");
  const [NativeLanguageName, setNativeLanguageName] = useState("");
  const [OfficialLanguage, setOfficialLanguage] = useState("");
  const [OfficialLanguageID, setOfficialLanguageID] = useState("");
  const [DialCode, setDialCode] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    editCountry(location.state).then((data) => {
      setCountryName(data.Result.CountryName);
      setCountryCode(data.Result.CountryCode);
      setCountryDescription(data.Result.CountryDescription);
      setMobileMaxLength(data.Result.MobileMaxLength);
      setMobileMinLength(data.Result.MobileMinLength);
      setMobilePreFix(data.Result.MobilePrefix);
      setCountryFlag(data.Result.CountryFlag);
      setNativeLanguageName(data.Result.NativeLanguageName);
      setOfficialLanguageID(data.Result.OfficialLanguageId);
      setDialCode(data.Result.DialCode);
    });
    OfficialLanguageList();
  }, []);

  const OfficialLanguageList = () => {
    getLanguageMasterList().then((data) => {
      setOfficialLanguage(data.Result);
    });
  };
  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (file) {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setCountryFlag("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setCountryFlag(btoa(binaryString));
    setImageUrl(setDp);
  };

  const UpdateCountry = (e) => {
    e.preventDefault();
    let Param = {
      countryID: location.state,
      countryName: CountryName,
      countryCode: CountryCode,
      countryDescription: CountryDescription,
      mobileMaxLength: MobileMaxLength,
      mobileMinLength: MobileMinLength,
      mobilePrefix: MobilePreFix,
      countryFlag: imageUrl === "" ? imageUrl : CountryFlag,
      nativeLanguageName: NativeLanguageName,
      officialLanguageID: OfficialLanguageID,
      dialCode: DialCode,
      createBy: AdminID,
    };
    updateCountry(Param).then((response) => {
      if (response.Status === "SUCCESS") {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/country");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Country</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  <form onSubmit={UpdateCountry}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Country Code
                      </label>
                      <input
                        value={CountryCode}
                        autoFocus
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Country-Code"
                        onChange={(e) => setCountryCode(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3 py-6">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Country Name
                      </label>
                      <input
                        value={CountryName}
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Country-Name"
                        onChange={(e) => setCountryName(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Country-Description
                      </label>
                      <textarea
                        value={CountryDescription}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Country-Description"
                        onChange={(e) => setCountryDescription(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Native Language Name
                      </label>
                      <input
                        value={NativeLanguageName}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder=" Native Language Name"
                        onChange={(e) => setNativeLanguageName(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Mobile Prefix
                      </label>
                      <input
                        value={MobilePreFix}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder=" Mobile Prefix"
                        onChange={(e) => setMobilePreFix(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Dial Code
                      </label>
                      <input
                        value={DialCode}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Dial Code"
                        onChange={(e) => setDialCode(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Mobile min. length
                      </label>
                      <input
                        value={MobileMinLength}
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder=" Mobile min. length"
                        onChange={(e) => setMobileMinLength(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Mobile max. length
                      </label>
                      <input
                        value={MobileMaxLength}
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Mobile max. length"
                        onChange={(e) => setMobileMaxLength(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Country Flag
                      </label>
                      <input
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder=" Country Flag"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                    <div>
                      {CountryFlag ? (
                        <>
                          <img
                            src={`${IMAGEURL}${CountryFlag}`}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      )}
                    </div>

                    <label className="font-semibold flex flex-col w-1/4  ">
                      Choose Official Language
                    </label>
                    <br />

                    <select
                      value={OfficialLanguageID}
                      className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg  w-1/4 "
                      onChange={(e) => {
                        setOfficialLanguageID(e.target.value);
                      }}
                    >
                      <option hidden>Official Language List</option>
                      {OfficialLanguage &&
                        OfficialLanguage?.map((item, index) => {
                          return (
                            <option value={item.LanguageID} key={index}>
                              {item.LanguageName}
                            </option>
                          );
                        })}
                    </select>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
