import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getPageByID, updatePage } from "services/pagemaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditPageMaster() {
  const location = useLocation();
  const [PageName, setPageName] = useState("");
  const [PageType, setPageType] = useState("");
  const [Description, setDescription] = useState("");
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  useEffect(() => {
    GetPageByID();
  }, []);
  const GetPageByID = () => {
    let Param = {
      PageID: location.state,
    };
    getPageByID(Param).then((data) => {
      setPageName(data.Result[0].PageName);
      setPageType(data.Result[0].PageType);
      setDescription(data.Result[0].Description);
    });
  };
  const UpdatePage = (e) => {
    e.preventDefault();
    let Param = {
      pageID: location.state,
      pageName: PageName,
      pageType: PageType,
      description: Description,
      createBy: AdminID,
    };
    updatePage(Param).then((response) => {
      if (response?.Status === "SUCCESS" && response?.Error === false) {
        toast.success(response?.Message);
        setTimeout(() => {
          navigate("/page");
        }, 1500);
      } else if (PageType === "") {
        toast.error("Page Type field is required");
      } else {
        toast.error(response?.Message);
        toast.error("Enter valid data");
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Page</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdatePage}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Page Type
                      </label>
                      <select
                        required
                        value={PageType}
                        className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        name="Page Type"
                        onChange={(e) => {
                          setPageType(e.target.value);
                        }}
                      >
                        <option hidden>Page Type List</option>
                        <option value="Android">Android</option>
                        <option value="Website">Website</option>
                      </select>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Page Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Page-Name"
                        value={PageName}
                        onChange={(e) => setPageName(e.target.value)}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Description"
                        value={Description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6 ">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Update
                      </button>
                      <Link
                        className="bg-transparent hover:bg-blue-500 text-blue-700
                      font-semibold hover:text-white py-2 px-4 rounded"
                        to="/page"
                      >
                        Back
                      </Link>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
