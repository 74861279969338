import axios from "axios";
const { APIURL } = require("global");

// eslint-disable-next-line no-undef
//To get Page List for Table
const getPageImageList = async () => {
  const listApi = APIURL + "pageimage/list";
  return await fetch(listApi);
};

//To get Page List for Table
const getPageImageByID = async (param) => {
  const getPageByIDApi = APIURL + `pageimage/${param}`;
  return await fetch(getPageByIDApi);
};

const getPageImageByPageID = async (param) => {
  const getPageByPageIDApi = APIURL + `pageimage/getPageImageByPageID/${param}`;
  return await fetch(getPageByPageIDApi);
};

//To Add Page List
const addPageImage = async (param) => {
  try {
    const URL = APIURL + "pageimage/create";
    return await axios.post(`${URL}`, param);
  } catch (error) {
    return error.response;
  }
};

//To Update Page List
const updatePageImage = async (param) => {
  try {
    const URL = APIURL + "pageimage/update";
    return await axios.post(`${URL}`, param);
  } catch (error) {
    return error.response;
  }
};

//To Delete Page
const deletePageImage = async (param) => {
  try {
    const URL = APIURL + "pageimage/delete";
    return await axios.post(`${URL}`, param);
  } catch (error) {
    return error.response;
  }
};
//To activate Page
const activatePageImage = async (param) => {
  try {
    const URL = APIURL + "pageimage/activate";
    return await axios.post(`${URL}`, param);
  } catch (error) {
    return error.response;
  }
};

//To deactivate Page
const deactivatePageImage = async (param) => {
  try {
    const URL = APIURL + "pageimage/deactivate";
    return await axios.post(`${URL}`, param);
  } catch (error) {
    return error.response;
  }
};
export {
  getPageImageList,
  getPageImageByID,
  addPageImage,
  updatePageImage,
  deletePageImage,
  activatePageImage,
  deactivatePageImage,
  getPageImageByPageID,
};
