const { APIURL } = require("global");

const getBoardTranslationList = async () => {
  const boardTranslationListApi = APIURL + "boardTranslation/list";
  return await fetch(boardTranslationListApi);
};

const getFilterBoardTranslation = async (param) => {
  const getFilterBoardByLanguageIDApi = APIURL + `boardTranslation/getFilterBoardByLanguageID/${param}`;
  return await fetch(getFilterBoardByLanguageIDApi);
};

const getBoardTranslationByID = async (param) => {
  const getBoardTranslationByIDApi =
    APIURL + `boardTranslation/${param.boardTranslationID}`;
  return await fetch(getBoardTranslationByIDApi);
};

const UpdateBoardTranslation = async (param) => {
    const updateBoardTranslation = APIURL + "boardTranslation/update";
    return await fetch(updateBoardTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addBoardMasterTranslation = async (param) => {
    const addBoardTranslation = APIURL + "boardTranslation/create";
    return await fetch(addBoardTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getBoardTranslationDataByLanguageID = async (param) => {
    const getBoardTranslationDataByLanguageID = APIURL + "boardTranslation/getBoardTranslationData";
    return await fetch(getBoardTranslationDataByLanguageID, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getBoardTranslationList,
  getFilterBoardTranslation,
  getBoardTranslationByID,
  UpdateBoardTranslation,
  addBoardMasterTranslation,
  getBoardTranslationDataByLanguageID,
};
