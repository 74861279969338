import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCourseByID,
  getGradeList,
  updateCourse,
} from "services/coursemaster.service";
import {
  getBoardList,
  getSubjectByGradeID,
} from "services/coursemaster.service";

export default function EditCourseMaster() {
  const navigate = useNavigate();
  const location = useLocation();
  const [CourseName, setCourseName] = useState("");
  const [Description, setDescription] = useState("");
  const [Board, setBoard] = useState([]);
  const [BoardID, setBoardID] = useState("");
  const [Grade, setGrade] = useState([]);
  const [GradeID, setGradeID] = useState("");
  const [Subject, setSubject] = useState([]);
  const [SubjectID, setSubjectID] = useState("");
  let CourseID = location.state;
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  useEffect(() => {
    BoardList();
    GradeList();
    GetCourseByID();
  }, []);
  const GetCourseByID = () => {
    let Param = {
      id: CourseID,
    };
    getCourseByID(Param).then((data) => {
      setBoardID(data.Result[0].BoardID);
      setGradeID(data.Result[0].GradeID);
      setSubjectID(data.Result[0].SubjectID);
      setCourseName(data.Result[0].CourseName);
      setDescription(data.Result[0].Description);
    });
  };
  const BoardList = () => {
    getBoardList().then((data) => {
      setBoard(data.Result);
    });
  };
  const GradeList = () => {
    getGradeList().then((data) => {
      setGrade(data.Result);
    });
  };
  const GetSubjectByGradeID = (GradeID) => {
    let param = {
      gradeID: GradeID,
    };
    setGradeID(GradeID);
    getSubjectByGradeID(param).then((data) => {
      setSubject(data.Result);
    });
  };
  const UpdateCourse = (e) => {
    e.preventDefault();
    let Param = {
      courseID: CourseID,
      name: CourseName,
      description: Description,
      boardID: BoardID,
      gradeID: GradeID,
      subjectID: SubjectID,
      createBy: AdminID,
    };
    console.log("object", Param);
    updateCourse(Param).then((response) => {
      if (response.Error === false && !Boolean(response.errors)) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/course");
        }, 1500);
      } else {
        toast.error(
          Boolean(response.errors)
            ? Object.values(response.errors)[0][0]
            : response.Message
        );
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit Course 
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  <form onSubmit={UpdateCourse}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Board-name"
                      >
                        Board
                      </label>
                      <div class="relative w-full mb-3">
                        <select
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-BoardID"
                          value={BoardID}
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden value="">Select Board</option>
                          {Board.map((item, index) => {
                            return (
                              <option
                                value={item.BoardID}
                                key={index}
                                selected={BoardID === item.BoardID}
                              >
                                {item.BoardName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Grade-name"
                      >
                        Grade
                      </label>
                      <div class="relative w-full mb-3">
                        <select
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="grid-GradeID"
                          value={GradeID}
                          onChange={(e) => {
                            GetSubjectByGradeID(e.target.value);
                          }}
                        >
                          <option hidden value="">Select Grade</option>
                          {Grade.map((item, index) => {
                            return (
                              <option
                                value={item.GradeID}
                                key={index}
                                selected={GradeID === item.GradeID}
                              >
                                {item.GradeName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-Subject-name"
                      >
                        Subject
                      </label>
                      <select
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        id="grid-SubjectID"
                        value={SubjectID}
                        onChange={(e) => {
                          setSubjectID(e.target.value);
                        }}
                      >
                        <option hidden value="">Select Subject</option>
                        {Subject.map((item, index) => {
                          console.log(Subject);
                          return (
                            <option
                              value={item.divisioSubjectID}
                              key={index}
                              selected={SubjectID === item.SubjectID}
                            >
                              {item.SubjectName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-course-name"
                      >
                        Course Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Course-Name"
                        value={CourseName}
                        onChange={(e) => setCourseName(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Description"
                        value={Description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      {/* <button
                        className="bg-green-600 active:bg-green-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Submit
                      </button>{" "} */}{" "}
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Update
                      </button>
                      <Link
                        class="bg-transparent hover:bg-blue-500 text-blue-700
                      font-semibold hover:text-white py-2 px-4 rounded"
                        to="/course"
                      >
                        Back
                      </Link>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
