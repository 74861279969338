import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  editProjectActivity,
  updateProjectActivity,
} from "services/projectActivity.service";
import { getChapterList } from "services/chaptermaster.service";
import { getLanguageMasterList } from "services/languages.service";
import { IMAGEURL } from "global";

export default function EditProjectActivity() {
  const location = useLocation();
  const [chapterID, setChapterID] = useState("");
  const [languageID, setLanguageID] = useState("");
  const [title, setTitle] = useState("");
  const [htmlContent, setHTMLContent] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [projectActivityUrlImage, setProjectActivityUrlImage] = useState("");
  const [chapterList, setChapterList] = useState("");
  const [languageList, setLanguageList] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getChapterList()
      .then((response) => response.json())
      .then((data) => {
        if (!data?.Error && data.Status === "Success") {
          setChapterList(data.Result);
        } else {
          setChapterList([]);
        }
      });
    getLanguageMasterList()
      .then((response) => response.json())
      .then((data) => {
        if (!data?.Error && data.Status === "Success") {
          setLanguageList(data.Result);
        } else {
          setLanguageList([]);
        }
      });
    editProjectActivity(location.state)
      .then((response) => response.json())
      .then((data) => {
        setChapterID(data.Result.ChapterID);
        setLanguageID(data.Result.LanguageID);
        setHTMLContent(data.Result.HTMLContent);
        setTitle(data.Result.Title);
        setAttachment(data.Result.Attachment);
        setAttachmentType(data.Result.AttachmentType);
      });
  }, []);

  const UpdateProjectActivitys = (e) => {
    e.preventDefault();

    let projectActivityData = {
      projectActivityID: location.state,
      languageID,
      chapterID,
      title,
      htmlContent,
      attachment,
      attachmentType,
      createBy: JSON.parse(localStorage.getItem("user")).AdminID,
    };
    
    updateProjectActivity(projectActivityData).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/projectactivity");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext) {
      if (file) {
        setAttachmentType(ext);
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    } else {
      return false;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setProjectActivityUrlImage(setDp);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit Project Activity
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateProjectActivitys}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Chapter
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={chapterID}
                          name="chapterID"
                          onChange={(e) => {
                            setChapterID(e.target.value);
                          }}
                        >
                          <option hidden>Chapter List</option>
                          {chapterList &&
                            chapterList?.map((item, index) => {
                              return (
                                <option value={item.ChapterID} key={index}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="languageID"
                          value={languageID}
                          onChange={(e) => {
                            setLanguageID(e.target.value);
                          }}
                        >
                          <option hidden>Language List</option>
                          {languageList &&
                            languageList?.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="title"
                        value={title}
                        placeholder="ProjectActivity-Name"
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Html Content
                      </label>
                      <ReactQuill
                        theme="snow"
                        name="htmlContent"
                        required={Boolean(attachment) ? false : true}
                        value={htmlContent}
                        onChange={(e) => {
                          setHTMLContent(e);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        required={Boolean(htmlContent) ? false : true}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="attachment"
                        placeholder="ProjectActivity-Name"
                        onChange={handleImage}
                      />
                    </div>
                    {projectActivityUrlImage ? (
                      <img src={projectActivityUrlImage} width="100" alt="" />
                    ) : (
                      <img
                        src={`${IMAGEURL}${attachment}`}
                        alt=""
                        width="100"
                      />
                    )}

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
