import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getPageImageByPageID,
  deletePageImage,
  activatePageImage,
  deactivatePageImage,
} from "services/pageImage.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMAGEURL } from "global";

export const PageImageView = ({ pageImages }) => {
  const location = useLocation();
  const [PageImages, setPageImages] = useState(pageImages);
  const navigate = useNavigate();
  useEffect(() => {
    setPageImages(pageImages);
  }, [pageImages]);
  const PageList = () => {
    getPageImageByPageID(location.state)
      .then((response) => response.json())
      .then((data) => {
        setPageImages(data.Result);
      });
  };

  const RedirectToEditPage = async (e) => {
    e.preventDefault();
    navigate("/editPageImage", {
      state: e.currentTarget.id,
    });
  };
  const DeletePage = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deletePageImage(Param).then((response) => {
      toast.error(response.data.Message);
      PageList();
    });
  };

  const ActivatePage = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    activatePageImage(Param).then((response) => {
      toast.success(response.data.Message);
      PageList();
    });
  };

  const DeactivatePage = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deactivatePageImage(Param).then((response) => {
      toast.error(response.data.Message);
      PageList();
    });
  };

  const directToPageImages = () => {
    navigate("/addPageImage", {
      state: location.state,
    });
  };

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">
                List of Page Images
              </h3>
            </div>
            <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
              <div className=" mt-32 sm:mt-0">
                <button
                  className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={directToPageImages}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Page Image Alt Title
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Page Image Title
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Page Image Source
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {PageImages &&
                PageImages.map((page) => {
                  return (
                    <tr key={page.PageID}>
                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                        {page.PageImgAltTitle}
                      </td>
                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                        {page.PageImgTitle}
                      </td>
                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                        <img
                          src={`${IMAGEURL}${page.PageImageSource}`}
                          width="100"
                        />
                      </td>
                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                        {page.CStatus}
                      </td>
                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
                        {page.CStatus === "Active" && (
                          <i
                            id={page.PageImageID}
                            class="fas fa-edit"
                            onClick={RedirectToEditPage}
                          ></i>
                        )}
                        {(page.CStatus === "Active" ||
                          page.CStatus === "Deactive") && (
                          <i
                            id={page.PageImageID}
                            class="fas fa-trash text-red-600"
                            onClick={(e) => DeletePage(e)}
                          ></i>
                        )}
                        {(page.CStatus === "Deactive" ||
                          page.CStatus === "Delete") && (
                          <i
                            id={page.PageImageID}
                            class="fas fa-check-circle"
                            onClick={(e) => ActivatePage(e)}
                          ></i>
                        )}
                        {(page.CStatus === "Active" ||
                          page.CStatus === "Delete") && (
                          <i
                            id={page.PageImageID}
                            class="fas fa-ban text-red-600"
                            onClick={(e) => DeactivatePage(e)}
                          ></i>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>{" "}
          <ToastContainer />
        </div>
      </div>
    </>
  );
};
