import React from "react";
import TlmMasterView from "view/admin/TlmMasterView";
const ListTlmMaster = () => {
  return (
    <>
      <TlmMasterView></TlmMasterView>
    </>
  );
};

export default ListTlmMaster;
