import React, { useState, useEffect } from "react";
import { getGradeMasterList } from "services/grademaster.service";
import {
  SubjectGradeMapping,
  SubjectGradeUnMapping,
  GetSubjectListByGradeID,
} from "services/subjectgrademapping.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SubjectGradeMappingView = () => {
  const [GradeList, setGradeList] = useState([]);
  const [GradeID, setGradeID] = useState("");
  const [SubjectList, setSubjectList] = useState([]);
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    gradeList();
  }, []);
  const gradeList = () => {
    getGradeMasterList().then((data) => {
      setGradeList(data.Result);
    });
  };
  const GetSubjectList = (e) => {
    let param = {
      gradeID: e,
    };
    setGradeID(e);
    GetSubjectListByGradeID(param).then((response) => {
      setSubjectList(response.Result);
    });
  };
  const Mapping = (subjectID, status) => {
    let param = {
      gradeID: GradeID,
      subjectID: subjectID,
      createBy: AdminID,
    };
    console.log("param List Param", param, status);
    if (status === false) {
      SubjectGradeUnMapping(param).then((response) => {
        console.log(response);
        toast.success(response.message);
        GetSubjectList(GradeID);
      });
    } else {
      SubjectGradeMapping(param).then((response) => {
        console.log(response);
        toast.success(response.message);
        GetSubjectList(GradeID);
      });
    }
  };
  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6   text-white">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">
                Subject Grade Mapping
              </h3>
            </div>
          </div>
        </div>
        <div className="text-black font-semibold px-4">Choose Grade</div>
        <div className="relative inline-block text-left w-34 px-4  ">
          <div>
            <div className="flex ">
              <select
                className="text-white font-semibold border-2 bg-blue-950 rounded-lg my-2 w-1/4 "
                // value={GradeID}
                onChange={(e) => {
                  GetSubjectList(e.target.value);
                }}
              >
                <option value="0" hidden>
                  Grade List
                </option>
                {GradeList &&
                  GradeList.map((item, index) => {
                    return (
                      <option value={item.GradeID} key={index}>
                        {item.GradeName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="block w-full overflow-x-auto">
          <table className="items- mt-1 center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                <th className="px-6 align-middle  border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left"></th>
                <th className="px-7 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-start">
                  Subject Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left"></th>
              </tr>
            </thead>
            <tbody>
              {SubjectList &&
                SubjectList?.map((subjectlist) => {
                  return (
                    <tr key={subjectlist.SubjectID}>
                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                        <div className="flex items-center mb-4">
                          <input
                            type="checkbox"
                            id={subjectlist.SubjectID}
                            value={subjectlist.CStatus}
                            checked={
                              subjectlist.CStatus === "true" ? true : false
                            }
                            onClick={(e) => {
                              Mapping(e.target.id, e.target.checked);
                            }}
                            //onClick={GetMappedList(id)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                        </div>
                      </td>

                      <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                        {subjectlist.SubjectName}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};
