import axios from "axios";
const { APIURL } = require("global");

//To get School List for Table
const getSchoolList = async () => {
  const listApi = APIURL + "school/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get School Type List for Table
const getSchoolTypeList = async () => {
  const listApi = APIURL + "schoolType/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get School list by state ID
const getSchoolByStateID = async (param) => {
  const getSchoolByStateIDAPI = APIURL + "school/filterByState";
  try {
    let Result = await axios.post(getSchoolByStateIDAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To delete School
const deleteSchool = async (param) => {
  const deleteVillageAPI = APIURL + "school/delete";
  try {
    let Result = await axios.post(deleteVillageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate School
const activateSchool = async (param) => {
  const activateSchoolAPI = APIURL + "school/activate";
  try {
    let Result = await axios.post(activateSchoolAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactive School
const deactivateSchool = async (param) => {
  const deactivateSchoolAPI = APIURL + "school/deactivate";
  try {
    let Result = await axios.post(deactivateSchoolAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get Division  By StateID
const getDivisionListByStateID = async (param) => {
  const getDivisionByStateID = APIURL + "division/getDivisionListByStateID";
  try {
    let Result = await axios.post(getDivisionByStateID, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get District  By DivisionID
const getDistrictListByDivisionID = async (param) => {
  const getDistrictByDivisionID = APIURL + "district/listByDivisionID";
  try {
    let Result = await axios.post(getDistrictByDivisionID, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get Block  By DistrictID
const getBlockListByDistrictID = async (param) => {
  const getBlockByDistrictID = APIURL + "block/listByDistrictID";
  try {
    let Result = await axios.post(getBlockByDistrictID, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get Cluster  By BlockID
const getClusterListByBlockID = async (param) => {
  const getClusterByBlockID = APIURL + "cluster/listByBlockID";
  try {
    let Result = await axios.post(getClusterByBlockID, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get Village  By ClusterID
const getVillageListByClusterID = async (param) => {
  const getVillageByClusterID = APIURL + "village/listByClusterID";
  try {
    let Result = await axios.post(getVillageByClusterID, param);
    console.log("getVillageByClusterID-->>>", Result.data);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add School
const addSchool = async (param) => {
  const addSchoolApi = APIURL + "school/create";
  try {
    let Result = await axios.post(addSchoolApi, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update School
const updateSchool = async (param) => {
  const updateSchoolApi = APIURL + "school/update";
  try {
    let Result = await axios.post(updateSchoolApi, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//Edit  School Page
const editSchool = async (param) => {
  const editSchoolAPI = APIURL + `school/${param}`;
  try {
    let Result = await axios.get(editSchoolAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getSchoolList,
  getSchoolTypeList,
  updateSchool,
  deleteSchool,
  activateSchool,
  deactivateSchool,
  getDivisionListByStateID,
  getDistrictListByDivisionID,
  getBlockListByDistrictID,
  getClusterListByBlockID,
  getVillageListByClusterID,
  addSchool,
  editSchool,
  getSchoolByStateID,
};
