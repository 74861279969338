import React, { useEffect, useState } from "react";
import { PageMasterView } from "view/admin/PageMasterView";

export default function CardTable({ color }) {
  // const [pages, setPages] = useState([]);

  // useEffect(() => {
  //   getPageList()
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setPages(data.Result);
  //     });
  // }, []);

  return <PageMasterView></PageMasterView>;
}
