import React from "react";
import ThemeMasterView from "view/admin/ThemeMasterView";
const ListThemeMaster = () => {
  return (
    <>
      <ThemeMasterView />
    </>
  );
};

export default ListThemeMaster;
