import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { addDivision } from "services/divisionmaster.service";
import { getCountryList } from "services/countrymaster.service";
import { getStateListByCountryID } from "services/divisionmaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DEFAULT_LANGUAGE } from "global";
import { KeyPressForAlphabets } from "globalKeyPressValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./DivisionMaster.css";

export default function AddDivisionMaster() {
  const [Country, setCountry] = useState("");
  const [State, setState] = useState("");
  const [countryID, setCountryID] = useState("");
  const [StateID, setStateID] = useState("");
  const [LanguageID, setLanguageID] = useState(DEFAULT_LANGUAGE);
  const [DivisionName, setDivisionName] = useState("");
  const [DivisionCode, setDivisionCode] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    CountryList();
  }, []);
  const CountryList = () => {
    getCountryList().then((data) => {
      setCountry(data.Result);
    });
  };
  const StateList = (id) => {
    getStateListByCountryID(id).then((data) => {
      setState(data.Result);
    });
  };

  const AddDivision = (e) => {
    e.preventDefault();
    let Param = {
      stateID: StateID,
      divisionName: DivisionName,
      divisionCode: DivisionCode,
      languageID: LanguageID,
      createBy: AdminID,
    };

    addDivision(Param).then((response) => {
      console.log(response);
      if (response.Status === "Success" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/division");
        }, 1500);
      } else if (response.Status === "Success" && response.Error === true) {
        toast.error(response.Message);
      } else if (DivisionCode.length > 4) {
        toast.error(response.Result.errors.divisionCode[0]);
      } else {
        toast.error(response.Result.errors);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Division</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddDivision}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Country
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setCountryID(e.target.value);
                            StateList(e.target.value);
                          }}
                        >
                          <option hidden>Country List</option>
                          {Country &&
                            Country?.map((item, index) => {
                              return (
                                <option value={item.CountryID} key={index}>
                                  {item.CountryName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose State
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setStateID(e.target.value);
                          }}
                        >
                          <option hidden>State List</option>
                          {State &&
                            State?.map((item, index) => {
                              return (
                                <option value={item.StateID} key={index}>
                                  {item.StateName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Division Name
                      </label>
                      <input
                        type="text"
                        maxLength={45}
                        required
                        value={DivisionName}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Division-Name"
                        onChange={(e) => setDivisionName(e.target.value)}
                        onKeyPress={(e) => KeyPressForAlphabets(e)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="flex uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Division Code
                        <div className="[&_p]:hover:block relative ">
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            size="lg"
                            className="ml-2 "
                          />

                          <p className="hidden absolute bg-[#cbd5e1] text-[#000] p-1 rounded-[8px] text-[10px] left-[-140%] bottom-[25px]  w-[150px] tooltip-arrow  font-normal text-red-700 ">
                            DivisionCode should not be greater then 5 characters
                          </p>
                        </div>
                      </label>
                      <input
                        type="text"
                        required
                        maxLength={5}
                        minLength={3}
                        value={DivisionCode}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Division-Code"
                        onChange={(e) => setDivisionCode(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
