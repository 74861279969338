import axios from "axios";
const { APIURL } = require("global");


//To get the list of users
const getUsersList = async () => {
  const listApi = APIURL + "users/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get UserList By ID for Table
const getUserListByID = async (param) => {
  const getUserByIDApi = APIURL + `users/${param.UserID}`;
  try {
    let data = await axios.get(getUserByIDApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record User
const getPageWiseRecordUser = async (param) => {
  const getPageWiseRecordUserAPI = APIURL + "users/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordUserAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add User
const addUser = async (param) => {
  const addUserAPI = APIURL + "users/addUser";
  try {
    let Result = await axios.post(addUserAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To  Update User
const updateUser = async (param) => {
  const updateUserAPI = APIURL + "users/update";
  try {
    let Result = await axios.post(updateUserAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete user
const deleteUser = async (param) => {
  const deleteUserAPI = APIURL + "users/delete";
  try {
    let Result = await axios.post(deleteUserAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate user
const activateUser = async (param) => {
  const activateUserAPI = APIURL + "users/activate";
  try {
    let Result = await axios.post(activateUserAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To deactivate user
const deactivateUser = async (param) => {
  const deactivateUserAPI = APIURL + "users/deactivate";
  try {
    let Result = await axios.post(deactivateUserAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getUsersList,updateUser,
  getUserListByID,
  getPageWiseRecordUser,
  addUser,
  activateUser,
  deactivateUser,
  deleteUser,
};
