const { APIURL } = require("global");

const getDivisionTranslationList = async (param) => {
  const divisionTranslationList = APIURL + "admin/login";
  return await fetch(divisionTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterDivisionTranslation = async (param) => {
  const getFilterDivision = APIURL + "admin/login";
  return await fetch(getFilterDivision, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getDivisionTranslationByID = async (data) => {
  const getDivisionTranslationByIDApi =
    APIURL + `divisionTranslation/${data.DivisionTranslationID}`;
  return await fetch(getDivisionTranslationByIDApi);
};

const UpdateDivisionTranslation = async (param) => {
    const updateDivisionTranslation = APIURL + "admin/login";
    return await fetch(updateDivisionTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addDivisionMasterTranslation = async (param) => {
    const addDivisionTranslation = APIURL + "admin/login";
    return await fetch(addDivisionTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getDivisionTranslationList,
  getFilterDivisionTranslation,
  getDivisionTranslationByID,
  UpdateDivisionTranslation,
  addDivisionMasterTranslation,
};
