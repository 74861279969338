import axios from "axios";
const { APIURL } = require("global");

//To get Division List for Table
const getDivisionList = async () => {
  const listApi = APIURL + "division/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get State List By Country ID
const getStateListByCountryID = async (param) => {
  const statelistApi = APIURL + `state/countryid/${param}`;
  try {
    let Result = await axios.get(statelistApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Division
const addDivision = async (param) => {
  try {
    const addDivisionAPI = APIURL + "division/create";
    let Result = await axios.post(addDivisionAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To update Division
const updateDivision = async (param) => {
  const updateDivisionAPI = APIURL + "division/update";
  try {
    let Result = await axios.post(updateDivisionAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Division
const deleteDivision = async (param) => {
  const deleteDivisionAPI = APIURL + "division/delete";
  try {
    let Result = await axios.post(deleteDivisionAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Division
const activateDivision = async (param) => {
  const activateDivisionAPI = APIURL + "division/activate";
  try {
    let Result = await axios.post(activateDivisionAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivDivision
const deactivateDivision = async (param) => {
  const deactivateDivisionAPI = APIURL + "division/deactivate";
  try {
    let Result = await axios.post(deactivateDivisionAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To EditDivision
const editDivision = async (param) => {
  const editDivisionAPI = APIURL + `division/${param}`;
  try {
    let Result = await axios.get(editDivisionAPI);
    console.log("editdivision ", Result);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Division
const getPageWiseRecordDivision = async (param) => {
  const getPageWiseRecordDivisionAPI = APIURL + "division/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordDivisionAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getDivisionList,
  getStateListByCountryID,
  addDivision,
  updateDivision,
  activateDivision,
  deactivateDivision,
  deleteDivision,
  editDivision,
  getPageWiseRecordDivision,
};
