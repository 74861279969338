import axios from "axios";
const { APIURL } = require("global");

//To get Activity List for Table
const getActivityList = async () => {
  const listApi = APIURL + "activity/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Activity
const addActivity = async (param) => {
  const addActivityAPI = APIURL + "activity/save";
  try {
    let Result = await axios.post(addActivityAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update Activity
const updateActivity = async (param) => {
  const updateActivityAPI = APIURL + "activity/update";
  try {
    let Result = await axios.post(updateActivityAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//Edit  Activity Page
const editActivity = async (param) => {
  const editActivityAPI = APIURL + `activity/${param}`;
  try {
    let Result = await axios.get(editActivityAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Activity
const deleteActivity = async (param) => {
  const deleteActivityAPI = APIURL + "activity/delete";
  try {
    let Result = await axios.post(deleteActivityAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Activity
const activateActivity = async (param) => {
  const activateActivityAPI = APIURL + "activity/activate";
  try {
    let Result = await axios.post(activateActivityAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactive Activity
const deactivateActivity = async (param) => {
  const deactivateActivityAPI = APIURL + "activity/deactivate";
  try {
    let Result = await axios.post(deactivateActivityAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Activity
const getPageWiseRecordActivity = async (param) => {
  const getPageWiseRecordActivityAPI = APIURL + "activity/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordActivityAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getActivityList,
  updateActivity,
  addActivity,
  deleteActivity,
  deactivateActivity,
  activateActivity,
  editActivity,
  getPageWiseRecordActivity,
};
