const { APIURL } = require("global");

const getVillageTranslationList = async (param) => {
  const villageTranslationList = APIURL + "admin/login";
  return await fetch(villageTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterVillageTranslation = async (param) => {
  const getFilterVillage = APIURL + "admin/login";
  return await fetch(getFilterVillage, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getVillageTranslationByID = async (data) => {
  const getVillageTranslationByIDApi =
    APIURL + `villageTranslation/${data.VillageTranslationID}`;
  return await fetch(getVillageTranslationByIDApi);
};

const UpdateVillageTranslation = async (param) => {
    const updateVillageTranslation = APIURL + "admin/login";
    return await fetch(updateVillageTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addVillageMasterTranslation = async (param) => {
    const addVillageTranslation = APIURL + "admin/login";
    return await fetch(addVillageTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getVillageTranslationList,
  getFilterVillageTranslation,
  getVillageTranslationByID,
  UpdateVillageTranslation,
  addVillageMasterTranslation,
};
