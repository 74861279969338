const { APIURL } = require("global");

//to get the projectActivitymaster list
const getProjectActivityList = async () => {
  const listAPI = APIURL + "projectActivity/list";
  return await fetch(listAPI);
};

//Edit  ProjectActivity Page
const editProjectActivity = async (param) => {
  const projectActivityAPI = APIURL + `projectActivity/${param}`;
  return await fetch(projectActivityAPI);
};

//To Add ProjectActivity
const addProjectActivity = async (param) => {
  const addProjectActivityAPI = APIURL + "projectActivity/create";
  return await fetch(addProjectActivityAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Update ProjectActivity
const updateProjectActivity = async (param) => {
  const updateProjectActivityAPI = APIURL + "projectActivity/update";
  return await fetch(updateProjectActivityAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Activate ProjectActivity
const activateProjectActivity = async (param) => {
  const activateProjectActivityAPI = APIURL + "projectActivity/activate";
  return await fetch(activateProjectActivityAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Delete ProjectActivity
const deleteProjectActivity = async (param) => {
  const deleteProjectActivityAPI = APIURL + "projectActivity/delete";
  return await fetch(deleteProjectActivityAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Deactivate ProjectActivity
const deactivateProjectActivity = async (param) => {
    const deactivateProjectActivityAPI = APIURL + "projectActivity/deactivate";
    return await fetch(deactivateProjectActivityAPI, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getProjectActivityList,
  addProjectActivity,
  activateProjectActivity,
  deleteProjectActivity,
  deactivateProjectActivity,
  editProjectActivity,
  updateProjectActivity,
};
