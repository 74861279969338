const { APIURL } = require("global");

const getStateTranslationList = async (param) => {
  const stateTranslationList = APIURL + "admin/login";
  return await fetch(stateTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterStateTranslation = async (param) => {
  const getFilterState = APIURL + "admin/login";
  return await fetch(getFilterState, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getStateTranslationByID = async (data) => {
  const getStateTranslationByIDApi =
    APIURL + `stateTranslation/${data.StateTranslationID}`;
  return await fetch(getStateTranslationByIDApi);
};

const UpdateStateTranslation = async (param) => {
    const updateStateTranslation = APIURL + "admin/login";
    return await fetch(updateStateTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addStateMasterTranslation = async (param) => {
    const addStateTranslation = APIURL + "admin/login";
    return await fetch(addStateTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getStateTranslationList,
  getFilterStateTranslation,
  getStateTranslationByID,
  UpdateStateTranslation,
  addStateMasterTranslation,
};
