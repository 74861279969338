import axios from "axios";
const { APIURL } = require("global");

const GetSubjectListByGradeID = async (param) => {
  const SubjectList = APIURL + "subjectGradeMapping/mappedList";
  try {
    let Result = await axios.post(SubjectList, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const SubjectGradeMapping = async (param) => {
  const Mapping = APIURL + "subjectGradeMapping/Mapping";
  try {
    let Result = await axios.post(Mapping, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const SubjectGradeUnMapping = async (param) => {
  const UnMapping = APIURL + "subjectGradeMapping/UnMapping";
  try {
    let Result = await axios.post(UnMapping, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export { GetSubjectListByGradeID, SubjectGradeMapping, SubjectGradeUnMapping };
