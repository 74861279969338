import React, { useState, useEffect } from "react";
import { addActivity } from "services/activity.service";
import { getBoardList } from "services/boardmaster.service";
import { getSubjectByGradeID } from "services/coursemaster.service";
import { getGradeMasterList } from "services/grademaster.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCourseSubContentList } from "services/coursesubcontentmaster.service";

const AddActivityMaster = () => {
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("");
  const [concept, setConcept] = useState("");
  const [boardID, setBoardID] = useState("");
  const [gradeID, setGradeID] = useState("");
  const [subjectID, setSubjectID] = useState("");
  const [boardList, setBoardList] = useState("");
  const [gradeList, setGradeList] = useState("");
  const [subjectList, setSubjectList] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [courseSubContentID, setCourseSubContentID] = useState("");
  const [courseSubContentList, setCourseSubContentList] = useState([]);

  const navigate = useNavigate();
  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    DropDownList();
  }, []);

  const DropDownList = () => {
    getBoardList()
      .then((response) => {
        setBoardList(response.Result);
      });
    getGradeMasterList()
      .then((res) => {
        setGradeList(res.Result);
      });

    getCourseSubContentList()
      .then((response) => {
        setCourseSubContentList(response.Result);
      });
  };

  const AddActivity = (e) => {
    e.preventDefault();
    let Param = {
      BoardID: boardID,
      GradeID: gradeID,
      SubjectID: subjectID,
      Concept: concept,
      Attachment: attachment,
      Description: description,
      CourseSubContentID: courseSubContentID,
      CreateBy: AdminID,
    };

    addActivity(Param)
      .then((response) => {
        if (response.Status === "SUCCESS" && response.Error === false) {
          toast.success(response.Message);
          setTimeout(() => {
            navigate("/activity");
          }, 1500);
        } else if (response.Status === "SUCCESS" && response.Error === true) {
          toast.error(response.Message);
        } else if (response.Status === "Failed" && response.Error === false) {
          toast.error(response.Message);
        } 
        else {
          toast.error("Please fill the required fields");
        }
      });
  };

  const getSubjectListByGradeID = (e) => {
    setGradeID(e.target.value);
    let obj = {
      gradeID: e.target.value,
    };
    getSubjectByGradeID(obj).then((result) => {
      setSubjectList(result.Result);
    });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (file) {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setAttachment("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setImageUrl(setDp);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Activity</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddActivity}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Board
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden>Board List</option>
                          {boardList &&
                            boardList?.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Course SubContent
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setCourseSubContentID(e.target.value);
                          }}
                        >
                          <option hidden>Course SubContent List</option>
                          {courseSubContentList &&
                            courseSubContentList?.map((item, index) => {
                              return (
                                <option value={item.CourseSubContentID} key={index}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Grade
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            getSubjectListByGradeID(e);
                          }}
                        >
                          <option hidden>Grade List</option>
                          {gradeList &&
                            gradeList?.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setSubjectID(e.target.value);
                          }}
                        >
                          <option hidden>Subject List</option>
                          {subjectList &&
                            subjectList?.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Concept
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Concept"
                        onChange={(e) => setConcept(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Description"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="activity uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Attachment"
                        onChange={(e) => handleImage(e)}
                      />
                    </div>
                    <div>
                      {attachment && (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      )}
                    </div>
                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddActivityMaster;
