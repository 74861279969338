import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getPageWiseRecordCourse,
  deleteCourse,
  activateCourse,
  deactivateCourse,
  getcourseByKeyword,
} from "services/coursemaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "components/admin/Admin-Pagination/pagination";

export const CourseMasterView = () => {
  const [Course, setCourse] = useState([]);
  const [counts, setCounts] = useState("");
  const [startLimit, setStartLimit] = useState(0);
  const [endLimit, setEndLimit] = useState(10);
  const [searchedValue, SetSearchedValue] = useState("");
  const [searchedResult, SetSearchedResult] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    CourseList();
  }, [startLimit]);

  const CourseList = () => {
    let obj = {
      offset: startLimit,
      records: endLimit,
    };
    getPageWiseRecordCourse(obj).then((data) => {
      setCourse(data.Result);
      setCounts(data.Count);
    });
  };
  const RedirectToEditCourse = async (e) => {
    e.preventDefault();
    navigate("/innovativepaathshala/editcourse", {
      state: e.currentTarget.id,
    });
  };
  const DeleteCourse = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deleteCourse(Param).then((response) => {
      toast.error(response.Message);
      CourseList();
    });
  };

  const ActivateCourse = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    activateCourse(Param).then((response) => {
      toast.success(response.Message);
      CourseList();
    });
  };

  const DeactivateCourse = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deactivateCourse(Param).then((response) => {
      toast.error(response.Message);
      CourseList();
    });
  };

  const RedirectToCourseContent = (e) => {
    e.preventDefault();
    navigate("/innovativepaathshala/coursecontent", {
      state: e.currentTarget.id,
    });
  };
  const SearchByKeyword = (e) => {
    e.preventDefault();
    let Param = {
      keyWord: searchedValue,
    };
    console.log(Param);

    getcourseByKeyword(Param).then((response) => {
      SetSearchedResult(response.Result);
    });
  };

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
        <div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
          <div className="w-full max-w-full flex-grow flex-1">
            <h3 className="font-semibold text-lg text-black">Filter</h3>
          </div>
          <div>
            <input
              type="text"
              className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
              placeholder="Keyword"
              onChange={(e) => SetSearchedValue(e.target.value)}
            />

            <select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 ">
              <option hidden>Status</option>
              <option>Activate</option>
              <option>Deactivate</option>
              <option>Delete</option>
            </select>

            <button
              className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
              type="button"
              onClick={SearchByKeyword}
            >
              Filter
            </button>
          </div>
        </div>
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">
                List of Course
              </h3>
            </div>
            <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
              <div className=" mt-32 sm:mt-0">
                <button
                  className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  <Link to="/innovativepaathshala/addcourse">Add</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr className="bg-blue-950">
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Course Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Board Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Grade Name
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Subject Name
                </th>

                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Status
                </th>
                <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchedResult
                ? searchedResult &&
                  searchedResult.map((item) => {
                    return (
                      <tr key={item.CourseID}>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {item.CourseName ?? item.Name}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {item.boardName}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6 d align-middle text-black text-md whitespace-normal p-4">
                          {item.gradeName}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6 d align-middle text-black text-md whitespace-normal p-4">
                          {item.subjectName}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {item.CStatus}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
                          {item.CStatus === "Active" && (
                            <i
                              id={item.CourseID}
                              className="fas fa-edit"
                              onClick={RedirectToEditCourse}
                            ></i>
                          )}
                          {item.CStatus === "Active" && (
                            <i
                              id={item.CourseID}
                              className="fas fa-trash text-red-600"
                              onClick={(e) => DeleteCourse(e)}
                            ></i>
                          )}
                          {(item.CStatus === "Deactive" ||
                            item.CStatus === "Delete") && (
                            <i
                              id={item.CourseID}
                              className="fas fa-check-circle"
                              onClick={(e) => ActivateCourse(e)}
                            ></i>
                          )}
                          {item.CStatus === "Active" && (
                            <i
                              id={item.CourseID}
                              className="fas fa-ban text-red-600"
                              onClick={(e) => DeactivateCourse(e)}
                            ></i>
                          )}
                          <i
                            id={item.CourseID}
                            className="fas fa-file-alt"
                            onClick={(e) => RedirectToCourseContent(e)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })
                : Course.map((course) => {
                    return (
                      <tr key={course.CourseID}>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {course.CourseName ?? course.Name}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {course.BoardName}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6 d align-middle text-black text-md whitespace-normal p-4">
                          {course.GradeName}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6 d align-middle text-black text-md whitespace-normal p-4">
                          {course.SubjectName}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {course.CStatus}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
                          {course.CStatus === "Active" && (
                            <i
                              id={course.CourseID}
                              className="fas fa-edit"
                              onClick={RedirectToEditCourse}
                            ></i>
                          )}
                          {course.CStatus === "Active" && (
                            <i
                              id={course.CourseID}
                              className="fas fa-trash text-red-600"
                              onClick={(e) => DeleteCourse(e)}
                            ></i>
                          )}
                          {(course.CStatus === "Deactive" ||
                            course.CStatus === "Delete") && (
                            <i
                              id={course.CourseID}
                              className="fas fa-check-circle"
                              onClick={(e) => ActivateCourse(e)}
                            ></i>
                          )}
                          {course.CStatus === "Active" && (
                            <i
                              id={course.CourseID}
                              className="fas fa-ban text-red-600"
                              onClick={(e) => DeactivateCourse(e)}
                            ></i>
                          )}
                          <i
                            id={course.CourseID}
                            className="fas fa-file-alt"
                            onClick={(e) => RedirectToCourseContent(e)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>{" "}
          <ToastContainer />
          <Pagination
            data={{
              counts,
              startLimit,
              setStartLimit,
              endLimit,
              setEndLimit,
            }}
          />
        </div>
      </div>
    </>
  );
};
