import axios from "axios";
const { APIURL } = require("global");

//To get LearningOutcome List for Table
const getLearningOutcomeList = async () => {
  const listApi = APIURL + "learningOutcome/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get list by subjectID
const getLearningOutcomeListBySubjectID = async (param) => {
  const getLearningOutcomeListBySubjectIDAPI =
    APIURL + "learningOutcome/getLearningOutcomeBySubjectID";
  try {
    let Result = await axios.post(getLearningOutcomeListBySubjectIDAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To add LearningOutcome
const addLearningOutcome = async (param) => {
  const addLearningOutcomeAPI = APIURL + "learningOutcome/create";
  try {
    let Result = await axios.post(addLearningOutcomeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update LearningOutcome
const updateLearningOutcome = async (param) => {
  const updateLearningOutcomeAPI = APIURL + "learningOutcome/update";
  try {
    let Result = await axios.post(updateLearningOutcomeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//Edit  LearningOutcome Page
const editLearningOutcome = async (param) => {
  const learningOutcomeAPI = APIURL + `learningOutcome/${param}`;
  try {
    let Result = await axios.get(learningOutcomeAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete LearningOutcome
const deleteLearningOutcome = async (param) => {
  const deleteLearningOutcomeAPI = APIURL + "learningOutcome/delete";
  try {
    let Result = await axios.post(deleteLearningOutcomeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate LearningOutcome
const activateLearningOutcome = async (param) => {
  const activateLearningOutcomeAPI = APIURL + "learningOutcome/activate";
  try {
    let Result = await axios.post(activateLearningOutcomeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactive LearningOutcome
const deactivateLearningOutcome = async (param) => {
  const deactivateLearningOutcomeAPI = APIURL + "learningOutcome/deactivate";
  try {
    let Result = await axios.post(deactivateLearningOutcomeAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Learning Outcome
const getPageWiseRecordLearningOutcome = async (param) => {
  const getPageWiseRecordLearningOutcomeAPI =
    APIURL + "learningOutcome/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordLearningOutcomeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getLearningOutcomeList,
  addLearningOutcome,
  editLearningOutcome,
  updateLearningOutcome,
  deleteLearningOutcome,
  activateLearningOutcome,
  deactivateLearningOutcome,
  getLearningOutcomeListBySubjectID,
  getPageWiseRecordLearningOutcome,
};
