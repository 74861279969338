import React, { useState, useEffect } from "react";
import { getBoardList } from "services/boardmaster.service";
import { getGradeMasterList } from "services/grademaster.service";
import { getLanguageMasterList } from "services/languages.service";
import { getSubjectByGradeID } from "services/coursemaster.service";
import { editChapter, updateChapter } from "services/chaptermaster.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditChapterMaster = () => {
  const [Board, setBoard] = useState([]);
  const [BoardID, setBoardID] = useState("");
  const [Grade, setGrade] = useState([]);
  const [GradeID, setGradeID] = useState("");
  const [Subject, setSubject] = useState([]);
  const [SubjectID, setSubjectID] = useState("");
  const [Language, setLanguage] = useState([]);
  const [LanguageID, setLanguageID] = useState("");
  const [Name, setName] = useState("");

  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    editChapter(location.state).then((data) => {
      setGradeID(data.Result.GradeID);
      setBoardID(data.Result.BoardID);
      setSubjectID(data.Result.SubjectID);
      setLanguageID(data.Result.LanguageID);
      setName(data.Result.Name);
      const obj = {
        gradeID: data.Result.GradeID,
      };
      getSubjectByGradeID(obj).then((result) => {
        setSubject(result.Result);
      });
    });

    BoardList();
    GradeList();
    LanguageList();
  }, []);

  const BoardList = () => {
    getBoardList().then((data) => {
      setBoard(data.Result);
    });
  };

  const GradeList = () => {
    getGradeMasterList().then((data) => {
      setGrade(data.Result);
    });
  };

  const SubjectListByGradeID = (e) => {
    setGradeID(e.target.value);
    let obj = {
      gradeID: e.target.value,
    };
    getSubjectByGradeID(obj).then((result) => {
      setSubject(result.Result);
    });
  };

  const LanguageList = () => {
    getLanguageMasterList().then((data) => {
      setLanguage(data.Result);
    });
  };

  const UpdateChapter = (e) => {
    e.preventDefault();
    let Param = {
      chapterID: location.state,
      boardID: BoardID,
      gradeID: GradeID,
      subjectID: SubjectID,
      languageID: LanguageID,
      name: Name,
      createBy: AdminID,
    };
    updateChapter(Param).then((response) => {
      if (response.Status === "SUCCESS") {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/chapter");
        }, 1500);
      } else if (response.Status === "Failed") {
        toast.error(response.Message);
      } else {
        toast.error("Please fill the required fields");
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Chapter</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateChapter}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Board
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={BoardID}
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden>Board List</option>
                          {Board &&
                            Board.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}{" "}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Grade
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={GradeID}
                          onChange={SubjectListByGradeID}
                        >
                          <option hidden>Grade List</option>
                          {Grade &&
                            Grade.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={SubjectID}
                          onChange={(e) => {
                            setSubjectID(e.target.value);
                          }}
                        >
                          <option hidden>Subject List</option>
                          {Subject &&
                            Subject.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={LanguageID}
                          onChange={(e) => {
                            setLanguageID(e.target.value);
                          }}
                        >
                          <option hidden>Language List</option>
                          {Language &&
                            Language.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Name
                      </label>
                      <input
                        value={Name}
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditChapterMaster;
