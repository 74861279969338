import React from "react";
import CountryMasterView from "view/admin/CountryMasterView";
const ListCountryMaster = () => {
  return (
    <>
      <CountryMasterView />
    </>
  );
};

export default ListCountryMaster;
