import React, { useState, useEffect } from "react";
import {
  addHandBook,
} from "services/handbook.service";
import { getLanguageMasterList } from "services/languages.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";

export default function AddHandBookMaster() {
  const [language, setLanguage] = useState("");
  const [handBookData, setHandBookData] = useState({
    createBy: JSON.parse(localStorage.getItem("user")).AdminID,
  });

  const navigate = useNavigate();
  useEffect(() => {
    languageList();
  }, []);
  const languageList = () => {
    getLanguageMasterList()
    .then((data) =>  {
        setLanguage(data.Result);
      });
  };

  const handleChange = (e) => {
    setHandBookData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const AddHandBook = (e) => {
    e.preventDefault();

    addHandBook(handBookData)
      .then((response) => {
        if (response.Status === "Success" && response.Error === false) {
          toast.success(response.Message);
          setTimeout(() => {
            navigate("/teachermanual");
          }, 1500);
        } else if (response.Status === "Success" && response.Error === true){
            toast.error(response.Message);
        } else {
          toast.error("Please fill required fields");
        }
      });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Teacher Manual
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddHandBook}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="languageID"
                          onChange={handleChange}
                        >
                          <option hidden>Language List</option>
                          {language &&
                            language?.map((item, index) => {
                              return (
                                <option
                                  value={item.LanguageID}
                                  key={index}
                                >
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}
