import axios from "axios";
const { APIURL } = require("global");

//To get Page List for Table
const getPageList = async () => {
  const listApi = APIURL + "page/list";
  try {
    return await axios.get(listApi);
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Page List for Table
const getPageByID = async (param) => {
  const getPageByIDApi = APIURL + `page/${param.PageID}`;
  try {
    let data = await axios.get(getPageByIDApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Page List
const addPage = async (param) => {
  const addPageAPI = APIURL + "page/create";
  try {
    let Result = await axios.post(addPageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Page List
const updatePage = async (param) => {
  const updatePageAPI = APIURL + "page/update";

  try {
    let Result = await axios.post(updatePageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Page
const deletePage = async (param) => {
  const deletePageAPI = APIURL + "page/delete";

  try {
    let Result = await axios.post(deletePageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Page
const activatePage = async (param) => {
  const activatePageAPI = APIURL + "page/activate";

  try {
    let Result = await axios.post(activatePageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To deactivate Page
const deactivatePage = async (param) => {
  const deactivatePageAPI = APIURL + "page/deactivate";

  try {
    let Result = await axios.post(deactivatePageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Page
const getPageWiseRecordPage = async (param) => {
  const getPageWiseRecordPageAPI = APIURL + "page/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordPageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getPageList,
  getPageByID,
  addPage,
  updatePage,
  deletePage,
  activatePage,
  deactivatePage,
  getPageWiseRecordPage,
};
