import axios from "axios";
const { APIURL } = require("global");

//To get InnovativePathshala List for Table
const getInnovativePathshalaList = async () => {
  const listApi = APIURL + "innovativePathshala/list";
  try {
    let Result = await axios.get(listApi);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add InnovativePathshala
const addInnovativePathshala = async (param) => {
  const addInnovativePathshalaAPI = APIURL + "innovativePathshala/create";
  try {
    let Result = await axios.post(addInnovativePathshalaAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update InnovativePathshala
const updateInnovativePathshala = async (param) => {
  const updateInnovativePathshalaAPI = APIURL + "innovativePathshala/update";
  try {
    let Result = await axios.post(updateInnovativePathshalaAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete InnovativePathshala
const deleteInnovativePathshala = async (param) => {
  const deleteInnovativePathshalaAPI = APIURL + "innovativePathshala/delete";
  try {
    let Result = await axios.post(deleteInnovativePathshalaAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate InnovativePathshala
const activateInnovativePathshala = async (param) => {
  const activateInnovativePathshalaAPI =
    APIURL + "innovativePathshala/activate";
  try {
    let Result = await axios.post(activateInnovativePathshalaAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate InnovativePathshala
const deactivateInnovativePathshala = async (param) => {
  const deactivateInnovativePathshalaAPI =
    APIURL + "innovativePathshala/deactivate";
  try {
    let Result = await axios.post(deactivateInnovativePathshalaAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const editInnovativePathshala = async (param) => {
  const editInnovativePathshalaAPI = APIURL + `innovativePathshala/${param}`;
  try {
    let Result = await axios.get(editInnovativePathshalaAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record InnovativePathshala
const getPageWiseRecordInnovativePathshala = async (param) => {
  const getPageWiseRecordInnovativePathshalaAPI =
    APIURL + "innovativePathshala/getPageWiseRecords";
  try {
    let Result = await axios.post(
      getPageWiseRecordInnovativePathshalaAPI,
      param
    );
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get CourseContent PageWise Record By Course
const getPageWiseRecordInnovativePathshalaByCourseSubContent = async (
  param
) => {
  const getPeriodWiseRecordInnovativePathshalaAPI =
    APIURL + "innovativePathshala/getPageWiseRecordsByCourseSubContent";

  try {
    let Result = await axios.post(
      getPeriodWiseRecordInnovativePathshalaAPI,
      param
    );
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getInnovativePathshalaList,
  addInnovativePathshala,
  updateInnovativePathshala,
  deleteInnovativePathshala,
  activateInnovativePathshala,
  deactivateInnovativePathshala,
  editInnovativePathshala,
  getPageWiseRecordInnovativePathshala,
  getPageWiseRecordInnovativePathshalaByCourseSubContent,
};
