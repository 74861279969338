import React, { useState } from "react";
import { useNavigate } from "react-router";
import { addGradeMaster } from "services/grademaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeyPressForNumeric } from "globalKeyPressValidation";

export default function AddGradeMaster() {
  const [gradeName, setGradeName] = useState("");
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const AddGrade = (e) => {
    e.preventDefault();
    let Param = {
      gradeName: gradeName,
      createBy: AdminID,
    };
    addGradeMaster(Param).then((response) => {
      if (response.Status === "Success" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/grade");
        }, 1500);
      } else if (gradeName > 12) {
        toast.error("The gradeName may not be greater than 12");
      } else {
        toast.error(response.Message);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Grade</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddGrade}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Grade Name
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Grade-Name"
                        onChange={(e) => setGradeName(e.target.value)}
                        onKeyDown={(e) => {
                          KeyPressForNumeric(e);
                        }}
                        autoFocus
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
