import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/admin/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/admin/Dropdowns/UserDropdown.js";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-slate-300 flex flex-wrap items-center justify-between relative md:w-1/6 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/dashboard"
          >
            <img src="/images/Logo.png" className="m-auto" />
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none  absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {/* Dash Board */}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/dashboard") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/dashboard") 
                        ? "opacity-75"
                        : "text-black")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
              Master
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/user") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/user"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/user") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Users
                </Link>
              </li>
              {/* page Master */}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/page") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/page"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/page") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Pages
                </Link>
              </li>
              {/* Course Master */}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/board") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/board"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/board") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Boards
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/grade") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/grade"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/grade") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Grades
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/subject") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/subject"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/subject") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Subjects
                </Link>

                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/country") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/country"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/country") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Countries
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/state") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/state"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/state") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  States
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/division") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/division"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/division") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Divisions
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/district") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/district"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/district") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Districts
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/block") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/block"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/block") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Blocks
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/cluster") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/cluster"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/cluster") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Clusters
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/village") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/village"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/village") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Villages
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/school") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/school"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/school") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Schools
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/language") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/language"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/language") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Languages
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/tlm") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/tlm"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/tlm") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  TLM
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/tlmitem") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/tlmitem"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/tlmitem") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  TLM Items
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/activity") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/activity"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/activity") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Activities
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/configuration") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/configuration"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/configuration") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Configuration
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/teachermanual") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/teachermanual"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/teachermanual") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Teacher Manual (HandBook)
                </Link>

                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/knowledge") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/knowledge"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/knowledge") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Knowledge Base
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/casestudy") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/casestudy"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/casestudy") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Case Study
                </Link>
                <li>
                  Competency Based Learning
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/learningOutcome") 
                        ? "text-black hover:text-white"
                        : "text-black hover:text-white")
                    }
                    to="/learningOutcome"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/learningOutcome") 
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Learning Outcome
                  </Link>
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/concept") 
                        ? "text-black hover:text-white"
                        : "text-black hover:text-white")
                    }
                    to="/concept"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/concept") 
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Concepts
                  </Link>
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/subconcept") 
                        ? "text-black hover:text-white"
                        : "text-black hover:text-white")
                    }
                    to="/subconcept"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/subconcept") 
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Sub concepts
                  </Link>
                </li>
                <li>
                  Competency Based Learning Plan
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/chapter") 
                        ? "text-black hover:text-white"
                        : "text-black hover:text-white")
                    }
                    to="/chapter"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/chapter") 
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Chapters
                  </Link>
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf("/period") 
                        ? "text-black hover:text-white"
                        : "text-black hover:text-white")
                    }
                    to="/period"
                  >
                    <i
                      className={
                        "fas fa-table mr-2 text-sm " +
                        (window.location.href.indexOf("/period") 
                          ? "opacity-75"
                          : "text-blueGray-300")
                      }
                    ></i>{" "}
                    Periods
                  </Link>
                </li>
              </li>
              <li>
                Competency Based Smart Learning
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/theme") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/theme"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/theme") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Themes
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/project") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/project"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/project") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Projects
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/projectactivity") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/projectactivity"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/projectactivity") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Project Activities
                </Link>
              </li>
              <li>
                Innovative Paathshala
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/innovativepaathshala/course "
                    ) 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/innovativepaathshala/course"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/innovativepaathshala/course"
                      ) 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Courses
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/innovativepaathshala/coursecontent"
                    ) 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/innovativepaathshala/coursecontent"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/innovativepaathshala/coursecontent"
                      ) 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Course Content
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/innovativepaathshala/chapterWeek"
                    ) 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/innovativepaathshala/chapterWeek"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/innovativepaathshala/chapterWeek"
                      ) 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Chapter Week
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/innovativePathshala ") !==
                    -1
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/innovativePathshala"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/innovativePathshala") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Innovative Pathshala
                </Link>
                {/* <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/innovativepaathshala/chapterweek") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/innovativepaathshala/chapterweek"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/innovativepaathshala/chapterweek") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Chapter/Week
                </Link> */}
              </li>
              <li>
                Idea Submission
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/status") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/status"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/status") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Status
                </Link>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/idea") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/idea"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/idea") 
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Idea
                </Link>
              </li>
              <li>
                Mapping
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/subjectgrademapping") 
                      ? "text-black hover:text-white"
                      : "text-black hover:text-white")
                  }
                  to="/subjectgrademapping"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/subjectgrademapping") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Subject Grade Mapping
                </Link>
              </li>
              <li>
                Translation
                <ul>
                  <li>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/admin/blockTranslation"
                        ) 
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/blockTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/blockTranslation") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Block Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/boardTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/boardTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/boardTranslation") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Board Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/clusterTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/clusterTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/clusterTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Cluster Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/courseTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/courseTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/courseTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Course Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/districtTranslation"
                        ) 
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/districtTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/districtTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      District Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf(
                          "/divisionTranslation"
                        ) 
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/divisionTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/divisionTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Division Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/pageTranslation") 
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/pageTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/pageTranslation") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Page Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/schoolTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/schoolTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/schoolTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      School Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/stateTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/stateTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/stateTranslation") !==
                          -1
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      State Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/subjectTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/subjectTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/subjectTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Subject Translation
                    </Link>
                    <Link
                      className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/villageTranslation") !==
                        -1
                          ? "text-black hover:text-white"
                          : "text-black hover:text-white")
                      }
                      to="/villageTranslation"
                    >
                      <i
                        className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/villageTranslation"
                          ) 
                            ? "opacity-75"
                            : "text-blueGray-300")
                        }
                      ></i>{" "}
                      Village Translation
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
