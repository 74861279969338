import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addBoardMasterTranslation,
  getBoardTranslationDataByLanguageID,
} from "services/Translation/BoardTranslation.service";
import {
  getToLanguageIDByFromLanguageID,
  getLanguageMasterList,
} from "services/languages.service";

export default function AddBoardMasterTranslation() {
  const [BoardName, setBoardName] = useState("");
  const [fromLanguageID, setFromLanguageID] = useState("");
  const [fromLanguageList, setFromLanguageList] = useState("");
  const [toLanguageList, setToLanguageList] = useState("");
  const [toLanguageID, setToLanguageID] = useState("");
  const [originalText, setOriginalText] = useState("");
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useState(() => {
    getLanguageMasterList()
      .then((response) => response.json())
      .then((data) => {
        setFromLanguageList(data.Result);
      });
  }, []);

  const changeToLanguageID = (e) => {
    setFromLanguageID(e.target.value);
    getToLanguageIDByFromLanguageID({ fromLanguageID: e.target.value }).then(
      (response) => {
        setToLanguageList(response.Result);
      }
    );
  };

  const getOriginalText = (e) => {
    setToLanguageID(e.target.value);
    let obj = {
      fromLanguageID: fromLanguageID,
      toLanguageID: e.target.value,
    };
    getBoardTranslationDataByLanguageID(obj).then((response) => {
      if (response.Result.length > 0) {
        setOriginalText(response.Result);
      } else if (response.Result.length === 0) {
        setOriginalText("");
        toast.warn(response.Message);
      } else {
        console.log("im in else condition");
        toast.error(response.Message);
      }
    });
  };

  const addBoardTranslation = (e) => {
    e.preventDefault();
    let Param = {
      boardID: originalText[0].BoardID,
      boardName: BoardName,
      languageID: toLanguageID,
      createBy: AdminID,
    };
    addBoardMasterTranslation(Param).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        let obj = {
          fromLanguageID: fromLanguageID,
          toLanguageID: toLanguageID,
        };
        getBoardTranslationDataByLanguageID(obj).then((response) => {
          if (response.Result.length > 0) {
            setOriginalText(response.Result);
          } else if (response.Result.length === 0) {
            setOriginalText("");
          } else {
            toast.error(response.Message);
          }
        });
      } else if (response.Status === "SUCCESS" && response.Error === true) {
        toast.error(response.Message);
      } else {
        toast.error(response.Result.errors.BoardName[0]);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Board Translation Master
              </h3>
            </div>
            <div>
              <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                <div className=" mt-32 sm:mt-0">
                  <button
                    className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <Link to="/boardTranslationList">Go to List</Link>
                  </button>
                </div>
              </div>
              <div className="mx-4 my-2">
                <label className="font-semibold flex flex-col w-1/4">
                  From
                  <select
                    className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                    onChange={changeToLanguageID}
                  >
                    <option hidden>Language</option>
                    {fromLanguageList &&
                      fromLanguageList.map((item, index) => {
                        return (
                          <option value={item.LanguageID} key={index}>
                            {item.LanguageName}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </div>

              <div className="mx-4 my-2">
                <label className="font-semibold flex flex-col w-1/4">
                  To
                  <select
                    className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                    onChange={getOriginalText}
                  >
                    <option hidden>Language</option>
                    {toLanguageList &&
                      toLanguageList?.map((item, index) => {
                        return (
                          <option value={item.LanguageID} key={index}>
                            {item.LanguageName}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </div>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={addBoardTranslation}>
                    {originalText?.length > 0 ? (
                      <div className="relative w-full mb-3">
                        <label
                          className="board uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-page-name"
                        >
                          Board Name
                        </label>
                        <label
                          className="board uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-page-name"
                        >
                          {originalText[0]?.BoardName}
                        </label>
                        <textarea
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="BoardName"
                          onChange={(e) => setBoardName(e.target.value)}
                        />
                      </div>
                    ) : !fromLanguageID || !toLanguageID ? (
                      ""
                    ) : (
                      "No Data Found"
                    )}
                    {/* <div className="relative w-full mb-3">
                      <label
                        className="board uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Subject Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Subject-Code"
                        onChange={(e) => setSubjectCode(e.target.value)}
                      />
                    </div> */}

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
