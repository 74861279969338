import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getBoardByID, updateBoard } from "services/boardmaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeyPressForAlphabets } from "globalKeyPressValidation";

export default function EditBoardMaster() {
  const location = useLocation();
  let BoardID = location.state;
  const [BoardName, setBoardName] = useState("");
  const [BoardDescription, setBoardDescription] = useState("");
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  useEffect(() => {
    GetBoardByID();
  }, []);
  const GetBoardByID = () => {
    let Param = {
      BoardID: BoardID,
    };
    getBoardByID(Param)
      .then((data) => {
        setBoardName(data.Result[0].BoardName);

        setBoardDescription(data.Result[0].BoardDescription);
      });
  };
  const UpdateBoard = (e) => {
    e.preventDefault();
    let Param = {
      boardID: BoardID,
      boardName: BoardName,
      description: BoardDescription,
      createBy: AdminID,
    };
    updateBoard(Param)
      .then((response) => {
        if (response.Error === false && !Boolean(response.errors)) {
          if (response.Status === "SUCCESS") {
            toast.success(response.Message);
            setTimeout(() => {
              navigate("/board");
            }, 1500);
          } else {
            toast.error(response.Message);
          }
        } else {
          console.log(response.errors);
          toast.error(
            Boolean(response.errors)
              ? Object.values(response.errors)[0][0]
              : response.Message
          );
        }
      });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Board</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateBoard}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Board Name
                      </label>
                      <input
                        type="text"
                        autoFocus
                        maxLength={50}
                        minLength={2}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Board-Name"
                        value={BoardName}
                        onChange={(e) => setBoardName(e.target.value)}
                        onKeyDown={(e)=> KeyPressForAlphabets(e)}
                        required
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        maxLength={100}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Description"
                        value={BoardDescription}
                        onChange={(e) => setBoardDescription(e.target.value)}
                        required
                      />
                    </div>

                    <div className="text-left mt-6 ">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Update
                      </button>
                      <Link
                        class="bg-transparent hover:bg-blue-500 text-blue-700
                      font-semibold hover:text-white py-2 px-4 rounded"
                        to="/board"
                      >
                        Back
                      </Link>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
