import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { addLearningOutcome } from "services/learningOutcomeMaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBoardList } from "services/boardmaster.service";
import { getLanguageMasterList } from "services/languages.service";
import { GetSubjectListByGradeID } from "services/subjectgrademapping.service";
import { getGradeMasterList } from "services/grademaster.service";

export default function AddLearningOutcomeMaster() {
  const [learningOutcomeData, setLearningOutcomeData] = useState({
    createBy: JSON.parse(localStorage.getItem("user")).AdminID,
  });
  const [subjectList, setSubjectList] = useState("");
  const [languageList, setLanguageList] = useState("");
  const [boardList, setBoardList] = useState("");
  const [gradeList, setGradeList] = useState("");
  const [GradeID, setGradeID] = useState("");
  const navigate = useNavigate();
  //   let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    getBoardList()
      .then((value) => {
        setBoardList(value.Result);
      });

    getLanguageMasterList()
      .then((value) => {
        setLanguageList(value.Result);
      });
    getGradeMasterList()
      .then((value) => {
        setGradeList(value.Result);
      });
  }, []);

  const AddLearningOutcome = (e) => {
    e.preventDefault();
    // let Param = {
    //   learningOutcomeName: learningOutcomeName,
    //   createBy: AdminID,
    // };
    addLearningOutcome(learningOutcomeData).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/learningOutcome");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  const handleChange = (e) => {
    if (e.target.name === "gradeID") {
      let obj = {
        [e.target.name]: e.target.value,
      };
      GetSubjectListByGradeID(obj).then((data) => {
        setSubjectList(data.Result);
      });
      setLearningOutcomeData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
    setLearningOutcomeData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Learning Outcome
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddLearningOutcome}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Board
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="boardID"
                          onChange={handleChange}
                        >
                          <option hidden>Board List</option>
                          {boardList &&
                            boardList?.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Grade
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="gradeID"
                          onChange={handleChange}
                        >
                          <option hidden>Grade List</option>
                          {gradeList &&
                            gradeList?.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="subjectID"
                          onChange={handleChange}
                        >
                          <option hidden>Subject List</option>
                          {subjectList &&
                            subjectList?.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="languageID"
                          onChange={handleChange}
                        >
                          <option hidden>Language List</option>
                          {languageList &&
                            languageList?.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="name"
                        placeholder="LearningOutcome-Name"
                        onChange={handleChange}
                        autoFocus
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
