import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen bg-gray-200 flex flex-col items-center justify-center">
      <h1 className="text-5xl font-bold text-gray-800">404</h1>
      <p className="text-xl text-gray-600 mt-4">
        Oops! The page you are looking for could not be found.
      </p>
      <Link
        to="/dashboard"
        className="mt-8 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-300"
      >
        Go back to homepage
      </Link>
    </div>
  );
};

export default NotFound;
