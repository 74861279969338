import axios from "axios";
 const { APIURL } = require("global");

//to get thetheme list for table

const getThemeList = async () => {
  const listAPI = APIURL + "theme/list";
  try {
    let Result = await axios.get(listAPI);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Add Theme
const addTheme = async (param) => {
  const addThemeAPI = APIURL + "theme/create";
  try {
    let Result = await axios.post(addThemeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Activate Theme
const activateTheme = async (param) => {
  const activateThemeAPI = APIURL + "theme/activate";
  try {
    let Result = await axios.post(activateThemeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Deactivate Theme
const deactivateTheme = async (param) => {
  const deactivateThemeAPI = APIURL + "theme/deactivate";
  try {
    let Result = await axios.post(deactivateThemeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Theme
const deleteTheme = async (param) => {
  const deleteThemeAPI = APIURL + "theme/delete";
  try {
    let Result = await axios.post(deleteThemeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update Theme
const updateTheme = async (param) => {
  const updateThemeAPI = APIURL + "theme/update";
  try {
    let Result = await axios.post(updateThemeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const editTheme = async (param) => {
  const editThemeAPI = APIURL + `theme/${param}`;
  try {
    let Result = await axios.post(editThemeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export { getThemeList, addTheme, activateTheme, deactivateTheme, deleteTheme , updateTheme,editTheme};
