const { APIURL } = require("global");

const getGradeTranslationList = async (param) => {
  const gradeTranslationList = APIURL + "admin/login";
  return await fetch(gradeTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterGradeTranslation = async (param) => {
  const getFilterGrade = APIURL + "admin/login";
  return await fetch(getFilterGrade, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getGradeTranslationByID = async (data) => {
  const getGradeTranslationByIDApi =
    APIURL + `gradeTranslation/${data.GradeTranslationID}`;
  return await fetch(getGradeTranslationByIDApi);
};

const UpdateGradeTranslation = async (param) => {
    const updateGradeTranslation = APIURL + "admin/login";
    return await fetch(updateGradeTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addGradeMasterTranslation = async (param) => {
    const addGradeTranslation = APIURL + "admin/login";
    return await fetch(addGradeTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getGradeTranslationList,
  getFilterGradeTranslation,
  getGradeTranslationByID,
  UpdateGradeTranslation,
  addGradeMasterTranslation,
};
