import axios from "axios";
const { APIURL } = require("global");

//to get the projectmaster list

const getProjectList = async () => {
  const listAPI = APIURL + "project/list";
  try {
    let Result = await axios.get(listAPI);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Project
const addProject = async (param) => {
  const addProjectAPI = APIURL + "project/create";
  try {
    let Result = await axios.post(addProjectAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Activate Project
const activateProject = async (param) => {
  const activateProjectAPI = APIURL + "project/activate";
  try {
    let Result = await axios.post(activateProjectAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Deactivate Project
const deactivateProject = async (param) => {
  const deactivateProjectAPI = APIURL + "project/deactivate";
  try {
    let Result = await axios.post(deactivateProjectAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Project
const deleteProject = async (param) => {
  const deleteProjectAPI = APIURL + "project/delete";
  try {
    let Result = await axios.post(deleteProjectAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Project List for Table
const getProjectByID = async (param) => {
  const getProjectByIDApi = APIURL + `project/${param}`;
  try {
    let data = await axios.get(getProjectByIDApi);
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//to update project
const updateProject = async (param) => {
  const updateProjectAPI = APIURL + "project/update";
  try {
    let Result = await axios.post(updateProjectAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get getProjectByThemeID
const getProjectByThemeID = async (param) => {
  const getProjectByThemeIDAPI = APIURL + "project/getProjectByThemeID";
  try {
    let Result = await axios.post(getProjectByThemeIDAPI, param);
    let data = Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getProjectList,
  addProject,
  activateProject,
  deactivateProject,
  deleteProject,
  getProjectByID,
  updateProject,
  getProjectByThemeID,
};
