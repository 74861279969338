const { APIURL } = require("global");

// eslint-disable-next-line no-undef
//To get IdeaStatus List for Table
const getIdeaStatusList = async () => {
  const listApi = APIURL + "ideaStatus/list";
  return await fetch(listApi);
};

//To get IdeaStatus List for Table
const getIdeaStatusByID = async (param) => {
  const getIdeaStatusByIDApi = APIURL + `ideaStatus/${param}`;
  return await fetch(getIdeaStatusByIDApi);
};

//To get IdeaStatus List for Table
const getIdeaStatusByIdeaID = async (param) => {
  console.log(param)
  const getIdeaStatusByIDApi = APIURL + `ideaStatus/ideaStatusDataByIdea/${param}`;
  return await fetch(getIdeaStatusByIDApi);
};

//To Add IdeaStatus List
const addIdeaStatus = async (param) => {
  const addIdeaStatusAPI = APIURL + "ideaStatus/create";
  return await fetch(addIdeaStatusAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Update IdeaStatus List
const updateIdeaStatus = async (param) => {
  const updateIdeaStatusAPI = APIURL + "ideaStatus/addNewStatus";
  return await fetch(updateIdeaStatusAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Delete IdeaStatus
const deleteIdeaStatus = async (param) => {
  const deleteIdeaStatusAPI = APIURL + "ideaStatus/delete";
  return await fetch(deleteIdeaStatusAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To activate IdeaStatus
const activateIdeaStatus = async (param) => {
  const activateIdeaStatusAPI = APIURL + "ideaStatus/activate";
  return await fetch(activateIdeaStatusAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To deactivate IdeaStatus
const deactivateIdeaStatus = async (param) => {
  const deactivateIdeaStatusAPI = APIURL + "ideaStatus/deactivate";
  return await fetch(deactivateIdeaStatusAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
export {
  getIdeaStatusList,
  getIdeaStatusByID,
  addIdeaStatus,
  updateIdeaStatus,
  deleteIdeaStatus,
  activateIdeaStatus,
  deactivateIdeaStatus,
  getIdeaStatusByIdeaID,
};
