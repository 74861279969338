import axios from "axios";
const { APIURL } = require("global");

//To get State List for Table
const getStateList = async () => {
  const listApi = APIURL + "state/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get active State List for Table
const getActiveStateList = async () => {
  const activeLististApi = APIURL + "state/activeList";
  try {
    let Result = await axios.get(activeLististApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To add State
const addState = async (param) => {
  const createStateAPI = APIURL + "state/create";
  try {
    let Result = await axios.post(createStateAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To update State
const updateState = async (param) => {
  const updateStateAPI = APIURL + "state/update";
  try {
    let Result = await axios.post(updateStateAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete State
const deleteState = async (param) => {
  const deleteStateAPI = APIURL + "state/delete";
  try {
    let Result = await axios.post(deleteStateAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate State
const activateState = async (param) => {
  const activateStateAPI = APIURL + "state/activate";
  try {
    let Result = await axios.post(activateStateAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate State
const deactivateState = async (param) => {
  const deactivateStateAPI = APIURL + "state/deactivate";
  try {
    let Result = await axios.post(deactivateStateAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

const editState = async (param) => {
  const editStateAPI = APIURL + `state/${param}`;
  try {
    let Result = await axios.get(editStateAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getStateList,
  addState,
  editState,
  deleteState,
  deactivateState,
  activateState,
  updateState,getActiveStateList
};
