import React, { useEffect, useState } from "react";
import { GradeTranslationView } from "view/admin/Translation/GradeTranslationView";
import { getGradeTranslationList } from "services/Translation/GradeTranslation.service";

export default function GradeTranslationTable({ color }) {
  const [gradeTranslation, setGradeTranslation] = useState([]);

  useEffect(() => {
    getGradeTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setGradeTranslation(data.Result);
      });
  }, []);

  return <GradeTranslationView gradeTranslation={gradeTranslation}></GradeTranslationView>;
}
