import axios from 'axios';
const { APIURL } = require("global");

//To get userType  list
const getUserTypeList = async () => {
  
  try{

    const listApi = APIURL + "userType/list";
    let Result = await axios.get(listApi);
    return Result.data;

  } catch(error) {
    console.log(error)
  }
 
};

export { getUserTypeList };
