import axios from "axios";
const { APIURL } = require("global");

//To get District List for Table
const getDistrictList = async () => {
  const listApi = APIURL + "district/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get Division list by stateID for Table
const getDivisionListByStateID = async (param) => {
  const getDivisionByStateIDAPI = APIURL + "division/getDivisionListByStateID";
  try {
    let Result = await axios.post(getDivisionByStateIDAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Add District
const addDistrict = async (param) => {
  const addDistrictAPI = APIURL + "district/create";
  try {
    let Result = await axios.post(addDistrictAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update District
const updateDistrict = async (param) => {
  const updateDistrictAPI = APIURL + "district/update";
  try {
    let Result = await axios.post(updateDistrictAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete District
const deleteDistrict = async (param) => {
  const deleteDistrictAPI = APIURL + "district/delete";
  try {
    let Result = await axios.post(deleteDistrictAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate District
const activateDistrict = async (param) => {
  const activateDistrictAPI = APIURL + "district/activate";
  try {
    let Result = await axios.post(activateDistrictAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate District
const deactivateDistrict = async (param) => {
  const deactivateDistrictAPI = APIURL + "district/deactivate";
  try {
    let Result = await axios.post(deactivateDistrictAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

const editDistrict = async (param) => {
  const editDistrictAPI = APIURL + `district/${param}`;
  try {
    let Result = await axios.get(editDistrictAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record District
const getPageWiseRecordDistrict = async (param) => {
  const getPageWiseRecordDistrictAPI = APIURL + "district/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordDistrictAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getDistrictList,
  getDivisionListByStateID,
  addDistrict,
  updateDistrict,
  deleteDistrict,
  activateDistrict,
  deactivateDistrict,
  editDistrict,
  getPageWiseRecordDistrict,
};
