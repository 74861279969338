import React, { useEffect, useState } from "react";
import { SubjectTranslationView } from "view/admin/Translation/SubjectTranslationView";
import { getSubjectTranslationList } from "services/Translation/SubjectTranslation.service";

export default function SubjectTranslationTable({ color }) {
  const [subjectTranslation, setSubjectTranslation] = useState([]);

  useEffect(() => {
    getSubjectTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setSubjectTranslation(data.Result);
      });
  }, []);

  return <SubjectTranslationView subjectTranslation={subjectTranslation}></SubjectTranslationView>;
}
