import axios from "axios";
const { APIURL } = require("global");

//To get SubConceptMaster List for Table
const getSubConceptMasterList = async () => {
  const listApi = APIURL + "subConcept/list";

  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add SubConceptMaster List
const addSubConceptMaster = async (param) => {
  const addSubconceptAPI = APIURL + "SubConcept/create";
  try {
    let Result = await axios.post(addSubconceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Activate SubConceptMaster List
const activateSubConceptMaster = async (param) => {
  const activateSubconceptAPI = APIURL + "SubConcept/activate";
  try {
    let Result = await axios.post(activateSubconceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Deactivate SubConceptMaster List
const deactivateSubConceptMaster = async (param) => {
  const deactivateSubconceptAPI = APIURL + "SubConcept/deactivate";
  try {
    let Result = await axios.post(deactivateSubconceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete SubConceptMaster List
const deleteSubConceptMaster = async (param) => {
  const deleteSubconceptAPI = APIURL + "SubConcept/delete";
  try {
    let Result = await axios.post(deleteSubconceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To update SubConcept
const updateSubConcept = async (param) => {
  const updateSubcConceptAPI = APIURL + "subConcept/update";
  try {
    let Result = await axios.post(updateSubcConceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const editSubConcept = async (param) => {
  const editSubcConceptAPI = APIURL + `subConcept/${param}`;
  try {
    let Result = await axios.get(editSubcConceptAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record SubConcept By Concept
const getPageWiseRecordSubConceptByConcept = async (param) => {
  const getPageWiseRecordSubConceptAPI =
    APIURL + "subconcept/getPageWiseRecordsByConcept";

  try {
    let Result = await axios.post(getPageWiseRecordSubConceptAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getSubConceptMasterList,
  addSubConceptMaster,
  activateSubConceptMaster,
  deactivateSubConceptMaster,
  deleteSubConceptMaster,
  editSubConcept,
  updateSubConcept,
  getPageWiseRecordSubConceptByConcept,
};
