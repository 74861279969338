import axios from "axios";
const { APIURL } = require("global");

//To get Course List for Table
const getCourseList = async () => {
  const listApi = APIURL + "course/list";
  try {
    let Result = await axios.get(listApi);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Course Details by ID
const getCourseByID = async (param) => {
  const getCourseByIDAPI = APIURL + `Course/${param.id}`;

  try {
    let Result = await axios.get(getCourseByIDAPI);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Course List
const addCourse = async (param) => {
  const addCourseAPI = APIURL + "Course/create";

  try {
    let Result = await axios.post(addCourseAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update Course List
const updateCourse = async (param) => {
  const updateCourseAPI = APIURL + "Course/update";
  try {
    let Result = await axios.post(updateCourseAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get BoardList
const getBoardList = async () => {
  const getBoardList = APIURL + "common/boards";
  try {
    let Result = await axios.get(getBoardList);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get GradeList
const getGradeList = async () => {
  const getGradeListAPI = APIURL + "common/Grades";
  try {
    let Result = await axios.get(getGradeListAPI);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get Subject By GradeID
const getSubjectByGradeID = async (param) => {
  const getSubjectByGradeIDAPI =
    APIURL + "subjectGradeMapping/subjectByGradeID";
  try {
    let Result = await axios.post(getSubjectByGradeIDAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Course
const deleteCourse = async (param) => {
  const deleteCourseAPI = APIURL + "Course/delete";

  try {
    let Result = await axios.post(deleteCourseAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Course
const activateCourse = async (param) => {
  const activateCourseAPI = APIURL + "Course/activate";
  try {
    let Result = await axios.post(activateCourseAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Course
const deactivateCourse = async (param) => {
  const deactivateCourseAPI = APIURL + "Course/deactivate";
  try {
    let Result = await axios.post(deactivateCourseAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Course
const getPageWiseRecordCourse = async (param) => {
  const getPageWiseRecordCourseAPI = APIURL + "course/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordCourseAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get course By Keyword
const getcourseByKeyword = async (param) => {
  const getcourseByKeywordAPI = APIURL + "course/courseSearchByKeyword";
  try {
    let Result = await axios.post(getcourseByKeywordAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getCourseList,
  getCourseByID,
  addCourse,
  updateCourse,
  getBoardList,
  getGradeList,
  getSubjectByGradeID,
  deleteCourse,
  activateCourse,
  deactivateCourse,
  getPageWiseRecordCourse,
  getcourseByKeyword,
};
