import React from "react";
import SubConceptMasterView from "view/admin/SubConceptMasterView";
const ListSubConceptMaster = () => {
  return (
    <>
      <SubConceptMasterView />
    </>
  );
};

export default ListSubConceptMaster;
