import axios from "axios";
const { APIURL } = require("global");

//To get Period List for Table
const getPeriodList = async () => {
  const listApi = APIURL + "period/list";

  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Period List for Table
const getPeriodByID = async (param) => {
  const getPeriodByIDApi = APIURL + `period/${param}`;
  try {
    let data = await axios.get(getPeriodByIDApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Period List
const addPeriod = async (param) => {
  const addPeriodAPI = APIURL + "period/create";

  try {
    let Result = await axios.post(addPeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Period List
const updatePeriod = async (param) => {
  const updatePeriodAPI = APIURL + "period/update";

  try {
    let Result = await axios.post(updatePeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Period
const deletePeriod = async (param) => {
  const deletePeriodAPI = APIURL + "period/delete";

  try {
    let Result = await axios.post(deletePeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Period
const activatePeriod = async (param) => {
  const activatePeriodAPI = APIURL + "period/activate";

  try {
    let Result = await axios.post(activatePeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To deactivate Period
const deactivatePeriod = async (param) => {
  const deactivatePeriodAPI = APIURL + "period/deactivate";

  try {
    let Result = await axios.post(deactivatePeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get Period PageWise Record Period
const getPeriodWiseRecordPeriod = async (param) => {
  const getPeriodWiseRecordPeriodAPI =
    APIURL + "period/getPeriodPageWiseRecords";

  try {
    let Result = await axios.post(getPeriodWiseRecordPeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get Period PageWise Record By Chapter
const getPeriodWiseRecordByChapter = async (param) => {
  const getPeriodWiseRecordPeriodAPI =
    APIURL + "period/getPageWiseRecordsByChapter";

  try {
    let Result = await axios.post(getPeriodWiseRecordPeriodAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getPeriodList,
  getPeriodByID,
  addPeriod,
  updatePeriod,
  deletePeriod,
  activatePeriod,
  deactivatePeriod,
  getPeriodWiseRecordPeriod,
  getPeriodWiseRecordByChapter,
};
