import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	getVillageList,
	deleteVillage,
	deactivateVillage,
	activateVillage,
	getVillageByKeyword
} from "services/villagemaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VillageMasterView = () => {
	const [Village, setVillage] = useState("");
	const [searchedValue, SetSearchedValue] = useState("");
	const [searchedResult, SetSearchedResult] = useState("");
	const [status,setStatus] = useState("");

	const navigate = useNavigate();

	useEffect(() => {
		VillageList();
	}, []);

	const VillageList = () => {
		getVillageList()
			.then((data) => {
				setVillage(data.Result);
			});
	};

	const DeleteVillage = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		deleteVillage(Param).then((response) => {
			toast.error(response.Message);

			VillageList();
		});
	};
	
	const ActivateVillage = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		activateVillage(Param).then((response) => {
			toast.success(response.Message);

			VillageList();
		});
	};
	const DeactivateVillage = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		deactivateVillage(Param).then((response) => {
			toast.error(response.Message);

			VillageList();
		});
	};

	const RedirectToEditVillage = async (e) => {
		e.preventDefault();
		navigate("/editvillage", {
			state: e.currentTarget.id,
		});
	};

	const searchByKeyword = (e) => {
		e.preventDefault();
		let Param = {
		  keyword: searchedValue,
		  status :status
		};
	
		getVillageByKeyword(Param).then((response) => {
		  SetSearchedResult(response.Result);
		});
	  };

	  const statusSetUp=(e)=>{
		setStatus(e.target.value);
	   }

	return (
		<>
			<div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
				<div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
					<div className="w-full max-w-full flex-grow flex-1">
						<h3 className="font-semibold text-lg text-black">Filter</h3>
					</div>
					<div>
						<input
							type="text"
							className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
							placeholder="Keyword"
							onChange={(e) => SetSearchedValue(e.target.value)}
						/>

						<select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 "
						 onChange={(value)=>statusSetUp(value)}>
							<option hidden>Status</option>
							<option value="active">Activate</option>
                            <option value="deactive">Deactivate</option>
                            <option value="delete">Delete</option>
						</select>

						<button
							className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
							// ml-48
							type="button"
							onClick={searchByKeyword}
						>
							Filter
						</button>
					</div>
				</div>
				<div className="mb-0 px-4 py-3 border-0 bg-slate-300">
					<div className="flex flex-wrap items-center">
						<div className="w-full max-w-full flex-grow flex-1">
							<h3 className="font-semibold text-lg text-black">
								List of Village
							</h3>
						</div>
						<div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
							<div className=" mt-32 sm:mt-0">
								<button
									className="bg-slate-950	active:bg-lightBlPue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
									type="button"
								>
									<Link to="/addvillage">Add</Link>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="block w-full overflow-x-auto">
					<table className="items-center w-full bg-transparent border-collapse">
						<thead>
							<tr className="bg-blue-950">
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Village Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Village Code
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Native Language Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Status
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Action
								</th>
							</tr>
						</thead>

						<tbody>
						{searchedResult
						? searchedResult &&
								searchedResult.map((village) => {
									return (
										<tr key={village.VillageID}>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.VillageName}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.VillageCode}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.NativeLanguageName}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.CStatus}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
												{village.CStatus === "Active" && (
													<i
														id={village.VillageID}
														className="fas fa-edit"
														onClick={RedirectToEditVillage}
													></i>
												)}
												{(village.CStatus === "Active" ) && (
													<i
														id={village.VillageID}
														className="fas fa-trash text-red-600"
														onClick={(e) => DeleteVillage(e)}
													></i>
												)}
												{(village.CStatus === "Deactive" ||
													village.CStatus === "Delete") && (
													<i
														id={village.VillageID}
														className="fas fa-check-circle"
														onClick={(e) => ActivateVillage(e)}
													></i>
												)}
												{(village.CStatus === "Active") && (
													<i
														id={village.VillageID}
														className="fas fa-ban text-red-600"
														onClick={(e) => DeactivateVillage(e)}
													></i>
												)}
											</td>
										</tr>
									);
								})
						    :  Village &&
								Village.map((village) => {
									return (
										<tr key={village.VillageID}>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.VillageName}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.VillageCode}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.NativeLanguageName}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{village.CStatus}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
												{village.CStatus === "Active" && (
													<i
														id={village.VillageID}
														className="fas fa-edit"
														onClick={RedirectToEditVillage}
													></i>
												)}
												{(village.CStatus === "Active" ) && (
													<i
														id={village.VillageID}
														className="fas fa-trash text-red-600"
														onClick={(e) => DeleteVillage(e)}
													></i>
												)}
												{(village.CStatus === "Deactive" ||
													village.CStatus === "Delete") && (
													<i
														id={village.VillageID}
														className="fas fa-check-circle"
														onClick={(e) => ActivateVillage(e)}
													></i>
												)}
												{(village.CStatus === "Active") && (
													<i
														id={village.VillageID}
														className="fas fa-ban text-red-600"
														onClick={(e) => DeactivateVillage(e)}
													></i>
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
					<ToastContainer />
				</div>
			</div>
		</>
	);
};

export default VillageMasterView;
