import React, { useEffect, useState } from "react";
import { PageContentView } from "view/admin/PageContentView";
import { getPageContentByPageID } from "services/pageContent.service";
import { useLocation } from "react-router";

export default function ListPageContentMaster({ color }) {
  const [pageContents, setPageContents] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getPageContentByPageID(location.state)
      .then((response) => response.json())
      .then((data) => {
        setPageContents(data.Result);
      });
  }, []);

  return <PageContentView pageContents={pageContents}></PageContentView>;
}
