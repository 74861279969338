import React from "react";
import PeriodMasterView from "view/admin/PeriodMasterView";
const ListPeriodMaster = () => {
  return (
    <>
      <PeriodMasterView />
    </>
  );
};

export default ListPeriodMaster;
