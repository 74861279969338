import axios from "axios";
const { APIURL } = require("global");

//To get StatusMaster List for Table
const getStatusMasterList = async () => {
  const listApi = APIURL + "status/list";
  try {
    let Result = await axios.get(listApi);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get StatusMaster List for Table
const getListByID = async (param) => {
  const listApi = APIURL + `status/${param}`;
  try {
    let Result = await axios.post(listApi);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add StatusMaster List
const addStatusMaster = async (param) => {
  const addStatusAPI = APIURL + "status/create";
  try {
    let Result = await axios.post(addStatusAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To update StatusMaster List
const updateStatusMaster = async (param) => {
  const updateStatusAPI = APIURL + "status/update";
  try {
    let Result = await axios.post(updateStatusAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
export {
  getStatusMasterList,
  addStatusMaster,
  updateStatusMaster,
  getListByID,
};
