import React from 'react'
import CourseContentView from 'view/admin/CourseContentView'
const ListCourseContentMaster = () => {
  return (
   <>
   
   <CourseContentView/>
   
   </>
  )
}

export default ListCourseContentMaster