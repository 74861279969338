import React, { useEffect, useState } from "react";
import { PageImageView } from "view/admin/PageImageView";
import { getPageImageByPageID } from "services/pageImage.service";
import { useLocation } from "react-router";

export default function ListPageImageMaster({ color }) {
  const [pageImages, setPageImages] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getPageImageByPageID(location.state)
      .then((response) => response.json())
      .then((data) => {
        setPageImages(data.Result);
      });
  }, []);

  return <PageImageView pageImages={pageImages}></PageImageView>;
}
