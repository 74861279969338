import axios from "axios";
const { APIURL } = require("global");

//To get Concept List for Table
const getConceptList = async () => {
  const listApi = APIURL + "concept/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To add Concept
const addConcept = async (param) => {
  const createConceptAPI = APIURL + "concept/create";
  try {
    let Result = await axios.post(createConceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To delete Concept
const deleteConcept = async (param) => {
  const deleteConceptAPI = APIURL + "concept/delete";
  try {
    let Result = await axios.post(deleteConceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Concept
const activateConcept = async (param) => {
  const activateConceptAPI = APIURL + "concept/activate";
  try {
    let Result = await axios.post(activateConceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Concept
const deactivateConcept = async (param) => {
  const deactivateConceptAPI = APIURL + "concept/deactivate";
  try {
    let Result = await axios.post(deactivateConceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To update Concept
const updateConcept = async (param) => {
  const updateConceptAPI = APIURL + "concept/update";
  try {
    let Result = await axios.post(updateConceptAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const editConcept = async (param) => {
  const editConceptAPI = APIURL + `concept/${param}`;
  try {
    let Result = await axios.get(editConceptAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Concept
const getPageWiseRecordConcept = async (param) => {
  const getPageWiseRecordConceptAPI = APIURL + "concept/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordConceptAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Concept
const getPageWiseRecordConceptByLearningOutcome = async (param) => {
  const getPageWiseRecordConceptAPI =
    APIURL + "concept/getPageWiseRecordsByLearningOutcome";

  try {
    let Result = await axios.post(getPageWiseRecordConceptAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
export {
  getConceptList,
  addConcept,
  deleteConcept,
  deactivateConcept,
  activateConcept,
  updateConcept,
  editConcept,
  getPageWiseRecordConcept,
  getPageWiseRecordConceptByLearningOutcome,
};
