import React, { useState, useEffect, useTransition } from "react";
import {
  getPageWiseRecordCluster,
  activateCluster,
  deactivateCluster,
  deleteCluster,
} from "services/clustermaster.service";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "components/admin/Admin-Pagination/pagination";

const ClusterMasterView = () => {
  const [Cluster, setCluster] = useState("");
  const [counts, setCounts] = useState("");
  const [startLimit, setStartLimit] = useState(0);
  const [endLimit, setEndLimit] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    ClusterList();
  }, []);

  const ClusterList = () => {
    let obj = {
      offset: startLimit,
      records: endLimit,
    };
    getPageWiseRecordCluster(obj).then((data) => {
      setCluster(data.Result);
      setCounts(data.Count);
    });
  };

  const DeleteCluster = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deleteCluster(Param).then((response) => {
      toast.error(response.Message);

      ClusterList();
    });
  };
  const DeactivateCluster = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    deactivateCluster(Param).then((response) => {
      toast.error(response.Message);
      ClusterList();
    });
  };
  const ActivateCluster = async (e) => {
    e.preventDefault();
    let Param = {
      id: e.target.id,
    };
    activateCluster(Param).then((response) => {
      toast.success(response.Message);

      ClusterList();
    });
  };

  const RedirectToEditCluster = (e) => {
    e.preventDefault();
    navigate("/editcluster", {
      state: e.currentTarget.id,
    });
  };

  return (
    <>
      <>
        <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
          <div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">Filter</h3>
            </div>
            <div>
              <input
                type="text"
                className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
                placeholder="Keyword"
              />

              <select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 ">
                <option hidden>Status</option>
                <option>Activate</option>
                <option>Deactivate</option>
                <option>Delete</option>
              </select>

              <button
                className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
                // ml-48
                type="button"
              >
                Filter
              </button>
            </div>
          </div>
          <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
            <div className="flex flex-wrap items-center">
              <div className="w-full max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-lg text-black">
                  List of Cluster
                </h3>
              </div>
              <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                <div className=" mt-32 sm:mt-0">
                  <button
                    className="bg-slate-950	active:bg-lightBlPue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <Link to="/addcluster">Add</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="block w-full overflow-x-auto">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr className="bg-blue-950">
                  <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                    Cluster Name
                  </th>
                  <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                    Cluster Code
                  </th>
                  <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                    Native Language Name
                  </th>
                  <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                    Status
                  </th>
                  <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {Cluster &&
                  Cluster.map((cluster) => {
                    return (
                      <tr key={cluster.ClusterID}>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {cluster.ClusterName}
                        </td>

                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {cluster.ClusterCode}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {cluster.NativeLanguageName}
                        </td>

                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {cluster.CStatus}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
                          {cluster.CStatus === "Active" && (
                            <i
                              id={cluster.ClusterID}
                              className="fas fa-edit"
                              onClick={RedirectToEditCluster}
                            ></i>
                          )}
                          {(cluster.CStatus === "Active" ) && (
                            <i
                              id={cluster.ClusterID}
                              className="fas fa-trash text-red-600"
                              onClick={(e) => DeleteCluster(e)}
                            ></i>
                          )}
                          {(cluster.CStatus === "Deactive" ||
                            cluster.CStatus === "Delete") && (
                            <i
                              id={cluster.ClusterID}
                              className="fas fa-check-circle"
                              onClick={(e) => ActivateCluster(e)}
                            ></i>
                          )}
                          {(cluster.CStatus === "Active" ) && (
                            <i
                              id={cluster.ClusterID}
                              className="fas fa-ban text-red-600"
                              onClick={(e) => DeactivateCluster(e)}
                            ></i>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <ToastContainer />
            <Pagination
              data={{
                counts,
                startLimit,
                setStartLimit,
                endLimit,
                setEndLimit,
              }}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default ClusterMasterView;
