import React, { useState, useEffect } from "react";
import {
  updateConfiguration,
  editConfiguration,
} from "services/configuration.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditConfigurationMaster() {
  const [keyName, setKeyName] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [defaultValue, setDefaultValue] = useState("");

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    editConfiguration(location.state)
      .then((response) => response.json())
      .then((data) => {
        setKeyName(data.Result.KeyName);
        setMinValue(data.Result.MinValue);
        setMaxValue(data.Result.MaxValue);
        setDefaultValue(data.Result.DefaultValue);
      });
  }, []);

  const UpdateConfiguration = (e) => {
    e.preventDefault();
    let Param = {
      configurationID: location.state,
      minValue,
      maxValue,
      defaultValue,
      keyName,
      createBy: AdminID,
    };

    updateConfiguration(Param).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/configuration");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit Configuration
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  <form onSubmit={UpdateConfiguration}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-board-name"
                      >
                        KeyName
                      </label>
                      <select
                        className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        name="keyName"
                        value={keyName}
                        onChange={(e) => {
                          setKeyName(e.target.value);
                        }}
                      >
                        <option hidden>KeyName</option>
                        <option value="Idea Submission">Idea Submission</option>
                        <option value="Innovative Pathshala">
                          Innovative Pathshala
                        </option>
                        <option value="Competence Best Education">
                          Competence Best Education
                        </option>
                        <option value="Certified Course">
                          Certified Courses
                        </option>
                        <option value="Concept Based Lesson Plan">
                          Concept Based Lesson Plan
                        </option>
                        <option value="Competence Best Smart Learning Project">
                          Competence Best Smart Learning Project
                        </option>
                        <option value="Competence Best Learning Project">
                          Competence Best Learning Project
                        </option>
                      </select>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Min Value
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Min-Value"
                        name="minValue"
                        value={minValue}
                        onChange={(e) => {
                          setMinValue(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Max Value
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="maxValue"
                        placeholder="Max-Value"
                        value={maxValue}
                        onChange={(e) => {
                          setMaxValue(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Default Value
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="defaultValue"
                        value={defaultValue}
                        placeholder="Default-Value"
                        onChange={(e) => {
                          setDefaultValue(e.target.value);
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
