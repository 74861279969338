import axios from "axios";
const { APIURL } = require("global");

//To get Country List for Table
const getCountryList = async () => {
  const CountryListApi = APIURL + "country/list";
  try {
    let Result = await axios.get(CountryListApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To get active Country List for Table
const getActiveCountryList = async () => {
  const ActiveCountryListApi = APIURL + "country/activeList";
  try {
    let Result = await axios.get(ActiveCountryListApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Country
const addCountry = async (param) => {
  const addCountryAPI = APIURL + "country/create";
  try {
    let Result = await axios.post(addCountryAPI, param);
    console.log(Result);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//Edit country

const editCountry = async (param) => {
  const editCountryAPI = APIURL + `country/${param}`;
  try {
    let Result = await axios.get(editCountryAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update Country
const updateCountry = async (param) => {
  const updateCountryAPI = APIURL + "country/update";
  try {
    let Result = await axios.post(updateCountryAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Country
const deleteCountry = async (param) => {
  const deleteCountryAPI = APIURL + "country/delete";
  try {
    let Result = await axios.post(deleteCountryAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Country
const activateCountry = async (param) => {
  const activateCountryAPI = APIURL + "country/activate";
  try {
    let Result = await axios.post(activateCountryAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To deactivate Country
const deactivateCountry = async (param) => {
  const deactivateCountryAPI = APIURL + "country/deactivate";
  try {
    let Result = await axios.post(deactivateCountryAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Country
const getPageWiseRecordCountry = async (param) => {
  const getPageWiseRecordCountryAPI = APIURL + "country/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordCountryAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
	getCountryList,
	activateCountry,
	deactivateCountry,
	deleteCountry,
	addCountry,
	getPageWiseRecordCountry,
	editCountry,
	updateCountry,getActiveCountryList
};
