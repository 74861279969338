const { APIURL } = require("global");

const getClusterTranslationList = async (param) => {
  const clusterTranslationList = APIURL + "admin/login";
  return await fetch(clusterTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterClusterTranslation = async (param) => {
  const getFilterCluster = APIURL + "admin/login";
  return await fetch(getFilterCluster, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getClusterTranslationByID = async (data) => {
  const getClusterTranslationByIDApi =
    APIURL + `clusterTranslation/${data.ClusterTranslationID}`;
  return await fetch(getClusterTranslationByIDApi);
};

const UpdateClusterTranslation = async (param) => {
    const updateClusterTranslation = APIURL + "admin/login";
    return await fetch(updateClusterTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addClusterMasterTranslation = async (param) => {
    const addClusterTranslation = APIURL + "admin/login";
    return await fetch(addClusterTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getClusterTranslationList,
  getFilterClusterTranslation,
  getClusterTranslationByID,
  UpdateClusterTranslation,
  addClusterMasterTranslation,
};
