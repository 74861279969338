import React, { useState, useEffect } from "react";
import { getGradeMasterList } from "services/grademaster.service";
import { getLearningOutcomeListBySubjectID } from "services/learningOutcomeMaster.service";
import { useNavigate } from "react-router";
import { GetSubjectListByGradeID } from "services/subjectgrademapping.service";
import { addConcept } from "services/conceptmaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddConceptMaster() {
  const [Title, setTitle] = useState("");
  const [Grade, setGrade] = useState("");
  const [Subject, setSubject] = useState("");
  const [LearningOutcome, setLearningOutcome] = useState("");
  const [LearningOutcomeID, setLearningOutcomeID] = useState("");
  const [Description, setDescription] = useState("");

  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    GradeList();
  }, []);

  const GradeList = () => {
    getGradeMasterList()
      .then((data) => setGrade(data.Result));
  };

  const SubjectListByGradeID = (e) => {
    const Param = {
      gradeID: e.target.value,
    };
    GetSubjectListByGradeID(Param).then((response) => {
      setSubject(response.Result);
    });
  };
  const LearningOutcomeBySubjectID = (e) => {
    const Param = {
      subjectID: e.target.value,
    };
    getLearningOutcomeListBySubjectID(Param).then((response) => {
      setLearningOutcome(response.Result);
    });
  };

  const AddConcept = (e) => {
    e.preventDefault();
    let Param = {
      title: Title,
      learningOutcomeID: LearningOutcomeID,
      description: Description,
      createBy: AdminID,
    };
    addConcept(Param).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/concept");
        }, 1500);
      } else {
        toast.error("Please fill required fields");
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Concept</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddConcept}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Grade
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="gradeID"
                          onChange={SubjectListByGradeID}
                        >
                          <option hidden>Grade List</option>
                          {Grade &&
                            Grade.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="subjectID"
                          onChange={LearningOutcomeBySubjectID}
                        >
                          <option hidden>Subject List</option>
                          {Subject &&
                            Subject.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Learning Outcome
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="learningoutcomeID"
                          onChange={(e) => {
                            setLearningOutcomeID(e.target.value);
                          }}
                        >
                          <option hidden>Learning Outcome List</option>
                          {LearningOutcome &&
                            LearningOutcome.map((item, index) => {
                              return (
                                <option
                                  value={item.LearningOutcomeID}
                                  key={index}
                                >
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Concept Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="concepttitle"
                        placeholder="Concept Title"
                        autoFocus
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Description
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="description"
                        placeholder="Description"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
