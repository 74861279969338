import axios from "axios";
const { APIURL } = require("global");

//To get LanguageMaster List for Table
const getLanguageMasterList = async () => {
  const listApi = APIURL + "languages/list";

  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//For get ToLanguageID by using FromLanguageID
const getToLanguageIDByFromLanguageID = async (languageData) => {
  const getToLanguageIDByFromLanguageIDAPI =
    APIURL + "languages/getToLanguageListByFromLanguageID";
  try {
    let Result = await axios.post(
      getToLanguageIDByFromLanguageIDAPI,
      languageData
    );
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//add language
const addLanguage = async (param) => {
  const addLanguageAPI = APIURL + "languages/create";
  try {
    let Result = await axios.post(addLanguageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//activate language
const activateLanguage = async (param) => {
  const activateLanguageAPI = APIURL + "languages/activate";

  try {
    let Result = await axios.post(activateLanguageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//deactivate language
const deactivateLanguage = async (param) => {
  const deactivateLanguageAPI = APIURL + "languages/deactivate";
  try {
    let Result = await axios.post(deactivateLanguageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//delete language
const deleteLanguage = async (param) => {
  const deleteLanguageAPI = APIURL + "languages/delete";
  try {
    let Result = await axios.post(deleteLanguageAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//update language
const updateLanguage = async (param) => {
  const updateLanguageAPI = APIURL + "languages/update";
  try {
    let Result = await axios.post(updateLanguageAPI, param);
    console.log("languageservice", Result);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const editLanguage = async (param) => {
  const editLanguageAPI = APIURL + `languages/${param}`;
  try {
    let Result = await axios.get(editLanguageAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To add priority
const addPriority = async (param) => {
  const addPriorityAPI = APIURL + "languages/addPriority";
  try {
    let Result = await axios.post(addPriorityAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

const checkLanguageByLanguageName = async (param) => {
  try {
    const URL = APIURL + "languages/checkLanguageByLanguageName";
    return await axios.post(`${URL}`, param);
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Language
const getPageWiseRecordLanguage = async (param) => {
  const getPageWiseRecordLanguageAPI = APIURL + "languages/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordLanguageAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getLanguageMasterList,
  getToLanguageIDByFromLanguageID,
  addLanguage,
  activateLanguage,
  deactivateLanguage,
  deleteLanguage,
  editLanguage,
  updateLanguage,
  addPriority,
  checkLanguageByLanguageName,
  getPageWiseRecordLanguage,
};
