import React from "react";
import TlmItemMasterView from "view/admin/TlmItemMasterView";
const ListTlmItemMaster = () => {
  return (
    <div>
      <TlmItemMasterView> </TlmItemMasterView>
    </div>
  );
};

export default ListTlmItemMaster;
