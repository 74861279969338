import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { addProjectActivity } from "services/projectActivity.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProjectList } from "services/projectmaster.service";
import { getLanguageMasterList } from "services/languages.service";
import ReactQuill from "react-quill";

export default function AddProjectActivityMaster() {
  const [projectActivityData, setProjectActivityData] = useState({
    createBy: JSON.parse(localStorage.getItem("user")).AdminID,
  });
  const [projectList, setProjectList] = useState("");
  const [languageList, setLanguageList] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  //   let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    getProjectList()
      .then((data) => data.json())
      .then((value) => {
        setProjectList(value.Result);
      });

    getLanguageMasterList()
      .then((value) => {
        setLanguageList(value.Result);
      });
  }, []);

  const AddProjectActivity = (e) => {
    e.preventDefault();
    addProjectActivity(projectActivityData).then((response) => {
      if ((response.Status === "SUCCESS" || response.Status === "Success") && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/projectactivity");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  const handleChange = (e) => {
    setProjectActivityData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    if (ext) {
      if (file) {
        setProjectActivityData((prev) => ({ ...prev, attachmentType: ext }));
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      }
    } else {
      return false;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    // setAttachment(btoa(binaryString));
    setProjectActivityData((prev) => ({
      ...prev,
      attachment: btoa(binaryString),
    }));
    setImageUrl(setDp);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Project Activity
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddProjectActivity}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Project
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="chapterID"
                          onChange={handleChange}
                        >
                          <option hidden>Project List</option>
                          {projectList &&
                            projectList?.map((item, index) => {
                              return (
                                <option value={item.ProjectID} key={index}>
                                  {item.Name}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="languageID"
                          onChange={handleChange}
                        >
                          <option hidden>Language List</option>
                          {languageList &&
                            languageList?.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="title"
                        placeholder="ProjectActivity-Name"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Html Content
                      </label>
                      <ReactQuill
                        theme="snow"
                        name="htmlContent"
                        required={Boolean(projectActivityData.attachment) ? false : true}
                        onChange={(e) => {
                          setProjectActivityData((prev) => ({
                            ...prev,
                            htmlContent: e,
                          }));
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        required={Boolean(projectActivityData.htmlContent) ? false : true}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="attachment"
                        placeholder="ProjectActivity-Name"
                        onChange={handleImage}
                      />
                    </div>
                    <img src={imageUrl} width="100" alt="" />

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
