import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function InnovativePathshalaModal({ showModal }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={showModal.showModel} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={showModal.setShowModel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div> */}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left [&_p]:border-b-[1px] [&_p]:w-full w-full">
                    <div>
                        <h1 className="text-[25px] text-[#000] font-semibold mb-2">Innovative Pathshala</h1>
                    </div>
                      {showModal.tempData.map((item) => {
                        return (
                          <>
                            <p>
                              Title:{" "}
                              {item.Title !== "" ||
                              item.Title !== undefined ||
                              item.Title !== null
                                ? item.Title
                                : "No Data Found"}
                            </p>
                            <p>
                              Content:{" "}
                              {item.Content !== "" ||
                              item.Content !== undefined ||
                              item.Content !== null
                                ? item.Content
                                : "No Data Found"}
                            </p>
                            <p>ViewCount: {item.ViewCount ?? 0}</p>
                            <p>
                              Chapter Week:{" "}
                              {item.CourseSubContent !== "" ||
                              item.CourseSubContent !== undefined ||
                              item.CourseSubContent !== null
                                ? item.CourseSubContent
                                : "No Data Found"}
                            </p>
                            <p>
                              {item.Title !== "" ||
                              item.Title !== undefined ||
                              item.Title !== null
                                ? item.Title
                                : "No Data Found"}
                            </p>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-[#0d6efd] text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-[#0d6efd] sm:mt-0 sm:w-auto"
                    onClick={() => showModal.setShowModel(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
