import React, { useEffect, useState } from "react";
import {StatusMasterView } from "view/admin/StatusMasterView"
import { getStatusMasterList } from "services/status.service";

export default function ListStatusMaster() {
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    getStatusMasterList()
      .then((response) => response.json())
      .then((data) => {
        setStatusData(data.Result);
      });
  }, []);

  return <StatusMasterView status={statusData}></StatusMasterView>;
}
