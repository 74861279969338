import React, { useEffect, useState } from "react";
import { SchoolTranslationView } from "view/admin/Translation/SchoolTranslationView";
import { getSchoolTranslationList } from "services/Translation/SchoolTranslation.service";

export default function SchoolTranslationTable({ color }) {
  const [schoolTranslation, setSchoolTranslation] = useState([]);

  useEffect(() => {
    getSchoolTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setSchoolTranslation(data.Result);
      });
  }, []);

  return <SchoolTranslationView schoolTranslation={schoolTranslation}></SchoolTranslationView>;
}
