import React, { useEffect, useState } from "react";
import { VillageTranslationView } from "view/admin/Translation/VillageTranslationView";
import { getVillageTranslationList } from "services/Translation/VillageTranslation.service";

export default function VillageTranslationTable({ color }) {
  const [villageTranslation, setVillageTranslation] = useState([]);

  useEffect(() => {
    getVillageTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setVillageTranslation(data.Result);
      });
  }, []);

  return <VillageTranslationView villageTranslation={villageTranslation}></VillageTranslationView>;
}
