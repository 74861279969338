import axios from "axios";
const { APIURL } = require("global");

//to get board list for table

const getBoardList = async () => {
  const listApi = APIURL + "board/list";
  try {
    let data = await axios.get(listApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Board List for Table
const getBoardByID = async (param) => {
  const getBoardByIDApi = APIURL + `board/${param.BoardID}`;
  try {
    let data = await axios.get(getBoardByIDApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Board List
const addBoard = async (param) => {
  const addBoardAPI = APIURL + "board/create";

  try {
    let Result = await axios.post(addBoardAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Board List
const updateBoard = async (param) => {
  const updateBoardAPI = APIURL + "board/update";
  try {
    let Result = await axios.post(updateBoardAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

// to delete board
const deleteBoard = async (param) => {
  const deleteBoardAPI = APIURL + "Board/delete";

  try {
    let Result = await axios.post(deleteBoardAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Board
const activateBoard = async (param) => {
  const activateBoardAPI = APIURL + "Board/activate";

  try {
    let Result = await axios.post(activateBoardAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Board
const deactivateBoard = async (param) => {
  const deactivateBoardAPI = APIURL + "Board/deactivate";

  try {
    let Result = await axios.post(deactivateBoardAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Board
const getPageWiseRecordBoard = async (param) => {
  const getPageWiseRecordBoardAPI = APIURL + "board/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordBoardAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get board By Keyword
const getboardByKeyword = async (param) => {
  const getboardByKeywordAPI = APIURL + "board/boardSearchByKeyword";

  try {
    let Result = await axios.post(getboardByKeywordAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getBoardList,
  getBoardByID,
  addBoard,
  updateBoard,
  activateBoard,
  deactivateBoard,
  deleteBoard,
  getPageWiseRecordBoard,
  getboardByKeyword,
};
