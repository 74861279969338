import React, { useEffect, useState } from "react";
import { StateTranslationView } from "view/admin/Translation/StateTranslationView";
import { getStateTranslationList } from "services/Translation/StateTranslation.service";

export default function StateTranslationTable({ color }) {
  const [stateTranslation, setStateTranslation] = useState([]);

  useEffect(() => {
    getStateTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setStateTranslation(data.Result);
      });
  }, []);

  return <StateTranslationView stateTranslation={stateTranslation}></StateTranslationView>;
}
