import React, { useEffect, useState } from "react";
import { ClusterTranslationView } from "view/admin/Translation/ClusterTranslationView";
import { getClusterTranslationList } from "services/Translation/ClusterTranslation.service";

export default function ClusterTranslationTable({ color }) {
  const [clusterTranslation, setClusterTranslation] = useState([]);

  useEffect(() => {
    getClusterTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setClusterTranslation(data.Result);
      });
  }, []);

  return <ClusterTranslationView clusterTranslation={clusterTranslation}></ClusterTranslationView>;
}
