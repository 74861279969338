const { APIURL } = require("global");

// eslint-disable-next-line no-undef
const adminLogIn = async (param) => {
  const logInApi = APIURL + "admin/login";
  return await fetch(logInApi, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const adminForgotPassword = async (param) => {
  const forGotPasswordApi = APIURL + "admin/forgotPassword";
  return await fetch(forGotPasswordApi, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
      return err;
    });
};
const adminResetPassword = async (param) => {
  const resetPasswordApi = APIURL + "admin/resetPassword";
  return await fetch(resetPasswordApi, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
export { adminLogIn, adminForgotPassword, adminResetPassword };
