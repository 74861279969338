import React from 'react'
import ProjectMasterView from 'view/admin/ProjectMasterView'
const ListProjectMaster = () => {
  return (
    <>
    <ProjectMasterView/>
    </>
  )
}

export default ListProjectMaster
