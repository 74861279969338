import React, { useState, useEffect } from "react";
import { getBoardList } from "services/boardmaster.service";
import { getGradeMasterList } from "services/grademaster.service";
import { getLanguageMasterList } from "services/languages.service";
import { getSubjectByGradeID } from "services/coursemaster.service";
import { useNavigate } from "react-router";
import { addChapter } from "services/chaptermaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeyPressForAlphabets } from "globalKeyPressValidation";

const AddChapterMaster = () => {
  const [Board, setBoard] = useState([]);
  const [BoardID, setBoardID] = useState("");
  const [Grade, setGrade] = useState([]);
  const [GradeID, setGradeID] = useState("");
  const [Subject, setSubject] = useState([]);
  const [SubjectID, setSubjectID] = useState("");
  const [Language, setLanguage] = useState([]);
  const [LanguageID, setLanguageID] = useState("");
  const [Name, setName] = useState("");

  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();

  useEffect(() => {
    BoardList();
    GradeList();
    LanguageList();
  }, []);

  const BoardList = () => {
    getBoardList().then((data) => {
      setBoard(data.Result);
    });
  };

  const GradeList = () => {
    getGradeMasterList().then((data) => {
      setGrade(data.Result);
    });
  };

  const SubjectListByGradeID = (e) => {
    setGradeID(e.target.value);
    let obj = {
      gradeID: e.target.value,
    };
    getSubjectByGradeID(obj).then((result) => {
      setSubject(result.Result);
    });
  };

  const LanguageList = () => {
    getLanguageMasterList().then((data) => {
      setLanguage(data.Result);
    });
  };

  const AddChapter = (e) => {
    e.preventDefault();
    let Param = {
      boardID: BoardID,
      gradeID: GradeID,
      subjectID: SubjectID,
      languageID: LanguageID,
      name: Name,
      createBy: AdminID,
    };
    addChapter(Param).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/chapter");
        }, 1500);
      } else if (response.Status === "Error") {
        toast.error(response.Message);
      } else {
        toast.error("Please fill the required fields");
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Chapter</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddChapter}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Board
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden>Board List</option>
                          {Board &&
                            Board.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}{" "}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Grade
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={SubjectListByGradeID}
                        >
                          <option hidden>Grade List</option>
                          {Grade &&
                            Grade.map((item, index) => {
                              return (
                                <option value={item.GradeID} key={index}>
                                  {item.GradeName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setSubjectID(e.target.value);
                          }}
                        >
                          <option hidden>Subject List</option>
                          {Subject &&
                            Subject.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          required
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setLanguageID(e.target.value);
                          }}
                        >
                          <option hidden>Language List</option>
                          {Language &&
                            Language.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        onKeyDown={(e) => KeyPressForAlphabets(e)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddChapterMaster;
