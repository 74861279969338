import axios from "axios";
const { APIURL } = require("global");

//To get Tlm Item List for Table
const getTlmItemList = async () => {
  const listApi = APIURL + "tlmItem/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get TLMItem List ByID for Table
const getTLMItemByID = async (param) => {
  const getTLMItemByIDApi = APIURL + `tlmItem/${param.tlmItemID}`;
  try {
    let data = await axios.get(getTLMItemByIDApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add TLM Item
const addTLMItem = async (param) => {
  const addTLMItemAPI = APIURL + "tlmItem/save";

  try {
    let Result = await axios.post(addTLMItemAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Activate TLM Item
const activateTLMItem = async (param) => {
  const activateTLMItemAPI = APIURL + "tlmItem/activate";

  try {
    let Result = await axios.post(activateTLMItemAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Deactivate TLM Item
const deactivateTLMItem = async (param) => {
  const deactivateTLMItemAPI = APIURL + "tlmItem/deactivate";

  try {
    let Result = await axios.post(deactivateTLMItemAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete TLM Item
const deleteTLMItem = async (param) => {
  const deleteTLMItemAPI = APIURL + "tlmItem/delete";

  try {
    let Result = await axios.post(deleteTLMItemAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update TLM Item
const updateTLMItem = async (param) => {
  const updateTLMItemAPI = APIURL + "tlmItem/update";

  try {
    let Result = await axios.post(updateTLMItemAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getTlmItemList,
  getTLMItemByID,
  addTLMItem,
  activateTLMItem,
  deactivateTLMItem,
  deleteTLMItem,
  updateTLMItem,
};
