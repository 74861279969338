const { APIURL } = require("global");

const getSubjectTranslationList = async (param) => {
  const subjectTranslationList = APIURL + "admin/login";
  return await fetch(subjectTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterSubjectTranslation = async (param) => {
  const getFilterSubject = APIURL + "admin/login";
  return await fetch(getFilterSubject, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getSubjectTranslationByID = async (data) => {
  const getSubjectTranslationByIDApi =
    APIURL + `subjectTranslation/${data.SubjectTranslationID}`;
  return await fetch(getSubjectTranslationByIDApi);
};

const UpdateSubjectTranslation = async (param) => {
    const updateSubjectTranslation = APIURL + "admin/login";
    return await fetch(updateSubjectTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addSubjectMasterTranslation = async (param) => {
    const addSubjectTranslation = APIURL + "admin/login";
    return await fetch(addSubjectTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getSubjectTranslationList,
  getFilterSubjectTranslation,
  getSubjectTranslationByID,
  UpdateSubjectTranslation,
  addSubjectMasterTranslation,
};
