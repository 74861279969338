import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	getPageTranslationList,
	getFilterPageTranslation,
} from "services/Translation/PageTranslation.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PageTranslationView = ({ PageTranslation }) => {
	const [pageTranslation, setPageTranslation] = useState(PageTranslation);
	const navigate = useNavigate();
	useEffect(() => {
		setPageTranslation(PageTranslation);
	}, [PageTranslation]);

	const RedirectToEditPageTranslation = async (e) => {
		e.preventDefault();
		navigate("/editpage", {
			state: e.currentTarget.id,
		});
	};

	const getFilterPageTranslationData = async (e) => {
		e.preventDefault();
		let pageTranslationData = {
			id: e.target.id,
		};
		getFilterPageTranslation(pageTranslationData)
			.then((response) => response.json())
			.then((data) => {
				setPageTranslation(data.Result);
			});
	};
	return (
		<>
			<div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
				<div className="mb-0 px-4 py-3 border-0 bg-slate-300">
					<div className="flex flex-wrap items-center">
						<div className="w-full max-w-full flex-grow flex-1">
							<h3 className="font-semibold text-lg text-black">
								List of Translation
							</h3>
						</div>
						<div className="w-full">
							<div className="mx-4 my-2">
								<label className="font-semibold flex flex-col w-1/4">
									Language
									<select className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 ">
										<option hidden>Language</option>
										{/* {La &&
                            Country?.map((item, index) => {
                              return (
                                <option value={item.CountryID} key={index}>
                                  {item.CountryName}
                                </option>
                              );
                            })} */}
									</select>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className="block w-full overflow-x-auto">
					{/* Projects table */}
					<table className="items-center w-full bg-transparent border-collapse">
						<thead>
							<tr className="bg-blue-950">
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Page Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Action
								</th>
							</tr>
						</thead>

						<tbody>
							{pageTranslation?.map((page) => {
								return (
									<tr key={page.PageID}>
										<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
											{page.PageName}
										</td>
										<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
											<i
												id={page.PageTranslationID}
												class="fas fa-edit"
												onClick={RedirectToEditPageTranslation}
											></i>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>{" "}
					<ToastContainer />
				</div>
			</div>
		</>
	);
};
