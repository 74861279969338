const { APIURL } = require("global");

// eslint-disable-next-line no-undef
//To get Page List for Table
const getPageContentList = async () => {
  const listApi = APIURL + "pagecontent/list";
  return await fetch(listApi);
};

//To get Page List for Table
const getPageContentByID = async (param) => {
  const getPageByIDApi = APIURL + `pagecontent/${param}`;
  return await fetch(getPageByIDApi);
};

//To get Page List for Table
const getPageContentByPageID = async (param) => {
  const getPageByIDApi = APIURL + `pagecontent/pageContentByPageID/${param}`;
  return await fetch(getPageByIDApi);
};

//To Add Page List
const addPageContent = async (param) => {
  const addPageAPI = APIURL + "pagecontent/create";
  return await fetch(addPageAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Update Page List
const updatePageContent = async (param) => {
  const updatePageAPI = APIURL + "pagecontent/update";
  return await fetch(updatePageAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

//To Delete Page
const deletePageContent = async (param) => {
  const deletePageAPI = APIURL + "pagecontent/delete";
  return await fetch(deletePageAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To activate Page
const activatePageContent = async (param) => {
  const activatePageAPI = APIURL + "pagecontent/activate";
  return await fetch(activatePageAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
//To deactivate Page
const deactivatePageContent = async (param) => {
  const deactivatePageAPI = APIURL + "pagecontent/deactivate";
  return await fetch(deactivatePageAPI, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      //Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).Token,
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
export {
  getPageContentList,
  getPageContentByID,
  addPageContent,
  updatePageContent,
  deletePageContent,
  activatePageContent,
  deactivatePageContent,
  getPageContentByPageID
};
