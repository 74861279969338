const { APIURL } = require("global");

const getSchoolTranslationList = async (param) => {
  const schoolTranslationList = APIURL + "admin/login";
  return await fetch(schoolTranslationList, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getFilterSchoolTranslation = async (param) => {
  const getFilterSchool = APIURL + "admin/login";
  return await fetch(getFilterSchool, {
    method: "POST",
    body: JSON.stringify(param),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const getSchoolTranslationByID = async (data) => {
  const getSchoolTranslationByIDApi =
    APIURL + `schoolTranslation/${data.SchoolTranslationID}`;
  return await fetch(getSchoolTranslationByIDApi);
};

const UpdateSchoolTranslation = async (param) => {
    const updateSchoolTranslation = APIURL + "admin/login";
    return await fetch(updateSchoolTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const addSchoolMasterTranslation = async (param) => {
    const addSchoolTranslation = APIURL + "admin/login";
    return await fetch(addSchoolTranslation, {
      method: "POST",
      body: JSON.stringify(param),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

export {
  getSchoolTranslationList,
  getFilterSchoolTranslation,
  getSchoolTranslationByID,
  UpdateSchoolTranslation,
  addSchoolMasterTranslation,
};
