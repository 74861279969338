import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCourseTranslationByID } from "services/Translation/CourseMasterTranslation.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditCourseMasterTranslation() {
  const location = useLocation();
  const [CourseName, setCourseName] = useState();
//   const [SubjectCode, setSubjectCode] = useState();
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  useEffect(() => {
    GetCourseTranslationByID();
  }, []);

  const GetCourseTranslationByID = () => {
    let Param = {
      courseTranslationID: location.state,
    };
    getCourseTranslationByID(Param)
      .then((response) => response.json())
      .then((data) => {
        setCourseName(data.Result.CourseName);
        // setSubjectCode(data.Result.SubjectCode);
      });
  };
  const UpdateCourseTranslation = (e) => {
    e.preventDefault();
    let Param = {
      courseTranslationID: location.state,
      courseName: CourseName,
    //   subjectCode: SubjectCode,
      createBy: AdminID,
    };
    UpdateCourseTranslation(Param).then((response) => {
      if (response[0].OutStatus === "SUCCESS") {
        toast.success(response[0].OutMessage);
        setTimeout(() => {
          navigate("/courseMasterTranslation");
        }, 1500);
      } else {
        toast.error(response.OutMessage);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit Course translation Master
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateCourseTranslation}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Course Name
                      </label>
                      <input
                        type="text"
                        autoFocus
                        value={CourseName}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Course-Name"
                        onChange={(e) => setCourseName(e.target.value)}
                      />
                    </div>
                    {/* <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Subject Code
                      </label>
                      <input
                        type="text"
                        value={SubjectCode}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Subject-Code"
                        onChange={(e) => setSubjectCode(e.target.value)}
                      />
                    </div> */}

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
