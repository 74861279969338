import React, { useEffect, useState } from "react";
import { DivisionTranslationView } from "view/admin/Translation/DivisionTranslationView";
import { getDivisionTranslationList } from "services/Translation/DivisionTranslation.service";

export default function DivisionTranslationTable({ color }) {
  const [divisionTranslation, setDivisionTranslation] = useState([]);

  useEffect(() => {
    getDivisionTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setDivisionTranslation(data.Result);
      });
  }, []);

  return <DivisionTranslationView divisionTranslation={divisionTranslation}></DivisionTranslationView>;
}
