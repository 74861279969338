import React, { useEffect, useState } from "react";
import { editVillage, updateVillage } from "services/villagemaster.service";
import { getCountryList } from "services/countrymaster.service";
import {
  getDivisionList,
  getStateListByCountryID,
} from "services/divisionmaster.service";
import { getDistrictList } from "services/districtmaster.service";
import { getBlockList } from "services/blockmaster.service";
import { getClusterList } from "services/clustermaster.service";
import { getStateList } from "services/statemaster.service";
import {
  getDivisionListByStateID,
  getDistrictListByDivisionID,
  getBlockListByDistrictID,
  getClusterListByBlockID,
} from "services/schoolmaster.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditVillageMaster = () => {
  const [Country, setCountry] = useState("");
  const [CountryID, setCountryID] = useState("");
  const [State, setState] = useState("");
  const [StateID, setStateID] = useState("");
  const [Division, setDivision] = useState("");
  const [DivisionID, setDivisionID] = useState("");
  const [District, setDistrict] = useState("");
  const [DistrictID, setDistrictID] = useState("");
  const [Block, setBlock] = useState("");
  const [VillageID, setVillage] = useState("");
  const [BlockID, setBlockID] = useState("");
  const [Cluster, setCluster] = useState("");
  const [ClusterID, setClusterID] = useState("");
  const [VillageName, setVillageName] = useState("");
  const [VillageCode, setVillageCode] = useState("");
  const [NativeLanguageName, setNativeLanguageName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
    CountryList();
    StateList();
    DivisionList();
    DistrictList();
    BlockList();
    ClusterList();
  }, []);
  const CountryList = () => {
    getCountryList()
      .then((data) => {
        setCountry(data.Result);
        editVillage(location.state)
          .then((response) => response.json())
          .then((resp) => {
            console.log(resp);
            setVillageName(resp.Result[0].VillageName);
            setVillageCode(resp.Result[0].VillageCode);
            setNativeLanguageName(resp.Result[0].NativeLanguageName);
            setCountryID(resp.Result[0].CountryID);
            setStateID(resp.Result[0].StateID);
            setDivisionID(resp.Result[0].DivisionID);
            setDistrictID(resp.Result[0].DistrictID);
            setBlockID(resp.Result[0].BlockID);
            setClusterID(resp.Result[0].ClusterID);
          });
      });
  };

  const filterState = (id) => {
    setCountryID(id);
    getStateListByCountryID(id)
      .then((data) => {
        console.log("filterState",data);
        setState(data.Result);
      });
  };
  const filterDivision = (id) => {
    setStateID(id);
    let obj = {
      stateID: id,
    };
    getDivisionListByStateID(obj).then((data) => {
      setDivision(data.Result);
    });
  };
  const filterDistrict = (id) => {
    setDivisionID(id);
    let obj = {
      divisionID: id,
    };

    getDistrictListByDivisionID(obj).then((data) => {
      console.log("districtListByDivisonID",data);
      setDistrict(data.Result);
    });
  };
  const filterBlock = (id) => {
    setDistrictID(id);
    let obj = {
      districtID: id,
    };
    getBlockListByDistrictID(obj).then((data) => {
      setBlock(data.Result);
    });
  };
  const filterCluster = (id) => {
    setBlockID(id);

    let obj = {
      blockID: id,
    };
    getClusterListByBlockID(obj).then((data) => {
      setCluster(data.Result);
      console.log(data.Result);
    });
  };

  const setUpClusterID=(id)=>{
    setClusterID(id)
  }

  const StateList = () => {
    getStateList()
      .then((data) => {
        setState(data.Result);
      });
  };
  const DivisionList = () => {
    getDivisionList()
      .then((data) => {
        setDivision(data.Result);
      });
  };
  const DistrictList = () => {
    getDistrictList()
      .then((data) => {
        setDistrict(data.Result);
      });
  };

  const BlockList = () => {
    getBlockList()
      .then((data) => {
        setBlock(data.Result);
      });
  };
  const ClusterList = () => {
    getClusterList()
      .then((data) => {
        console.log("getclusterlist", data);
        setCluster(data.Result);
      });
  };
  const UpdateVillage = (e) => {
    e.preventDefault();
    let Param = {
      villageID: location.state,
      blockID: BlockID,
      clusterID: ClusterID,
      villageName: VillageName,
      villageCode: VillageCode,
      nativeLanguageName: NativeLanguageName,
      createBy: AdminID,
    };

    updateVillage(Param).then((response) => {
      if (response.Status === "SUCCESS") {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/village");
        }, 1500);
      } else toast.error(response.Message);
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit Village</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateVillage}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Country
                        <select
                          value={CountryID}
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            filterState(e.target.value);
                          }}
                        >
                          <option hidden>Country List</option>
                          {Country &&
                            Country?.map((item, index) => {
                              return (
                                <option value={item.CountryID} key={index}>
                                  {item.CountryName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose State
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            filterDivision(e.target.value);
                          }}
                          value={StateID}
                        >
                          <option hidden>State List</option>
                          {State &&
                            State?.map((item, index) => {
                              return (
                                <option value={item.StateID} key={index}>
                                  {item.StateName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Division
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            filterDistrict(e.target.value);
                          }}
                          value={DivisionID}
                        >
                          <option hidden>Division List</option>
                          {Division &&
                            Division?.map((item, index) => {
                              return (
                                <option value={item.DivisionID} key={index}>
                                  {item.DivisionName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose District
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            filterBlock(e.target.value);
                          }}
                          value={DistrictID}
                        >
                          <option hidden>District List</option>
                          {District &&
                            District?.map((item, index) => {
                              return (
                                <option value={item.DistrictID} key={index}>
                                  {item.DistrictName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Block
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            filterCluster(e.target.value);
                          }}
                          value={BlockID}
                        >
                          <option hidden>Block List</option>
                          {Block &&
                            Block?.map((item, index) => {
                              return (
                                <>
                                  <option value={item.BlockID} key={index}>
                                    {item.BlockName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Cluster
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setUpClusterID(e.target.value)
                          }}
                          value={ClusterID}
                        >
                          <option hidden>Cluster List</option>
                          {Cluster &&
                            Cluster?.map((item, index) => {
                              return (
                                <option value={item.ClusterID} key={index}>
                                  {item.ClusterName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Village Name
                      </label>
                      <input
                        type="text"
                        required
                        value={VillageName}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Village-Name"
                        onChange={(e) => setVillageName(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Village Code
                      </label>
                      <input
                        type="text"
                        required
                        value={VillageCode}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Village-Code"
                        onChange={(e) => setVillageCode(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Native Language Name
                      </label>
                      <input
                        type="text"
                        value={NativeLanguageName}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Native-language-name"
                        onChange={(e) => setNativeLanguageName(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default EditVillageMaster;
