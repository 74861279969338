import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { addPageContent } from "services/pageContent.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function AddPageContent() {
  const location = useLocation();
  const [pageContentData, setPageContentData] = useState({
    pageID: location.state,
    createBy: JSON.parse(localStorage.getItem("user")).AdminID,
  });
  const navigate = useNavigate();

  const AddPageContent = (e) => {
    e.preventDefault();

    addPageContent(pageContentData).then((response) => {
      if (response.Status === "SUCCESS" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/listPageContent", {
            state: location.state,
          });
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };

  const handleChange = (e) => {
    setPageContentData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Page Content
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddPageContent}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Page Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="title"
                        placeholder="Page Title"
                        onChange={handleChange}
                        autoFocus
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        rows="4"
                        name="shortDescription"
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Content
                      </label>
                      <ReactQuill
                        theme="snow"
                        onChange={(e) => {
                          setPageContentData((prev) => ({
                            ...prev,
                            description: e,
                          }));
                        }}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
