import React, { useEffect, useState } from "react";
import { getCourseContentList } from "services/coursecontentmaster.service";
import { addCourseSubContent } from "services/coursesubcontentmaster.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";

export default function AddCourseSubContentMaster() {
	const [CourseContent, setCourseContent] = useState([]);
	const [CourseContentID, setCourseContentID] = useState("");
	const [Name, setName] = useState("");
	const [Description, setDescription] = useState("");

	let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
	const navigate = useNavigate();

	useEffect(() => {
		CourseContentList();
	}, []);

	const CourseContentList = () => {
		getCourseContentList()
			
			.then((data) => {
				setCourseContent(data.Result);
			});
	};

	const AddCourseSubContent = (e) => {
		e.preventDefault();
		let Param = {
			courseContentID: CourseContentID,
			name: Name,
			description: Description,
			createBy: AdminID,
		};
		addCourseSubContent(Param).then((response) => {
			if (response.Status === "SUCCESS" && response.Error === false) {
				toast.success(response.Message);
				setTimeout(() => {
					navigate("/innovativepaathshala/chapterWeek");
				}, 1500);
			} else {
				toast.error(response.Message);
			}
		});
	};
	return (
		<>
			<main>
				<section className="w-full h-full">
					<div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
						<div>
							<h3 className="mx-4 my-2 text-[24px] font-bold">
								Add Course Content
							</h3>
						</div>
						<div className="w-full px-4">
							<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
								<div className="rounded-t mb-0 px-4 py-6">
									{" "}
									<form onSubmit={AddCourseSubContent}>
										<div className="relative w-full mb-3">
											<label
												className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
												htmlFor="grid-page-type"
											>
												Choose Course-Content
											</label>
											<select
												className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 w-48 "
												name="Page Type"
												onChange={(e) => setCourseContentID(e.target.value)}
											>
												<option hidden>Course-Content List</option>
												{CourseContent &&
													CourseContent.map((item, index) => {
														return (
															<option value={item.CourseContentID} key={index}>
																{item.Name}
															</option>
														);
													})}
											</select>
										</div>

										<div className="relative w-full mb-3">
											<label
												className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
												htmlFor="grid-page-name"
											>
												Name
											</label>
											<input
												type="text"
												className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
												placeholder="Name"
												onChange={(e) => setName(e.target.value)}
											/>
										</div>

										<div className="relative w-full mb-3">
											<label
												className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
												htmlFor="grid-page-name"
											>
												Description
											</label>
											<ReactQuill
												theme="snow"
												name="Description"
												onChange={(e) => setDescription(e)}
											/>
										</div>

										<div className="text-left mt-6">
											<button
												class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
												type="submit"
											>
												Submit
											</button>
											<ToastContainer />
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}
