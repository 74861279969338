import axios from "axios";
const { APIURL } = require("global");

//To get GradeMaster List for Table
const getGradeMasterList = async () => {
  const listApi = APIURL + "grade/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get GradeMaster List for Table
const getGradeMasterByID = async (param) => {
  const getGradeByIDApi = APIURL + `grade/${param.GradeID}`;
  try {
    let data = await axios.get(getGradeByIDApi);
    return data.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add GradeMaster List
const addGradeMaster = async (param) => {
  const addGradeAPI = APIURL + "grade/create";

  try {
    let Result = await axios.post(addGradeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Grade Master List
const updateGradeMaster = async (param) => {
  const updateGradeAPI = APIURL + "grade/update";
  try {
    let Result = await axios.post(updateGradeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Grade Master
const deleteGradeMaster = async (param) => {
  const deleteGradeAPI = APIURL + "grade/delete";

  try {
    let Result = await axios.post(deleteGradeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Grade Master
const activateGradeMaster = async (param) => {
  const activateGradeAPI = APIURL + "grade/activate";

  try {
    let Result = await axios.post(activateGradeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Grade Master
const deactivateGradeMaster = async (param) => {
  const deactivateGradeAPI = APIURL + "grade/deactivate";
  try {
    let Result = await axios.post(deactivateGradeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Get PageWise Record Grade
const getPageWiseRecordGrade = async (param) => {
  const getPageWiseRecordGradeAPI = APIURL + "grade/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordGradeAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getGradeMasterList,
  getGradeMasterByID,
  addGradeMaster,
  updateGradeMaster,
  deleteGradeMaster,
  activateGradeMaster,
  deactivateGradeMaster,
  getPageWiseRecordGrade,
};
