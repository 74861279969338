import axios from "axios";
const { APIURL } = require("global");

//To get Course Content List for Table
const getCourseContentList = async () => {
  const listApi = APIURL + "courseContent/list";
  try {
    let Result = await axios.get(listApi);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To add CourseContent
const addCourseContent = async (param) => {
  const createCourseContentAPI = APIURL + "courseContent/create";
  try {
    let Result = await axios.post(createCourseContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate CourseContent
const activateCourseContent = async (param) => {
  const activateCourseContentAPI = APIURL + "courseContent/activate";
  try {
    let Result = await axios.post(activateCourseContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate CourseContent
const deactivateCourseContent = async (param) => {
  const deactivateCourseContentAPI = APIURL + "courseContent/deactivate";
  try {
    let Result = await axios.post(deactivateCourseContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To delete CourseContent
const deleteCourseContent = async (param) => {
  const deleteCourseContentAPI = APIURL + "courseContent/delete";
  try {
    let Result = await axios.post(deleteCourseContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To update CourseContent
const updateCourseContent = async (param) => {
  const updateCourseContentAPI = APIURL + "courseContent/update";
  try {
    let Result = await axios.post(updateCourseContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
const editCourseContent = async (param) => {
  const editCourseContentAPI = APIURL + `courseContent/${param}`;
  try {
    let Result = await axios.get(editCourseContentAPI);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record CourseContent
const getPageWiseRecordCourseContent = async (param) => {
  const getPageWiseRecordCourseAPI =
    APIURL + "courseContent/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordCourseAPI, param);
    let data = Result.data;
    console.log("---", data);
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get CourseContent PageWise Record By Course
const getPeriodWiseRecordByCourse = async (param) => {
  const getPeriodWiseRecordCourseContentAPI =
    APIURL + "courseContent/getPageWiseRecordsByCourse";

  try {
    let Result = await axios.post(getPeriodWiseRecordCourseContentAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getCourseContentList,
  addCourseContent,
  activateCourseContent,
  deactivateCourseContent,
  deleteCourseContent,
  updateCourseContent,
  editCourseContent,
  getPageWiseRecordCourseContent,
  getPeriodWiseRecordByCourse,
};
