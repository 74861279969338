import React, { useState, useEffect } from "react";
import {
	getPageWiseRecordBlock,
	getBlockList,
	deleteBlock,
	activateBlock,
	deactivateBlock,
} from "services/blockmaster.service";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "components/admin/Admin-Pagination/pagination";

const BlockMasterView = () => {
	const navigate = useNavigate();

	const [Block, setBlock] = useState([]);
	const [counts, setCounts] = useState("");
	const [startLimit, setStartLimit] = useState(0);
	const [endLimit, setEndLimit] = useState(10);
	useEffect(() => BlockList(), [startLimit]);

	const BlockList = () => {
		let obj = {
			offset: startLimit,
			records: endLimit,
		}
		getPageWiseRecordBlock(obj).then((data)=> {
			setBlock(data.Result);
			setCounts(data.Count);
		})
	};
	const DeleteBlock = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		deleteBlock(Param).then((response) => {
			toast.error(response.Message);
			BlockList();
		});
	};

	const ActivateBlock = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		activateBlock(Param).then((response) => {
			toast.success(response.Message);
			BlockList();
		});
	};

	const DeactivateBlock = async (e) => {
		e.preventDefault();
		let Param = {
			id: e.target.id,
		};
		deactivateBlock(Param).then((response) => {
			toast.error(response.Message);
			BlockList();
		});
	};
	const RedirectToEditBlock = async (e) => {
		e.preventDefault();
		navigate("/editblock", {
			state: e.currentTarget.id,
		});
	};
	return (
		<>
			<div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
				<div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
					<div className="w-full max-w-full flex-grow flex-1">
						<h3 className="font-semibold text-lg text-black">Filter</h3>
					</div>
					<div>
						<input
							type="text"
							className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
							placeholder="Keyword"
						/>

						<select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 ">
							<option hidden>Status</option>
							<option>Activate</option>
							<option>Deactivate</option>
							<option>Delete</option>
						</select>

						<button
							className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
							// ml-48
							type="button"
						>
							Filter
						</button>
					</div>
				</div>
				<div className="mb-0 px-4 py-3 border-0 bg-slate-300">
					<div className="flex flex-wrap items-center">
						<div className="w-full max-w-full flex-grow flex-1">
							<h3 className="font-semibold text-lg text-black">
								List of Block
							</h3>
						</div>
						<div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
							<div className=" mt-32 sm:mt-0">
								<button
									className="bg-slate-950	active:bg-lightBlPue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
									type="button"
								>
									<Link to="/addblock">Add</Link>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="block w-full overflow-x-auto">
					<table className="items-center w-full bg-transparent border-collapse">
						<thead>
							<tr className="bg-blue-950">
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Block Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Block Code
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Native Language Name
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Status
								</th>
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Action
								</th>
							</tr>
						</thead>

						<tbody>
							{Block &&
								Block.map((block) => {
									return (
										<tr key={block.BlockID}>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{block.BlockName}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{block.BlockCode}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{block.NativeLanguageName}
											</td>

											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
												{block.CStatus}
											</td>
											<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4">
												{block.CStatus === "Active" && (
													<i
														id={block.BlockID}
														className="fas fa-edit"
														onClick={RedirectToEditBlock}
													></i>
												)}
												{(block.CStatus === "Active" ) && (
													<i
														id={block.BlockID}
														className="fas fa-trash text-red-600"
														onClick={(e) => DeleteBlock(e)}
													></i>
												)}
												{(block.CStatus === "Deactive" ||
													block.CStatus === "Delete") && (
													<i
														id={block.BlockID}
														className="fas fa-check-circle"
														onClick={(e) => ActivateBlock(e)}
													></i>
												)}
												{(block.CStatus === "Active" ) && (
													<i
														id={block.BlockID}
														className="fas fa-ban text-red-600"
														onClick={(e) => DeactivateBlock(e)}
													></i>
												)}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
					<Pagination
						data={{ counts, startLimit, setStartLimit, endLimit, setEndLimit }}
					/>
					<ToastContainer />
				</div>
			</div>
		</>
	);
};

export default BlockMasterView;
