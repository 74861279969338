import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addConfigurationMaster } from "services/configuration.service";
import { DEFAULT_LANGUAGE } from "global";

export default function AddConfigurationMaster() {
  const [ConfigurationName, setConfigurationName] = useState("");
  const [Description, setDescription] = useState("");
  const [languageID, setLanguageID] = useState(DEFAULT_LANGUAGE);
  const [configurationData, setConfigurationData] = useState({
    createBy: JSON.parse(localStorage.getItem("user")).AdminID,
  });
  const navigate = useNavigate();
  // let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const handleSubmit = (e) => {
    setConfigurationData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const AddConfiguration = (e) => {
    e.preventDefault();
  
    addConfigurationMaster(configurationData).then((response) => {
      if (response.Error === false && !Boolean(response.errors)) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/configuration");
        }, 1500);
      } else {
        toast.error(
          Boolean(response.errors)
            ? Object.values(response.errors)[0][0]
            : response.Message
        );
      }
    });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Configuration
              </h3>
            </div>

            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  <form onSubmit={AddConfiguration}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-board-name"
                      >
                        KeyName
                      </label>
                      <select
                        className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                        onChange={handleSubmit}
                        name="keyName"
                      >
                        <option hidden>KeyName</option>
                        <option value="Idea Submission">Idea Submission</option>
                        <option value="Innovative Pathshala">
                          Innovative Pathshala
                        </option>
                        <option value="Competence Best Education">
                          Competence Best Education
                        </option>
                        <option value="Certified Course">
                          Certified Courses
                        </option>
                        <option value="Concept Based Lesson Plan">
                          Concept Based Lesson Plan
                        </option>
                        <option value="Competence Best Smart Learning Project">
                          Competence Best Smart Learning Project
                        </option>
                        <option value="Competence Best Learning Project">
                          Competence Best Learning Project
                        </option>
                      </select>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Min Value
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Min-Value"
                        name="minValue"
                        onChange={(e)=>{ handleSubmit(e)}}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Max Value
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="maxValue"
                        placeholder="Max-Value"
                        onChange={(e)=>{ handleSubmit(e)}}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-description"
                      >
                        Default Value
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="defaultValue"
                        placeholder="Default-Value"
                        onChange={(e)=>{ handleSubmit(e)}}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
