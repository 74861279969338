import React, { useState, useEffect } from "react";
import { addBlock } from "services/blockmaster.service";
import { getDistrictList } from "services/districtmaster.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DEFAULT_LANGUAGE } from "global";
import { checkLanguageByLanguageName } from "services/languages.service";

const AddBlockMaster = () => {
  const [BlockName, setBlockName] = useState("");
  const [BlockCode, setBlockCode] = useState("");
  const [NativeLanguageName, setNativeLanguageName] = useState("");
  const [District, setDistrict] = useState("");
  const [DistrictID, setDistrictID] = useState("");
  const [LanguageID, setLanguageID] = useState("");

  const navigate = useNavigate();
  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  useEffect(() => {
     let obj = {
       languageName: DEFAULT_LANGUAGE,
     };
     checkLanguageByLanguageName(obj).then((response) => {
       if (response.data.Result != undefined || response.data.Result.length > 0) {
         setLanguageID(response.data.Result.LanguageID);
       }
     });
    DistrictList();
  }, []);

  const DistrictList = () => {
    getDistrictList()
      .then((data) => {
        setDistrict(data.Result);
      });
  };

  const AddBlock = (e) => {
    e.preventDefault();
    let Param = {
      districtID: DistrictID,
      blockName: BlockName,
      blockCode: BlockCode,
      nativeLanguageName: NativeLanguageName,
      languageID: LanguageID,
      createBy: AdminID,
    };

    addBlock(Param)
      .then((response) => {
        if (response.Status === "SUCCESS" && response.Error === false) {
          toast.success(response.Message);
          setTimeout(() => {
            navigate("/block");
          }, 1500);
        } else if (response.Status === "SUCCESS" && response.Error === true) {
          toast.error(response.Message);
        } else if(response.Status ==='Failed' && response.Error === false) {
          toast.error(response.Message);
      
        } else {
         
          toast.error(response.response.data.errors.districtID[0]);
        }
      });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Block</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddBlock}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose District
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setDistrictID(e.target.value);
                          }}
                        >
                          <option hidden>District List</option>
                          {District &&
                            District?.map((item, index) => {
                              return (
                                <option value={item.DistrictID} key={index}>
                                  {item.DistrictName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Block Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Block-Name"
                        onChange={(e) => setBlockName(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Block Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Block-Code"
                        onChange={(e) => setBlockCode(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Native Language Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Native-language-name"
                        onChange={(e) => setNativeLanguageName(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddBlockMaster;
