import React, { useEffect, useState } from "react";
import { DistrictTranslationView } from "view/admin/Translation/DistrictTranslationView";
import { getDistrictTranslationList } from "services/Translation/DistrictTranslation.service";

export default function DistrictTranslationTable({ color }) {
  const [districtTranslation, setDistrictTranslation] = useState([]);

  useEffect(() => {
    getDistrictTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setDistrictTranslation(data.Result);
      });
  }, []);

  return <DistrictTranslationView districtTranslation={districtTranslation}></DistrictTranslationView>;
}
