import React, { useState, useEffect } from "react";
import { addKnowledge } from "services/Knowledge.service";
import { getLanguageMasterList } from "services/languages.service";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";

export default function AddKnowledgeMaster() {
  const [language, setLanguage] = useState("");
  const [languageID, setLanguageID] = useState("");
  const [knowledgeTitle, setKnowledgeTitle] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [attachment, setAttachment] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    dropDownList();
  }, []);
  const dropDownList = () => {
    getLanguageMasterList()
      .then((response) => {
        setLanguage(response.Result);
      });
  };

  const AddKnowledge = (e) => {
    e.preventDefault();

    let obj = {
      knowledgeTitle: knowledgeTitle.replace("'", "''"),
      htmlContent: htmlContent.replace("'", "''"),
      languageID,
      attachment,
      attachmentType,
      createBy: JSON.parse(localStorage.getItem("user")).AdminID,
    };

    addKnowledge(obj).then((response) => {
      if (response.Status === "Success"  && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/knowledge");
        }, 1500);
      } else if(response.Status === "Success"  && response.Error === true) {
        toast.error(response.Message);
      }
       else {
        toast.error("Please fill required fields");
      }
    });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    let ext = file.name.split(".")[1].toLowerCase() ?? "";
    setAttachmentType(ext);
    if (file) {
      const reader = new FileReader();
      if (reader !== undefined && file !== undefined) {
        reader.onload = _handleReaderLoaded;
        reader.readAsBinaryString(file);
      }
    } else if (ext === "") {
      return false;
    } else {
      setAttachment("");
      toast.error("Image must be jpg, jpeg and png extension");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    setAttachment(btoa(binaryString));
    setImageUrl(setDp);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Knowledge Base</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddKnowledge}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          name="languageID"
                          onChange={(e) => {
                            setLanguageID(e.target.value);
                          }}
                        >
                          <option hidden>Language List</option>
                          {language &&
                            language?.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Knowledge Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Title"
                        name="title"
                        onChange={(e) => {
                          setKnowledgeTitle(e.target.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        HTMLContent
                      </label>
                      <ReactQuill
                        required={
                          attachment === "" && htmlContent === "" ? true : false
                        }
                        theme="snow"
                        onChange={(e) => {
                          setHtmlContent(e);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Attachment
                      </label>
                      <input
                        type="file"
                        required={
                          attachment === "" && htmlContent === "" ? true : false
                        }
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        name="attachment"
                        onChange={handleImage}
                      />
                      {imageUrl && (
                        <>
                          <img
                            src={imageUrl}
                            alt="not found image"
                            width="100"
                          />
                        </>
                      )}
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
}
