import React from "react";
import ConceptMasterView from "view/admin/ConceptMasterView";
const ListConceptMaster = () => {
  return (
    <>
      <ConceptMasterView />
    </>
  );
};

export default ListConceptMaster;
