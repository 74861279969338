import axios from "axios";
const { APIURL } = require("global");

//To get Block List for Table
const getBlockList = async () => {
  const listApi = APIURL + "block/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Block
const addBlock = async (param) => {
  const addBlockAPI = APIURL + "block/create";
  try {
    let Result = await axios.post(addBlockAPI, param);
    console.log(Result);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Block
const updateBlock = async (param) => {
  const updateBlockAPI = APIURL + "block/update";
  try {
    let Result = await axios.post(updateBlockAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//Edit  Block Page
const editBlock = async (param) => {
  const editBlockAPI = APIURL + `block/${param}`;
  try {
    let Result = await axios.get(editBlockAPI);
    console.log(Result.data);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Block
const deleteBlock = async (param) => {
  const deleteBlockAPI = APIURL + "block/delete";
  try {
    let Result = await axios.post(deleteBlockAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate Block
const activateBlock = async (param) => {
  const activateBlockAPI = APIURL + "block/activate";
  try {
    let Result = await axios.post(activateBlockAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To deactive Block
const deactivateBlock = async (param) => {
  const deactivateBlockAPI = APIURL + "block/deactivate";

  try {
    let Result = await axios.post(deactivateBlockAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Block
const getPageWiseRecordBlock = async (param) => {
  const getPageWiseRecordBlockAPI = APIURL + "block/getPageWiseRecords";

  try {
    let Result = await axios.post(getPageWiseRecordBlockAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getBlockList,
  updateBlock,
  addBlock,
  deleteBlock,
  deactivateBlock,
  activateBlock,
  editBlock,
  getPageWiseRecordBlock,
};
