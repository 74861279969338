import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Login from "./user/Login";
// / Components /;
import Sidebar from "./Sidebar/Sidebar";
import FooterSmall from "./Footers/FooterSmall";
import HeaderStats from "./Headers/HeaderStats";

import "../../assets/styles/main.css";
const isLoggedIn = localStorage.getItem("isLoggedIn");
export default function AdminLayout() {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("isLoggedIn"));
  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {loggedIn ? (
        <>
          <div className="grid grid-cols-6 gap-4 min-h-[95vh]">
            <div>
              <Sidebar />
            </div>
            <div className="col-span-5 h-full">
              <HeaderStats />
              <div>
                <Outlet />
              </div>
            </div>
          </div>
          <FooterSmall />
        </>
      ) : (
        <Login />
      )}
    </>
  );
}
