import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getPageImageByID, updatePageImage } from "services/pageImage.service";
import { IMAGEURL } from "global";

export default function EditPageImage() {
  const location = useLocation();
  const [pageID, setPageID] = useState("");
  const [pageImageSource, setPageImageSource] = useState("");
  const [pageImgAltTitle, setPageImgAltTitle] = useState("");
  const [pageImgTitle, setPageImgTitle] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [pageUrlImage, setPageUrlImage] = useState("");
  const navigate = useNavigate();

  const UpdatePageImages = (e) => {
    e.preventDefault();

    let pageImageData = {
      pageImageID: location.state,
      pageID,
      pageImageSource: pageUrlImage === "" ? pageUrlImage : pageImageSource,
      pageImgAltTitle,
      pageImgTitle,
      height,
      width,
      createBy: JSON.parse(localStorage.getItem("user")).AdminID,
    };
    updatePageImage(pageImageData).then((response) => {
      if (response.data.Status === "SUCCESS" && response.data.Error === false) {
        toast.success(response.data.Message);
        setTimeout(() => {
          navigate("/listPageImage", {
            state: pageID
          });
        }, 1500);
      } else {
        toast.error(response.data.Message);
      }
    });
  };

  useEffect(() => {
    getPageImageByID(location.state)
      .then((response) => response.json())
      .then((value) => {
        setPageID(value.Result[0].PageID);
        setPageImageSource(value.Result[0].PageImageSource);
        setPageImgAltTitle(value.Result[0].PageImgAltTitle);
        setPageImgTitle(value.Result[0].PageImgTitle);
        setWidth(value.Result[0].Width);
        setHeight(value.Result[0].Height);
      });
  }, []);

  const handleImage = (e) => {
    let file = e.target.files[0] ?? "";
    if (file) {
      let ext = file.name.split(".")[1].toLowerCase() ?? "";
      if (ext === "jpg" || ext === "jpeg" || ext === "png") {
        const reader = new FileReader();
        if (reader !== undefined && file !== undefined) {
          reader.onload = _handleReaderLoaded;
          reader.readAsBinaryString(file);
        }
      } else if (ext === "") {
        return false;
      } else {
        setPageImageSource("");
        toast.error("Image must be jpg, jpeg and png extension");
      }
    } else {
      setPageUrlImage("");
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    let setDp = "data:image/png;base64," + btoa(binaryString);
    // setPageImageData((prev) => ({ ...prev, imageSource: btoa(binaryString) }));
    setPageImageSource(btoa(binaryString));
    setPageUrlImage(setDp);
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit Page Image
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdatePageImages}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Page Image Alt Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="pageImgAltTitle"
                        value={pageImgAltTitle}
                        placeholder="Page Image Alt Title"
                        onChange={(e) => {
                          setPageImgAltTitle(e.target.value);
                        }}
                        autoFocus
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Page Image Title
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        placeholder="Page Image Title"
                        name="pageImgTitle"
                        value={pageImgTitle}
                        onChange={(e) => {
                          setPageImgTitle(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Width
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="width"
                        value={width}
                        placeholder="width"
                        onChange={(e) => {
                          setWidth(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Height
                      </label>
                      <input
                        type="number"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="height"
                        value={height}
                        placeholder="height"
                        onChange={(e) => {
                          setHeight(e.target.value);
                        }}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Page Image Source
                      </label>
                      <input
                        type="file"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        placeholder="Page Title"
                        onChange={handleImage}
                      />
                    </div>

                    {pageUrlImage ? (
                      <img src={pageUrlImage} width="100" alt="" />
                    ) : (
                      <img
                        src={`${IMAGEURL}${pageImageSource}`}
                        alt=""
                        width="100"
                      />
                    )}
                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
