import axios from "axios";
const { APIURL } = require("global");

//To get Tlm List for Table
const getTlmList = async () => {
  const listApi = APIURL + "tlm/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To add Tlm
const addTlm = async (param) => {
  const createTlmAPI = APIURL + "tlm/save";
  try {
    let Result = await axios.post(createTlmAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Tlm
const updateTlm = async (param) => {
  const updateTlmAPI = APIURL + "tlm/update";

  try {
    let Result = await axios.post(updateTlmAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Activate Tlm
const activateTlm = async (param) => {
  const activateTlmAPI = APIURL + "tlm/activate";
  try {
    let Result = await axios.post(activateTlmAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Deactivate Tlm
const deactivateTlm = async (param) => {
  const deactivateTlmAPI = APIURL + "tlm/deactivate";
  try {
    let Result = await axios.post(deactivateTlmAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Delete Tlm
const deleteTlm = async (param) => {
  const deleteTlmAPI = APIURL + "tlm/delete";
  try {
    let Result = await axios.post(deleteTlmAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

const editTlm = async (param) => {
  const editTlmAPI = APIURL + `tlm/${param}`;
  try {
    let Result = await axios.get(editTlmAPI);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getTlmList,
  addTlm,
  activateTlm,
  deactivateTlm,
  deleteTlm,
  editTlm,
  updateTlm,
};
