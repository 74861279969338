import axios from "axios";
const { APIURL } = require("global");

//To get HandBook List for Table
const getHandBookList = async () => {
  const listApi = APIURL + "adminHandbook/list";
  try {
    let Result = await axios.get(listApi);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add HandBook
const addHandBook = async (param) => {
  const addHandBookAPI = APIURL + "adminHandbook/create";
  try {
    let Result = await axios.post(addHandBookAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To Update HandBook
const updateHandBook = async (param) => {
  const updateHandBookAPI = APIURL + "adminHandbook/update";
  try {
    let Result = await axios.post(updateHandBookAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete HandBook
const deleteHandBook = async (param) => {
  const deleteHandBookAPI = APIURL + "adminHandbook/delete";
  try {
    let Result = await axios.post(deleteHandBookAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To activate HandBook
const activateHandBook = async (param) => {
  const activateHandBookAPI = APIURL + "adminHandbook/activate";
  try {
    let Result = await axios.post(activateHandBookAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate HandBook
const deactivateHandBook = async (param) => {
  const deactivateHandBookAPI = APIURL + "adminHandbook/deactivate";
  try {
    let Result = await axios.post(deactivateHandBookAPI, param);
    return Result.data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//to edit handbook
const editHandBook = async (param) => {
  const editHandBookAPI = APIURL + `adminHandbook/${param}`;
  let Result = await axios.get(editHandBookAPI);
  return Result.data;
};

//To Get PageWise Record Handbook
const getPageWiseRecordHandbook = async (param) => {
  const getPageWiseRecordHandbookAPI =
    APIURL + "adminHandbook/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordHandbookAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getHandBookList,
  addHandBook,
  updateHandBook,
  deleteHandBook,
  activateHandBook,
  deactivateHandBook,
  editHandBook,
  getPageWiseRecordHandbook,
};
