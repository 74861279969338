import React from "react";
import CaseStudyMasterView from "view/admin/CaseStudyMasterView";
const ListCaseStudyMaster = () => {
  return (
    <>
      <CaseStudyMasterView />
    </>
  );
};

export default ListCaseStudyMaster;
