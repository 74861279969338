import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { addCluster } from "services/clustermaster.service";
import { getBlockList } from "services/blockmaster.service";
import { ToastContainer, toast } from "react-toastify";
import { DEFAULT_LANGUAGE } from "global";
import { checkLanguageByLanguageName } from "services/languages.service";
import "react-toastify/dist/ReactToastify.css";

const AddClusterMaster = () => {
  const [Block, setBlock] = useState("");
  const [ClusterName, setClusterName] = useState("");
  const [ClusterCode, setClusterCode] = useState("");
  const [NativeLanguageName, setNativeLanguageName] = useState("");
  const [LanguageID, setLanguageID] = useState();
  const [BlockID, setBlockID] = useState("");

  const AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const navigate = useNavigate();

  useEffect(() => {
    let obj = {
      languageName: DEFAULT_LANGUAGE,
    };
    checkLanguageByLanguageName(obj).then((response) => {
      if (
        response.data.Result != undefined ||
        response.data.Result.length > 0
      ) {
        setLanguageID(response.data.Result.LanguageID);
      }
    });

    BlockList();
  }, []);

  const BlockList = () => {
    getBlockList().then((data) => {
      setBlock(data.Result);
    });
  };
  const AddCluster = (e) => {
    e.preventDefault();
    let Param = {
      blockID: BlockID,
      clusterName: ClusterName,
      clusterCode: ClusterCode,
      nativeLanguageName: NativeLanguageName,
      languageID: LanguageID,
      createBy: AdminID,
    };

    addCluster(Param).then((response) => {
      console.log(response);
      if (response.Status === "SUCCESS") {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/cluster");
        }, 1500);
      } else {
        toast.error(response.Message);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Add Cluster</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddCluster}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Block
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          onChange={(e) => {
                            setBlockID(e.target.value);
                          }}
                        >
                          <option hidden>Block List</option>
                          {Block &&
                            Block.map((item, index) => {
                              return (
                                <option value={item.BlockID} key={index}>
                                  {item.BlockName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>

                    <div className="relative w-full mb-3 mt-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Cluster Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Cluster-Name"
                        onChange={(e) => setClusterName(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Cluster Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Cluster-Code"
                        onChange={(e) => setClusterCode(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Native Language Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Native-language-name"
                        onChange={(e) => setNativeLanguageName(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddClusterMaster;
