import React, { useState, useEffect } from "react";
import {
  updateLearningOutcome,
  editLearningOutcome,
} from "services/learningOutcomeMaster.service";
import { getBoardList } from "services/boardmaster.service";
import { getSubjectList } from "services/subjectmaster.service";
import { getLanguageMasterList } from "services/languages.service";
import { useNavigate, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditLearningOutcomeMaster() {
  const [languageID, setLanguageID] = useState("");
  const [boardID, setBoardID] = useState("");
  const [subjectID, setSubjectID] = useState("");
  const [name, setName] = useState("");
  const [languageList, setLanguageList] = useState("");
  const [boardList, setBoardList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dropdownList();
  }, []);
  const dropdownList = () => {
    getBoardList()
      .then((data) => {
        setBoardList(data.Result);
        getSubjectList()
          .then((value) => {
            setSubjectList(value.Result);
            getLanguageMasterList()
              .then((response) => {
                setLanguageList(response.Result);
                editLearningOutcome(location.state)
                  .then((val) => {
                    setBoardID(val.Result[0].BoardID);
                    setLanguageID(val.Result[0].LanguageID);
                    setSubjectID(val.Result[0].SubjectID);
                    setName(val.Result[0].Name);
                  });
              });
          });
      });
  };

  const UpdateLearningOutcome = (e) => {
    e.preventDefault();
    let Param = {
      id: location.state,
      languageID,
      boardID,
      subjectID,
      name,
      createBy: AdminID,
    };

    updateLearningOutcome(Param)
      .then((response) => {
        if (response.Status === "SUCCESS" && response.Error === false) {
          toast.success(response.Message);
          setTimeout(() => {
            navigate("/learningOutcome");
          }, 1500);
        } else {
          toast.error(response.Message);
        }
      });
  };

  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Edit Learning Outcome
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateLearningOutcome}>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Board
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={boardID}
                          onChange={(e) => {
                            setBoardID(e.target.value);
                          }}
                        >
                          <option hidden>Board List</option>
                          {boardList &&
                            boardList?.map((item, index) => {
                              return (
                                <option value={item.BoardID} key={index}>
                                  {item.BoardName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Subject
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={subjectID}
                          name="subjectID"
                          onChange={(e) => {
                            setSubjectID(e.target.value);
                          }}
                        >
                          <option hidden>Subject List</option>
                          {subjectList &&
                            subjectList?.map((item, index) => {
                              return (
                                <option value={item.SubjectID} key={index}>
                                  {item.SubjectName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div>
                      <label className="font-semibold flex flex-col w-1/4">
                        Choose Language
                        <select
                          className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                          value={languageID}
                          name="languageID"
                          onChange={(e) => {
                            setLanguageID(e.target.value);
                          }}
                        >
                          <option hidden>Language List</option>
                          {languageList &&
                            languageList?.map((item, index) => {
                              return (
                                <option value={item.LanguageID} key={index}>
                                  {item.LanguageName}
                                </option>
                              );
                            })}
                        </select>
                      </label>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        maxLength="50"
                        name="name"
                        value={name}
                        placeholder="LearningOutcome-Name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        autoFocus
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        className="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
