import React from "react";
import CourseSubContentView from "view/admin/CourseSubContentView";
const ListCourseSubContentMaster = () => {
  return (
    <>
      <CourseSubContentView />
    </>
  );
};

export default ListCourseSubContentMaster;
