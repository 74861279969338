import React, { useEffect, useState } from "react";
import { BlockTranslationView } from "view/admin/Translation/BlockTranslationView";
import { getBlockTranslationList } from "services/Translation/BlockTranslation.service";

export default function BlockTranslationTable({ color }) {
  const [blockTranslation, setBlockTranslation] = useState([]);

  useEffect(() => {
    getBlockTranslationList()
      .then((response) => response.json())
      .then((data) => {
        setBlockTranslation(data.Result);
      });
  }, []);

  return <BlockTranslationView BlockTranslationData={blockTranslation}></BlockTranslationView>;
}
