import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getStatusMasterList } from "services/status.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const StatusMasterView = ({ status }) => {
	const [statusData, setStatusData] = useState(status);
	const navigate = useNavigate();
	useEffect(() => {
		setStatusData(status);
	}, [status]);

	const RedirectToEditStatus = async (e) => {
		e.preventDefault();
		navigate("/editStatus", {
			state: e.currentTarget.id,
		});
	};
	const DeleteStatus = async (e) => {
		e.preventDefault();
		// let Param = {
		//   id: e.target.id,
		// };
		// deleteStatus(Param).then((response) => {
		//   toast.error(response.Message);
		//   StatusList();
		// });
	};

	const ActivateStatus = async (e) => {
		e.preventDefault();
		// let Param = {
		//   id: e.target.id,
		// };
		// activateStatus(Param).then((response) => {
		//   toast.success(response.Message);
		//   StatusList();
		// });
	};

	const DeactivateStatus = async (e) => {
		e.preventDefault();
		// let Param = {
		//   id: e.target.id,
		// };
		// deactivateStatus(Param).then((response) => {
		//   toast.error(response.Message);
		//   StatusList();
		// });
	};

	return (
		<>
			<div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-white">
				<div className="px-4 py-3 border-1 my-5 bg-slate-300 text-black">
					<div className="w-full max-w-full flex-grow flex-1">
						<h3 className="font-semibold text-lg text-black">Filter</h3>
					</div>
					<div>
						<input
							type="text"
							className="border-0 px-3 py-2 my-3 placeholder-blueGray-300 text-blueGray-600 w-2/3 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-3 "
							placeholder="Keyword"
						/>

						<select className="text-white bg-blue-950 font-semibold rounded-lg w-2/12 mr-3  py-1 ">
							<option hidden>Status</option>
							<option>Activate</option>
							<option>Deactivate</option>
							<option>Delete</option>
						</select>

						<button
							className="bg-blue-950	 uppercase text-white  shadow text-md px-4 py-2 rounded  w-40  "
							// ml-48
							type="button"
						>
							Filter
						</button>
					</div>
				</div>
				<div className="mb-0 px-4 py-3 border-0 bg-slate-300">
					<div className="flex flex-wrap items-center">
						<div className="w-full max-w-full flex-grow flex-1">
							<h3 className="font-semibold text-lg text-black">
								List of Status
							</h3>
						</div>
						<div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
							<div className=" mt-32 sm:mt-0">
								<button
									className="bg-slate-950	active:bg-lightBlue-600 uppercase text-white  hover:shadow-md shadow text-md px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
									type="button"
								>
									<Link to="/addStatus">Add</Link>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="block w-full overflow-x-auto">
					{/* Projects table */}
					<table className="items-center w-full bg-transparent border-collapse">
						<thead>
							<tr className="bg-blue-950">
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Title
								</th>
								{/* <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Description
                </th> */}
								{/* <th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                  Status
                </th> */}
								<th className="px-6 align-middle border border-solid py-3 text-md uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{statusData.map((item) => {
								return (
									<tr key={item.StatusID}>
										<td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
											{item.Title}
										</td>
										{/* <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                      {item.CStatus}
                    </td> */}
										<td
											className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4 space-x-4"
											style={{ width: 250 }}
										>
											{/* {item.CStatus === "Active" && ( */}
											<i
												id={item.StatusID}
												className="fas fa-edit"
												onClick={RedirectToEditStatus}
											></i>
											{/* )} */}
											{/* {(item.CStatus === "Active" ||
                        item.CStatus === "Deactive") && (
                        <i
                          id={item.StatusID}
                          className="fas fa-trash text-red-600"
                          onClick={(e) => DeleteStatus(e)}
                        ></i>
                      )}
                      {(item.CStatus === "Deactive" ||
                        item.CStatus === "Delete") && (
                        <i
                          id={item.StatusID}
                          className="fas fa-check-circle"
                          onClick={(e) => ActivateStatus(e)}
                        ></i>
                      )}
                      {(item.CStatus === "Active" ||
                        item.CStatus === "Delete") && (
                        <i
                          id={item.StatusID}
                          className="fas fa-ban text-red-600"
                          onClick={(e) => DeactivateStatus(e)}
                        ></i>
                      )} */}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>{" "}
					<ToastContainer />
				</div>
			</div>
		</>
	);
};
