import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addSubject } from "services/subjectmaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DEFAULT_LANGUAGE } from "global";

export default function AddSubjectMaster() {
  const [SubjectName, setSubjectName] = useState("");
  const [SubjectCode, setSubjectCode] = useState("");
  const [LanguageID, setLanguageID] = useState(DEFAULT_LANGUAGE);
  const navigate = useNavigate();
  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  const AddSubject = (e) => {
    e.preventDefault();
    let Param = {
      subjectName: SubjectName,
      subjectCode: SubjectCode,
      languageID:LanguageID,
      createBy: AdminID,
    };
    addSubject(Param).then((response) => {
      if (response.Status === "Success" && response.Error === false) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/subject");
        }, 1500);
      } else if (response.Status === "Success" && response.Error === true) {
        toast.error(response.Message);
      } else if (SubjectCode.length > 4) {
        toast.error(response.Result.errors.subjectCode[0]);
      } else {
        toast.error(response.Result.errors.subjectName[0]);
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">
                Add Subject 
              </h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={AddSubject}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        Subject Name
                      </label>
                      <input
                        type="text"
                        required
                        autoFocus
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Subject-Name"
                        onChange={(e) => setSubjectName(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Subject Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Subject-Code"
                        onChange={(e) => setSubjectCode(e.target.value)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
