import axios from "axios";
const { APIURL } = require("global");


//To get Idea List for Table
const getIdeaList = async () => {
  const listApi = APIURL + "idea/list";
  try {
    let Result = await axios.get(listApi);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To get Idea List for Table
const getIdeaByID = async (param) => {
  const getIdeaByIDApi = APIURL + `idea/${param.id}`;
  try {
    let Result = await axios.get(getIdeaByID);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Add Idea List
const addIdea = async (param) => {
  const addIdeaAPI = APIURL + "idea/create";
  try {
    let Result = await axios.post(addIdeaAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Update Idea List
const updateIdea = async (param) => {
  const updateIdeaAPI = APIURL + "idea/update";
  try {
    let Result = await axios.post(updateIdeaAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Delete Idea
const deleteIdea = async (param) => {
  const deleteIdeaAPI = APIURL + "idea/delete";
  try {
    let Result = await axios.post(deleteIdeaAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To activate Idea
const activateIdea = async (param) => {
  const activateIdeaAPI = APIURL + "idea/activate";
  try {
    let Result = await axios.post(activateIdeaAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};
//To deactivate Idea
const deactivateIdea = async (param) => {
  const deactivateIdeaAPI = APIURL + "idea/deactivate";
  try {
    let Result = await axios.post(deactivateIdeaAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

//To Get PageWise Record Idea
const getPageWiseRecordIdea = async (param) => {
  const getPageWiseRecordIdeaAPI = APIURL + "idea/getPageWiseRecords";
  try {
    let Result = await axios.post(getPageWiseRecordIdeaAPI, param);
    let data = Result.data;
    return data;
  } catch (error) {
    if (
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      return error.response.data;
    } else {
      return error;
    }
  }
};

export {
  getIdeaList,
  getIdeaByID,
  addIdea,
  updateIdea,
  deleteIdea,
  activateIdea,
  deactivateIdea,
  getPageWiseRecordIdea,
};
