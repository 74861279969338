import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getIdeaStatusByIdeaID,
  updateIdeaStatus,
} from "services/IdeaStatus.service";
import { getStatusMasterList } from "services/status.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const IdeaStatusView = ({ ideaStatus }) => {
  const [Ideas, setIdeas] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [list, setList] = useState([]);
  const [statusID, setStatusID] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setIdeas(ideaStatus);
    if (ideaStatus?.IdeaID) {
      setStatusID(ideaStatus?.StatusID);
      getIdeaStatusByIdeaID(ideaStatus.IdeaID)
        .then((response) => response.json())
        .then((value) => {
          setStatusList(value?.Result);
        });
    }
    getStatusMasterList()
      .then((response) => response.json())
      .then((data) => {
        setList(data?.Result);
      });
  }, [ideaStatus]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let param = {
      statusID,
      ideaID: Ideas?.IdeaID,
    };
    updateIdeaStatus(param).then((response) => {
      toast.success(response.Message);
      setTimeout(() => {
        navigate("/idea");
      }, 1000);
    });
  };

  return (
    <>
      <div className=" overflow-x-visible flex flex-col min-w-0 break-words w-full mb-6 shadow-lg text-black h-[80vh]">
        <div className="mb-0 px-4 py-3 border-0 bg-slate-300">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-lg text-black">
                List of Ideas
              </h3>
            </div>
          </div>
        </div>
        <div className="flex h-full">
          <div className="flex flex-col w-[50%] px-[20px] [&_label]:my-2 mt-2">
            <h3 className="font-semibold">Idea data</h3>
            <label> Title :  
            {Ideas?.Title ?? " No Data Found"}</label>
            <label> Strategy: 
            {Ideas?.Strategy ?? "No Data Found"}</label>
            <label> Objective: 
            {Ideas?.Objective ?? "No Data Found"}</label>
            <label> Status: {" "}
            {Ideas?.StatusTitle ?? "No Data Found"}</label>
          </div>
          <div className="border-l-2 h-full w-[50%] px-5">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-md  mb-2 flex justify-between w-full my-2 items-center"
                htmlFor="grid-Grade-name"
              >
               <p className="font-semibold"> Status : </p>
             
              <select
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 w-[80%]"
                id="grid-GradeID"
                value={statusID}
                onChange={(e) => {
                  setStatusID(e.target.value);
                }}
              >
                <option hidden value="">
                  Select Status
                </option>
                {list.map((item, index) => {
                  return (
                    <option value={item.StatusID} key={index}>
                      {item.Title}
                    </option>
                  );
                })}
              </select>
              </label>
              <div className="text-left mt-6">
                <button
                  class="hover:bg-transparent bg-blue-500 hover:text-blue-700 text-white 
                      font-semibold hover:border-[1px] border-[1px] border-blue-500 py-2 px-4 rounded"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Update
                </button>
              </div>
            </div>
            <div className="block w-full overflow-x-auto my-5 ">
              {/* Projects table */}
              {/* <hr /> */}
              <h3 className="mb-3 font-medium">Status List</h3>
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr className="bg-blue-950">
                    <th className="px-6 align-middle border border-solid py-3 text-md text-white uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                      Idea
                    </th>
                    <th className="px-6 align-middle border border-solid py-3 text-md text-white uppercase border-l-0 border-r-0 whitespace-normal font-semibold text-left">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statusList.map((status) => {
                    return (
                      <tr key={status.IdeaID}>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {status.Title}
                        </td>
                        <td className="border-[1px] border-blue-950 px-6  align-middle text-black text-md whitespace-normal p-4">
                          {status.StatusTitle}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>{" "}
              <ToastContainer />
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};
