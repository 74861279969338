
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { editState, updateState } from "services/statemaster.service";
import { getActiveCountryList } from "services/countrymaster.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeyPressForAlphabets } from "globalKeyPressValidation";

export default function EditStatetMaster() {
  const [Country, setCountry] = useState("");
  const [countryID, setCountryID] = useState("");
  const [StateName, setStateName] = useState("");
  // const [StateID, setStateID] = useState("");
  const [StateCode, setStateCode] = useState("");
  const [nativeLanguageName, setNativeLanguageName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  let AdminID = JSON.parse(localStorage.getItem("user")).AdminID;
  useEffect(() => {
    editState(location.state).then((data) => {
      setStateName(data.Result.StateName);
      setStateCode(data.Result.StateCode);
      setNativeLanguageName(data.Result.NativeLanguageName);
      setCountryID(data.Result.CountryID);
    });
    CountryList();
  }, []);

  const CountryList = () => {
    getActiveCountryList().then((data) => {
      setCountry(data.Result);
      console.log(data.Result);
    });
  };

  const UpdateState = (e) => {
    e.preventDefault();
    let Param = {
      stateID: location.state,
      nativeLanguageName: nativeLanguageName,
      stateCode: StateCode,
      countryID: countryID,
      stateName: StateName,
      createBy: AdminID,
    };

    updateState(Param).then((response) => {
      if (response.Error === false && !Boolean(response.errors)) {
        toast.success(response.Message);
        setTimeout(() => {
          navigate("/state");
        }, 1500);
      } else {
        toast.error(
          Boolean(response.errors)
            ? Object.values(response.errors)[0][0]
            : response.Message
        );
      }
    });
  };
  return (
    <>
      <main>
        <section className="w-full h-full">
          <div className="grid grid-cols-1 flex content-center items-center justify-center h-full">
            <div>
              <h3 className="mx-4 my-2 text-[24px] font-bold">Edit State</h3>
            </div>
            <div className="w-full px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-4 py-6">
                  {" "}
                  <form onSubmit={UpdateState}>
                    <label className="font-semibold">Choose Country</label>
                    <br />

                    <select
                      className="text-white bg-blue-950 font-semibold border-2 border-blue-950 rounded-lg my-3 "
                      value={countryID}
                      onChange={(e) => {
                        setCountryID(e.target.value);
                      }}
                    >
                      <option hidden>Country List</option>
                      {Country &&
                        Country?.map((item, index) => {
                          return (
                            <option value={item.CountryID} key={index}>
                              {item.CountryName}
                            </option>
                          );
                        })}
                    </select>

                    <div className="relative w-full mb-3 py-6">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-name"
                      >
                        State Name
                      </label>
                      <input
                        type="text"
                        required
                        autoFocus
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="State-Name"
                        value={StateName}
                        onChange={(e) => setStateName(e.target.value)}
                        onKeyDown={(e)=>KeyPressForAlphabets(e)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        State Code
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="State-Code"
                        value={StateCode}
                        onChange={(e) => setStateCode(e.target.value)}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-page-type"
                      >
                        Native Language Name
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Native-Language-Name"
                        value={nativeLanguageName}
                        onChange={(e) => setNativeLanguageName(e.target.value)}
                        onKeyDown={(e)=>KeyPressForAlphabets(e)}
                      />
                    </div>

                    <div className="text-left mt-6">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 
                      font-semibold hover:text-white py-2 px-4 rounded"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </section>
      </main>
    </>
  );
}
